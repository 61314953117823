import { useEffect } from "react";
import { useAppSelector } from "../store/redux";
import { selectInterestedUnreadBondCount } from "../features/filterPanel";
import log from "../misc/log";
import { useLocation } from "react-router-dom";
import { isNativePlatform } from "../misc/capacitor";

async function updateBadgeCount(count: number) {
    try {
        // "Some" browsers require notification permissions to set badges. So just always
        // check. We rely on notifications coming up to actually do the prompting.
        const permission = await navigator.permissions.query({
            name: "notifications",
        });
        if (permission.state !== "granted") {
            return;
        }

        await navigator.setAppBadge(count);
    }
    catch (e) {
        log.error("Failed to set app badge", e);
    }
}

export default function BadgeCountUpdater(): React.JSX.Element {
    const interestedUnreadBondCount = useAppSelector(selectInterestedUnreadBondCount);
    const { pathname } = useLocation();

    // Window title. This is a bit more complex than you might imagine: some browsers
    // restore the title on navigating "back". And then re-writing the title doesn't
    // appear to do anything. So we need *two* writes to document.title, that differ,
    // to make the browser do something (tested on Edge, Chrome). And we obviously need
    // to re-write the title whenever the user might have navigated back, hence doing it
    // on location.pathname changing.
    //
    // See: https://stackoverflow.com/questions/72982365/setting-document-title-doesnt-change-the-tabs-text-after-pressing-back-in-the
    useEffect(() => {
        const title = interestedUnreadBondCount > 0 ? `Bond (${interestedUnreadBondCount})`
            : `Bond`;

        document.title = "";
        document.title = title;
    }, [interestedUnreadBondCount, pathname]);

    // PWA badge count
    useEffect(() => {
        if (!("setAppBadge" in navigator) || isNativePlatform) return;

        updateBadgeCount(interestedUnreadBondCount);
    }, [interestedUnreadBondCount]);

    return <></>;
}

import { Link } from "react-router-dom";
import { SentryReportButton } from "./SentryReportButton";

export function SidebarLinks({ location }: { location?: "isAside"; }): React.JSX.Element {
    return (
        <nav
            className={`c-links ${location === "isAside" ? "c-links--aside" : ""}`}
        >
            <Link to="/bond/settings" className="c-links__item c-links__item--settings">
                Settings
            </Link>
            <Link to="/bond/avatar" className="c-links__item">
                Avatar
            </Link>
            {/*<a href="" className="c-links__item">Profile</a>*/}
            <SentryReportButton buttonClassNames="c-links__item c-links__item--report" />
            <Link to="/logout" className="c-links__item">Sign out</Link>
        </nav>
    );
}

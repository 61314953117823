import { Capacitor } from "@capacitor/core";
import { nativeTargets } from "./environment";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { getRouter } from "./router";
import log from "./log";
import { To } from "react-router-dom";

export const isNativePlatform = Capacitor.isNativePlatform();
const nativePlatform = Capacitor.getPlatform();
export const isNativeAndroidPlatform = nativePlatform === "android";
export const isNativeIosPlatform = nativePlatform === "ios";
// isNativeAndroidPlatform || isNativeIosPlatform <=> isNativePlatform

export const nativePlatformTargetKey = "nativePlatformTarget";
export const getNativeTarget = () => {
    if (!isNativePlatform) {
        return undefined;
    }

    return localStorage.getItem(nativePlatformTargetKey) ?? nativeTargets.bondDev;
};

// We have a convoluted flow here wherein we first use react-router to change the URL,
// then we perform a full reload, rather than just navigating the browser.
// This is due to how it appears that Safari and react-oidc-context work.
//
// For Safari:
// If we navigate to a new URL, it appears to keep the previous page "running" in
// some form, such that the weblock is not released. If we instead refresh
// the current URL, this does not happen.
// Hence this set of actions works around Safari behaviour that hits up against our
// usage of the weblock.
//
// For react-oidc-context:
// This library only checks auth code from the URL on startup.
// So either we need to refresh our page entirely, or cause the AuthenticationProvider
// to be re-rendered completely. We don't have a nice way to manage the latter,
// so we do the former.
//
// This is a mess...
export const capacitorSetup = () => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        log.info("Native client redirecting to: ", event);

        const urlPrefix = "io.avos.beyond.app://app";
        const bondUrlPrefix = urlPrefix + "/bond";
        const inviteUrlPrefix = urlPrefix + "/invite";

        const router = getRouter();
        const routeAndReload = (route: To) => {
            log.info(`routeAndReload: ${route}`);
            router.navigate(route, { replace: true })
                .then(() => {
                    window.location.reload();
                });
        };

        // Example url: io.avos.beyond.app://app/bond

        if (event.url.startsWith(bondUrlPrefix)) {
            const slug = event.url.replace(bondUrlPrefix, "/mobile/tab/mybonds");
            if (slug) {
                routeAndReload(slug);
            }
        }
        else if (event.url.startsWith(inviteUrlPrefix)) {
            const slug = event.url.replace(inviteUrlPrefix, "/invite");
            if (slug) {
                routeAndReload(slug);
            }
        }
        else {
            // Default to the MyBonds tab view
            log.error("Unknown native client redirect:", event);
            routeAndReload("/mobile/tab/mybonds");
        }
    });
};

import classNames from "classnames";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import NewBondAudienceInput from "../components/NewBondAudienceInput";
import NewBondMessageComposer from "../components/NewBondMessageComposer";
import NewBondTitleBar from "../components/NewBondTitleBar";
import { bondCreationDraftTarget } from "../domain/channels";
import { clearDraftThunk } from "../features/bondCreation";
import { MetaInterestCounterKey, selectInterestInKey } from "../features/interest";
import { useNavigateBack } from "../hooks/useNavigateBack";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useAppDispatch } from "../store/redux";

export const NewBondView = () => <NewBondViewInternal key={Math.random()} />;

export const NewBondViewInternal = (): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const { navigateBack } = useNavigateBack();

    // Clear the bond creation draft when entering the view
    useEffect(() => {
        dispatch(clearDraftThunk(bondCreationDraftTarget));
    }, [dispatch]);

    // TODO: "Are you sure?"
    const backAction = navigateBack;

    // Navigate back when escape is pressed
    const hotkeyBlocked = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockHotkey,
    );

    const hotkeyBlockedWrapper = useCallback(() => hotkeyBlocked, [hotkeyBlocked]);
    useHotkeys("esc", backAction, {
        ignoreEventWhen: hotkeyBlockedWrapper,
        enableOnFormTags: ["input", "textarea"],
    });

    // Animation experiment
    const [entering, setEntering] = useState(true);
    useLayoutEffect(() => setEntering(false), []);
    const classes = classNames("c-new-bond", {
        "c-new-bond--entering": entering,
    });

    return (
        <div className={classes}>
            <NewBondTitleBar backAction={backAction} tabIndex={1} />
            <div className="c-new-bond__content">
                <NewBondAudienceInput draftTarget={bondCreationDraftTarget} tabIndex={2} />
            </div>
            <NewBondMessageComposer backAction={backAction} tabIndex={3} />
        </div>
    );
};

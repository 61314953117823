import { useEffect, useState } from "react";

import { updateActiveStatus } from "../features/connection";
import { MetaInterestCounterKey } from "../features/interest";
import { useMetaInterest } from "../hooks/interest/useInterest";
import useIdle from "../hooks/useIdle";
import useLocalDispatch from "../hooks/useLocalDispatch";

export default function WindowFocusTracker(): React.JSX.Element {
    // If there are ordering problems, we coud init these in a useEffect after
    // the event listeners are set up.
    const [focused, setFocused] = useState<boolean>(document.hasFocus());
    const [visible, setVisible] = useState<boolean>(document.visibilityState == "visible");
    const awake = !useIdle({ minutes: 1 });

    useEffect(() => {
        const focus = () => {
            setFocused(true);
        };
        window.addEventListener("focus", focus);

        const blur = () => {
            setFocused(false);
        };
        window.addEventListener("blur", blur);

        const updateVisibility = () => {
            // It's possible we may want to separately report visibility for some uses
            setVisible(!document.hidden);
        };
        document.addEventListener("visibilitychange", updateVisibility);

        return () => {
            window.removeEventListener("focus", focus);
            window.removeEventListener("blur", blur);
            document.removeEventListener("visibilitychange", updateVisibility);
        };
    }, []);

    const isActive = visible && focused && awake;

    // Store active state in local Redux store when user is totally active in window
    const localDispatch = useLocalDispatch();
    useEffect(() => {
        localDispatch(updateActiveStatus(isActive));
    }, [localDispatch, isActive]);

    // Update interest on overseer to update open gRPC streams.
    useMetaInterest(isActive, MetaInterestCounterKey.AnyTabActive);

    return <></>;
}

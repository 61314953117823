import classNames from "classnames";
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { calcBorderBoxSize } from "../../misc/css";
import { BoxSize } from "../../misc/types";
import { SuggestionDomain, SuggestionsList } from "../SuggestionsList";

const coordCalc = (
    anchor: number,
    altAnchor: number,
    invertAnchor: boolean,
    clampBehaviour: ClampBehaviour,
    size: number,
    windowSize: number,
): number => {
    if (invertAnchor) {
        const regular = Math.min(anchor, windowSize) - size;
        const alt = Math.max(size, altAnchor);
        const altClamped = Math.min(alt, windowSize) - size;
        if (clampBehaviour == "clamp") {
            return Math.max(size, regular);
        }
        else {
            return (anchor - size > 0) ? regular : altClamped;
        }
    }
    else {
        const regular = Math.max(0, anchor);
        const alt = Math.min(altAnchor, windowSize) - size;
        const altClamped = Math.max(0, alt);
        if (clampBehaviour == "clamp") {
            return Math.min(regular, windowSize - size);
        }
        else {
            return (anchor + size < windowSize) ? regular : altClamped;
        }
    }
};

type ClampBehaviour = "flip-alt" | "clamp";
type HorizontalAnchor = "left" | "right";
type VerticalAnchor = "top" | "bottom";

interface AutoCompleteProps<D extends any[]> {
    anchorX: number;
    anchorY: number;
    altAnchorX?: number;
    altAnchorY?: number;
    horizontalAnchor?: HorizontalAnchor;
    verticalAnchor?: VerticalAnchor;
    horizontalClampBehaviour?: ClampBehaviour;
    verticalClampBehaviour?: ClampBehaviour;
    suggestionDomain: SuggestionDomain<D>;
    onSuggestionsChange?: (suggestionGroups: SuggestionDomain<any[]>) => void;
    query: string;
}

export const AutoComplete = <D extends any[]>(
    props: AutoCompleteProps<D>,
): React.JSX.Element => {
    const { anchorX, anchorY, suggestionDomain, onSuggestionsChange, query } = props;
    const altAnchorX = props.altAnchorX ?? anchorX;
    const altAnchorY = props.altAnchorY ?? anchorY;
    const horizontalAnchor = props.horizontalAnchor ?? "left";
    const verticalAnchor = props.verticalAnchor ?? "top";
    const horizontalClampBehaviour = props.horizontalClampBehaviour ?? "flip-alt";
    const verticalClampBehaviour = props.verticalClampBehaviour ?? "flip-alt";

    const ref = useRef<HTMLDivElement>(null);

    const {
        width: windowWidth,
        height: windowHeight,
    } = useWindowSize();

    // Track the size of the dialog
    const [size, setSize] = useState<BoxSize>({ width: 0, height: 0 });
    const updateSize = useCallback(() => {
        if (!ref.current) return;
        setSize(calcBorderBoxSize(ref.current));
    }, []);
    useLayoutEffect(updateSize, [updateSize, windowWidth, windowHeight]);

    // Position the dropdown on screen, moving to the other side of the anchor if necessary
    const x = useMemo(
        () =>
            coordCalc(
                anchorX,
                altAnchorX,
                horizontalAnchor == "right",
                horizontalClampBehaviour,
                size.width,
                windowWidth,
            ),
        [
            anchorX,
            altAnchorX,
            horizontalAnchor,
            horizontalClampBehaviour,
            size.width,
            windowWidth,
        ],
    );
    const y = useMemo(
        () =>
            coordCalc(
                anchorY,
                altAnchorY,
                verticalAnchor == "bottom",
                verticalClampBehaviour,
                size.height,
                windowHeight,
            ),
        [
            anchorY,
            altAnchorY,
            verticalAnchor,
            verticalClampBehaviour,
            size.height,
            windowHeight,
        ],
    );

    const onSuggestionsChangeInternal = useCallback((suggestionGroups: SuggestionDomain<any[]>) => {
        onSuggestionsChange?.(suggestionGroups);
        updateSize();
    }, [onSuggestionsChange, updateSize]);

    const classes = classNames("c-autocomplete", {
        "c-autocomplete--hydrating": !size.width || !size.height,
    });

    const style = {
        left: (isNaN(x)) ? undefined : x,
        top: (isNaN(y)) ? undefined : y,
    };

    return (
        <div
            ref={ref}
            className={classes}
            style={style}
        >
            <SuggestionsList
                domain={suggestionDomain}
                query={query}
                arrowKeysActive={true}
                allowTab={true}
                useUnselectedState={false}
                groupClassName={"c-autocomplete__group"}
                suggestionClassName={"c-suggestion"}
                onSuggestionsChange={onSuggestionsChangeInternal}
            />
        </div>
    );
};

import { Code, ConnectError } from "@connectrpc/connect";
import { ThunkDispatch } from "@reduxjs/toolkit";

import log from "../misc/log";
import { resetAuth } from "./auth";
import debounce from "../misc/debounce";

export type TrimmedThunkAPI = {
    rejectWithValue: (value: any) => any;
    dispatch: ThunkDispatch<unknown, unknown, any>; // TODO: improve this
    abort: () => void;
};

// eslint-disable-next-line no-constant-condition
const logReturnValues = false ? log.debug : () => {};

export async function unaryThunkHandler<S>(
    thunkAPI: TrimmedThunkAPI,
    promise: Promise<S>,
    logId: string,
) {
    try {
        log.info(`Unary request: ${logId}`);
        const r = await promise;
        logReturnValues(`Request completed: ${logId}`, r);
        return r;
    }
    catch (err) {
        // Allow the service to clean up the subscription.
        thunkAPI.abort();

        if (err instanceof ConnectError) {
            log.info(`Request exception: ${logId}:`, err);
            if (err.code === Code.Unauthenticated) {
                thunkAPI.dispatch(resetAuth());
            }
        }
        else {
            log.info(`Request non-Error thrown ${logId}:`, err);
        }
        throw thunkAPI.rejectWithValue({ error: JSON.stringify(err), at: Date.now() });
    }
}

export async function streamThunkHandler<T>(
    thunkAPI: TrimmedThunkAPI,
    stream: AsyncIterableIterator<T>,
    parser: (t: T) => void,
    logId: string,
) {
    log.info(`Stream starting: ${logId}`);

    try {
        while (true) {
            const iter = await stream.next();

            if (iter.value) {
                logReturnValues(`Stream value: ${logId}`, iter.value);
                parser?.(iter.value);
            }

            if (iter.done) {
                log.info(`Stream ended: ${logId}`);
                break;
            }
        }
    }
    catch (err) {
        // Allow the service to clean up the subscription.
        thunkAPI.abort();

        if (err instanceof ConnectError) {
            log.info(`Stream exception: ${logId}:`, err);
            if (err.code === Code.Unauthenticated) {
                thunkAPI.dispatch(resetAuth());
            }
        }
        else {
            log.info(`Stream non-Error thrown ${logId}:`, err);
        }
        throw thunkAPI.rejectWithValue({ error: JSON.stringify(err), at: Date.now() });
        // TODO sort out what to do if we get exceptions - restart the stream? other?
    }
}

export async function streamThunkHandlerBatched<T>(
    thunkAPI: TrimmedThunkAPI,
    stream: AsyncIterableIterator<T>,
    debounceMs: number,
    parser: (thunkAPI: TrimmedThunkAPI, ts: T[]) => void,
    logId: string,
) {
    // `debounce` stores previously-streamed values. These are flushed
    // through `debounceMs` after the first received value. This allows us to
    // group streamed values and deal with them in batches, which helps
    // avoid the O(n^2) update that would otherwise occur for n sequential
    // updates into the redux store.
    const curried = (ts: T[]) => parser(thunkAPI, ts);
    const debouncer = debounce(debounceMs, curried);

    return streamThunkHandler(thunkAPI, stream, debouncer, logId);
}

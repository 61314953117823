import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

import { selectNumberOfUnreadBondsForMyBonds } from "../features/filterPanel";
import { selectCurrentSquadIdsOrderedBySquadName } from "../features/squads";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useAppSelector } from "../store/redux";
import SquadEntry from "./buttons/BondsTopbarSquadEntry";
import LinkButton from "./gui/LinkButton";

function MyBondsEntry() {
    const [unreadCount, mentionsCount] = useSelectorArgs(selectNumberOfUnreadBondsForMyBonds);
    const { pathname } = useLocation();
    const myBondsSelected = pathname === "/mobile/tab/mybonds" || pathname == "/bond";
    const selectedClass = myBondsSelected ? "is-selected" : "";

    return (
        <Link to="/bond" className={`c-sidebar__my-bonds c-btn-mybonds ${selectedClass}`}>
            <div className="c-btn-mybonds__label">
                <div className="c-btn-mybonds__icon"></div>
                Inbox
            </div>
            <div className="c-sidebar-counts">
                {mentionsCount > 0 && (
                    <div
                        className={`c-mentions-count ${
                            mentionsCount > 99 ? "c-mentions-count--large" : ""
                        }`}
                    >
                        {mentionsCount}
                    </div>
                )}
                {unreadCount > 0 && (
                    <div
                        className={`c-bond-count ${unreadCount > 99 ? "c-bond-count--large" : ""}`}
                    >
                        {unreadCount}
                    </div>
                )}
            </div>
        </Link>
    );
}

function MyBondsDiscover() {
    const count = 0;
    const { pathname } = useLocation();
    const showingDiscover = pathname === "/discover";

    return (
        <LinkButton
            className={`c-btn-discover-main ${showingDiscover ? "is-selected" : ""}`}
            to="/discover"
        >
            <div className="c-btn-discover-main__label">
                <div className="c-btn-discover-main__icon"></div>
                Discover
            </div>
            {count > 0 && (
                <div className={`c-bond-count ${count > 99 ? "c-bond-count--large" : ""}`}>
                    {count}
                </div>
            )}
        </LinkButton>
    );
}

export function SquadAndMyBondsSelector(
    props: {
        showMyBonds: boolean;
        location?: "isAside";
    },
): React.JSX.Element {
    const allMySquadIds = useAppSelector(selectCurrentSquadIdsOrderedBySquadName);
    const classes = classNames("c-squad-listing", {
        "c-squad-listing--aside": props.location === "isAside",
    });

    return (
        <>
            {props.showMyBonds && (
                <>
                    <MyBondsEntry />
                    <MyBondsDiscover />
                </>
            )}
            <div className={classes}>
                {allMySquadIds.map(squadId => (
                    <SquadEntry
                        key={squadId}
                        id={squadId}
                        showUnreadCount={true}
                    />
                ))}
            </div>
        </>
    );
}

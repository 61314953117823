import { createSelector } from "reselect";
import { AudienceMember, AudienceMemberOverview, isSquadId } from "../domain/audience";
import { SquadId, UserId } from "../domain/domain";
import { separateDiscriminatedUnion } from "../misc/utils";
import { createAppSelector } from "../store/redux";
import { memoizeOptions } from "./selectors";
import { selectSquads } from "./squads";
import { selectUsers } from "./users";

const separateAudienceMemo = createSelector(
    (audience: AudienceMember[]) => audience,
    audience => separateDiscriminatedUnion<SquadId, UserId>(isSquadId, audience),
    memoizeOptions.weakMapShallow,
);

export const selectAudienceOverviews = createAppSelector(
    [
        state => state,
        (_, audience: AudienceMember[]) => separateAudienceMemo(audience),
    ],
    (state, [squadReferences, userReferences]): AudienceMemberOverview[] => {
        return [
            ...selectSquads(state, squadReferences),
            ...selectUsers(state, userReferences),
        ];
    },
    memoizeOptions.weakMapShallow,
);

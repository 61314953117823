import * as d from "../../domain/domain";
import { selectCurrentUserId } from "../../features/auth";
import {
    MetaInterestCounterKey,
    updateBondInterest,
    updateCallInterest,
    updateInvitedBondInterest,
    updateMetaInterest,
    updateOrgInterest,
    updateSquadInterest,
    updateUserInterest,
    updateVisibleBondInterest,
    updateVisibleChannelInterest,
} from "../../features/interest";
import { Optional } from "../../misc/types";
import { useAppSelector } from "../../store/redux";
import useRegisterInterest, { OneNoneOrMany, oneNoneOrManyToArray } from "./useRegisterInterest";

export function useInterestedUsers(userIds: OneNoneOrMany<d.UserId>) {
    const ids = oneNoneOrManyToArray(userIds);
    useRegisterInterest({ ids, update: updateUserInterest });
}

export function useSelfInterest() {
    const userId = useAppSelector(selectCurrentUserId);

    useInterestedUsers(userId);
}

export function useInterestedBond(bondId: Optional<d.BondId>) {
    useRegisterInterest({
        ids: bondId ? [bondId] : [],
        update: updateBondInterest,
    });
}

export function useInterestedCall(callId: Optional<d.CallId>) {
    useRegisterInterest({
        ids: callId ? [callId] : [],
        update: updateCallInterest,
    });
}

export function useInterestedOrgs(orgIds: OneNoneOrMany<d.OrgId>) {
    useRegisterInterest({
        ids: oneNoneOrManyToArray(orgIds),
        update: updateOrgInterest,
    });
}

export function useInterestedSquads(squadIds: OneNoneOrMany<d.SquadId>) {
    useRegisterInterest({
        ids: oneNoneOrManyToArray(squadIds),
        update: updateSquadInterest,
    });
}

export function useInterestedVisibleBond(bondId: Optional<d.BondId>) {
    useRegisterInterest({
        ids: bondId ? [bondId] : [],
        update: updateVisibleBondInterest,
    });
}

export function useInterestedInvitedBond(bondId: Optional<d.BondId>) {
    useRegisterInterest({
        ids: bondId ? [bondId] : [],
        update: updateInvitedBondInterest,
    });
}

export function useInterestedChannel(channelId: Optional<d.ChannelId>) {
    useRegisterInterest({
        ids: channelId ? [channelId] : [],
        update: updateVisibleChannelInterest,
    });
}

export function useMetaInterest(
    interested: boolean,
    ...interestKeys: MetaInterestCounterKey[]
) {
    useRegisterInterest({
        ids: interested ? interestKeys : [],
        update: updateMetaInterest,
    });
}

import { BlockBlobClient, newPipeline } from "@azure/storage-blob";
import { ErrorBoundary } from "@sentry/react";
import { RouterProvider } from "react-router-dom";

import { getRouter } from "../misc/router";
import IdleTracker from "./IdleTracker";
import AttachmentManager, { FileStashContext, fileStashMap } from "./managers/AttachmentManager";
import WakeLockManager from "./managers/WakeLockManager";
import RefreshOnNewVersion from "./RefreshOnNewVersion";
import ToastContainer from "./ToastContainer";
import WindowFocusTracker from "./WindowFocusTracker";
import RtcSessionContextProvider from "@/context/RtcSessionContext";
import { isMobileBrowser } from "@/misc/mobile";

const blockBlobClientGenerator = (url: string) => {
    return new BlockBlobClient(
        url,
        newPipeline(),
    );
};

export default function Session(): React.JSX.Element {
    return (
        <ErrorBoundary>
            <WakeLockManager />
            <WindowFocusTracker />
            <RefreshOnNewVersion />
            <IdleTracker />
            <ToastContainer />
            <FileStashContext.Provider value={fileStashMap}>
                <AttachmentManager clientFactory={blockBlobClientGenerator} />
                <RtcSessionContextProvider disableMultitasking={isMobileBrowser()}>
                    <RouterProvider router={getRouter()} />
                </RtcSessionContextProvider>
            </FileStashContext.Provider>
        </ErrorBoundary>
    );
}

// @generated by protoc-gen-es v1.4.2 with parameter "target=js+ts"
// @generated from file intel/intel.proto (package intel, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { BondId, SquadSet, UserId, UserSet } from "../domain/domain_pb.js";

/**
 * @generated from message intel.SuggestBondTitleRequest
 */
export const SuggestBondTitleRequest = proto3.makeMessageType(
  "intel.SuggestBondTitleRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 2, name: "bond_content_draft", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.SuggestBondTitleResponse
 */
export const SuggestBondTitleResponse = proto3.makeMessageType(
  "intel.SuggestBondTitleResponse",
  () => [
    { no: 1, name: "bond_title_suggestion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message intel.SuggestedBond
 */
export const SuggestedBond = proto3.makeMessageType(
  "intel.SuggestedBond",
  () => [
    { no: 1, name: "bond_id", kind: "message", T: BondId },
    { no: 2, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message intel.SuggestBondsRequest
 */
export const SuggestBondsRequest = proto3.makeMessageType(
  "intel.SuggestBondsRequest",
  () => [
    { no: 1, name: "user_id", kind: "message", T: UserId },
    { no: 7, name: "squad_scope", kind: "message", T: SquadSet },
    { no: 3, name: "target_user_ids", kind: "message", T: UserSet },
    { no: 4, name: "soliciting_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "soliciting_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message intel.SuggestBondsResponse
 */
export const SuggestBondsResponse = proto3.makeMessageType(
  "intel.SuggestBondsResponse",
  () => [
    { no: 1, name: "suggested_bonds", kind: "message", T: SuggestedBond, repeated: true },
  ],
);


import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { bondCreationDraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { DraftChatMessage } from "../domain/messages";
import { selectCurrentOrgId } from "../features/auth";
import {
    createBondFromMessageThunk,
    selectBondTitleSuggestion,
    selectBondCreationAudience,
    selectBondUserSpecifiedTitle,
} from "../features/bondCreation";
import log from "../misc/log";
import { Optional } from "../misc/types";
import { useAppDispatch, useAppSelector } from "../store/redux";
import { FeatureFlagged } from "./FeatureFlags";
import MessageComposer from "./MessageComposer";
import RichTextMessageComposer from "./RichTextMessageComposer";

const placeholder = "Message...";

interface NewBondMessageComposerProps {
    backAction: () => void;
    tabIndex?: number;
}

export const NewBondMessageComposer: FC<NewBondMessageComposerProps> = ({
    backAction,
    tabIndex,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const orgId = useAppSelector(selectCurrentOrgId);
    const userSpecifiedTitle = useAppSelector(selectBondUserSpecifiedTitle);
    const aiGeneratedTitle = useAppSelector(selectBondTitleSuggestion);
    const audience = useAppSelector(selectBondCreationAudience);

    const createBond = useCallback(async (draft: Optional<DraftChatMessage>) => {
        if (!draft) return;

        // In future, we'll want this to be context dependent. Hence, don't
        // extract it from the store inside the thunk, do it here.
        if (!orgId) {
            log.warn(`No orgId for new bond`);
            return;
        }

        log.info("Creating bond with message");

        const newBond = await dispatch(createBondFromMessageThunk({
            orgId,
            bondTitles: {
                userSpecifiedTitle: userSpecifiedTitle,
                aiGeneratedTitle: aiGeneratedTitle,
            },
            msg: draft,
            audience,
        })).unwrap();

        navigate("/bond/" + d.extractUUID(newBond.id), { replace: true });
    }, [
        dispatch,
        orgId,
        userSpecifiedTitle,
        aiGeneratedTitle,
        audience,
        navigate,
    ]);

    return (
        <FeatureFlagged
            flag={"rich-text-composer"}
            match={true}
            wrapWithDiv={false}
            fallback={
                <MessageComposer
                    id="comms-input-bond-creation"
                    key="comms-input-bond-creation"
                    draftTarget={bondCreationDraftTarget}
                    msgCompletionAction={createBond}
                    escapeAction={backAction}
                    bondComposer={true}
                    placeholder={placeholder}
                    tabIndex={tabIndex}
                />
            }
        >
            <RichTextMessageComposer
                id="comms-input-bond-creation"
                key="comms-input-bond-creation"
                draftTarget={bondCreationDraftTarget}
                msgCompletionAction={createBond}
                escapeAction={backAction}
                bondComposer={true}
                placeholder={placeholder}
                tabIndex={tabIndex}
            />
        </FeatureFlagged>
    );
};

export default NewBondMessageComposer;

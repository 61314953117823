import useResizeObserver from "@react-hook/resize-observer";
import classNames from "classnames";
import React, {
    createContext,
    ForwardedRef,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import {
    ChannelMessageComposer,
    ChannelMessageComposerProps,
} from "../components/ChannelMessageComposer";
import * as d from "../domain/domain";
import { orderParticipants } from "../domain/rtc";
import { selectChannelIdByBondId, selectLiveCallIdByBondId } from "../features/bonds";
import { JoinedCallView, selectCallById, selectJoinedCallView } from "../features/calls";
import {
    selectMessageIdsByChannelId,
    selectPublishedSequenceNumber,
    updateStagedSequenceNumberToLocalMax,
} from "../features/channels";
import { useInterestedChannel } from "../hooks/interest/useInterest";
import useKeypressFocus from "../hooks/useKeypressFocus";
import useMergedRefs from "../hooks/useMergedRefs";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { Focusable, Optional } from "../misc/types";
import { useAppDispatch, useAppSelector } from "../store/redux";
import BondLiveGridView from "./BondLiveGridView";
import MessageView from "./MessageView";
import useRtcSessionContext from "../hooks/rtc/useRtcSessionContext";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";

const DEFAULT_MSG_HEIGHT = 60;

export interface MessagesViewContextValue {
    // Client size of the messages view
    clientWidth: number;
    clientHeight: number;
}

export const MessagesViewContext = createContext<MessagesViewContextValue | undefined>(undefined);

export function ChatMessagesViewInternal(props: ChatMessagesViewProps): React.JSX.Element {
    const {
        bondId,
        scrollToMessageId,
        publishedSequenceNumberForChannel,
    } = props;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const messageIds = useAppSelector(selectMessageIdsByChannelId(channelId));
    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);
    const listRef = useRef<HTMLDivElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const previousScrollToMessageId = useRef<Optional<d.AnyMessageId>>(undefined);
    const endDivRef = useRef<HTMLDivElement>(null);
    const [currentlyScrolledToBottom, setCurrentlyScrolledToBottom] = useState(
        props.scrollToMessageId === undefined,
    );
    const [clientDims, setClientDims] = useState<MessagesViewContextValue | undefined>(undefined);

    const { callParticipants } = useRtcSessionContext();
    const { displayMediaParticipants } = useShallowEqualsMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );

    const scrollToBottom = useCallback(() => {
        endDivRef.current?.scrollIntoView({ behavior: "instant" });
    }, []);

    useLayoutEffect(() => {
        if (!scrollToMessageId) {
            scrollToBottom();
            return;
        }

        if (scrollToMessageId && scrollToMessageId != previousScrollToMessageId.current) {
            document.getElementById(scrollToMessageId)?.scrollIntoView({
                behavior: "instant",
                block: "center",
            });

            previousScrollToMessageId.current = scrollToMessageId;
        }
    }, [scrollToBottom, scrollToMessageId]);

    // Keep scroll up to date
    useResizeObserver(divRef, () => {
        if (listRef.current) {
            setCurrentlyScrolledToBottom(isNearBottom(listRef.current));
        }

        if (currentlyScrolledToBottom) {
            scrollToBottom();
        }
        // This implicitly assumes that call summaries do not change for long-since-ended calls.
    });

    // Let elements that need to know the viewport width know it. This is used for
    // sizing inline attachments at least. Unfortunately we can't just use the span
    // sizing since that will be empty before any messages are rendered, we need
    // the div above it; i.e. listRef.
    useResizeObserver(listRef, () => {
        const lr = listRef.current;
        if (!lr) return;

        setClientDims({ clientWidth: lr.clientWidth, clientHeight: lr.clientHeight });
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (currentlyScrolledToBottom && channelId !== undefined) {
            dispatch(
                updateStagedSequenceNumberToLocalMax(channelId),
            );
        }
    }, [dispatch, currentlyScrolledToBottom, channelId]);

    const isNearBottom = useCallback((d: HTMLDivElement) => {
        // Half a small message height is about right as a heuristic
        // for "can we see the message content at all".
        const bottomThresh = DEFAULT_MSG_HEIGHT / 2;
        return d.scrollHeight - d.scrollTop - d.clientHeight < bottomThresh;
    }, []);

    const handleScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
        (ev: React.UIEvent<HTMLDivElement, UIEvent>) => {
            const atBottom = isNearBottom(ev.currentTarget);
            setCurrentlyScrolledToBottom(atBottom);

            // On phase 3, keep the bond read when we reach the bottom.
            // But we avoid auto-reading if you can't see all the content.
            // This gets the job done for now, but it's far from ideal. There is nothing
            // to stop new messages coming in and temporarily showing a new messages line
            // which looks quite bad really.
            if (atBottom && channelId !== undefined) {
                dispatch(
                    updateStagedSequenceNumberToLocalMax(channelId),
                );
            }
        },
        [isNearBottom, channelId, dispatch],
    );

    if (!channelId) {
        return <></>;
    }

    const bondClasses = classNames("c-bond__content", {
        "c-bond__content--short": displayMediaParticipants.length > 0,
    });

    return (
        <div
            onScroll={handleScroll}
            ref={listRef}
            className={bondClasses}
        >
            <div ref={divRef}>
                <MessagesViewContext.Provider value={clientDims}>
                    {props.preChatContent}
                    <MessageListMemo
                        messageIds={messageIds}
                        liveCallId={liveCallId}
                        publishedSequenceNumberForChannel={publishedSequenceNumberForChannel}
                    />
                    <div className="c-bond__spacer" ref={endDivRef} />
                </MessagesViewContext.Provider>
            </div>
        </div>
    );
}

interface MessageListProps {
    messageIds: string[];
    liveCallId: Optional<d.CallId>;
    publishedSequenceNumberForChannel: Optional<number>;
}

const MessageListMemo: React.FC<MessageListProps> = memo(props =>
    props.messageIds.map((id, index) => (
        <MessageView
            key={id}
            id={id}
            currentCallId={props.liveCallId}
            previousId={props.messageIds[index - 1]}
            lastMessage={index == (props.messageIds.length - 1)}
            publishedSequenceNumber={props.publishedSequenceNumberForChannel}
        />
    ))
);

interface ChatMessagesViewProps {
    bondId: d.BondId;
    scrollToMessageId?: d.AnyMessageId;
    publishedSequenceNumberForChannel?: number;
    preChatContent?: React.JSX.Element;
}

export function ChatMessagesView(props: ChatMessagesViewProps): React.JSX.Element {
    const { bondId } = props;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const messageIds = useAppSelector(selectMessageIdsByChannelId(channelId));
    const publishedSequenceNumber = useSelectorArgs(selectPublishedSequenceNumber, channelId);

    // We save the published sequence number on mount so we can keep the unread messages
    // marker at the same place in the view.
    const savedPublishedSeqNo = useRef<{ bondId: d.BondId; seqNo: number; } | undefined>(
        publishedSequenceNumber !== undefined ? {
            bondId,
            seqNo: publishedSequenceNumber,
        } : undefined,
    );

    useEffect(() => {
        // If we re-render with a different bond, forget any state
        if (savedPublishedSeqNo.current) {
            if (savedPublishedSeqNo.current.bondId !== bondId) {
                savedPublishedSeqNo.current = undefined;
            }
        }

        // Mostly this will fire to save the sequence number when we initially mount this component
        if (!savedPublishedSeqNo.current) {
            savedPublishedSeqNo.current = { bondId, seqNo: publishedSequenceNumber ?? 0 };
        }
    }, [bondId, publishedSequenceNumber]);

    // This is responsible for finding the ID of the message with the new messages marker on it.
    // If there is not one, the it returns undefined.
    //
    // If there is a specific message ID to scroll to supplied in the props to this component,
    // that ID is prioritised.
    const scrollToMessageId = useMemo(() => {
        if (props.scrollToMessageId !== undefined) {
            return props.scrollToMessageId;
        }

        // This is the index of the message that would have the new messages marker on it,
        // which is the message with sequence number 1 greater than the published sequence number.
        // Since the sequence numbers for messages start at 1, we have to subtract 1
        // from the published sequence number to use as an index into the array of messageIds.
        const desiredIndex = publishedSequenceNumber ?? 0;

        // The following index works since we have already fetched all messages
        // in the channel when we come to here.
        return messageIds[desiredIndex];
    }, [messageIds, props.scrollToMessageId, publishedSequenceNumber]);

    return (
        <ChatMessagesViewInternal
            {...props}
            scrollToMessageId={scrollToMessageId}
            publishedSequenceNumberForChannel={savedPublishedSeqNo.current?.seqNo}
        />
    );
}

export interface ChatViewProps {
    bondId: d.BondId;
    scrollToMessageId?: d.MessageId;
    showBondInterestedAction?: () => void;
    preChatContent?: React.JSX.Element;
}

export const ChatView = forwardRef((
    props: ChatViewProps,
    ref: ForwardedRef<Focusable>,
): React.JSX.Element => {
    const {
        bondId,
        scrollToMessageId,
        showBondInterestedAction,
    } = props;

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const liveCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);

    const liveCall = useSelectorArgs(selectCallById, liveCallId);
    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isLiveView = joinedCallView === JoinedCallView.Live;

    const composerRef = useRef<Focusable>(null);
    const setComposerTextFocused = useKeypressFocus(composerRef);
    const mergedRefs = useMergedRefs(composerRef, ref);

    const onEditorFocus = useCallback(() => setComposerTextFocused(true), [setComposerTextFocused]);
    const onEditorBlur = useCallback(() => setComposerTextFocused(false), [setComposerTextFocused]);

    // TODO: do we need this?
    useInterestedChannel(channelId);

    if (!channelId) {
        return <></>;
    }

    const liveGridView = <BondLiveGridView />;

    const chatOrLiveGrid = (isLiveView && liveCall) ? liveGridView : (
        <ChatMessagesView
            bondId={bondId}
            scrollToMessageId={scrollToMessageId}
            preChatContent={props.preChatContent}
        />
    );

    const composerProps: ChannelMessageComposerProps = {
        channelId,
        showBondInterestedAction,
        onEditorFocus,
        onEditorBlur,
    };

    return (
        <>
            {chatOrLiveGrid}
            <ChannelMessageComposer ref={mergedRefs} {...composerProps} />
        </>
    );
});

export default ChatView;

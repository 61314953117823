import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";

import {
    addSquadMember,
    type AddSquadMemberArgs,
    createOrg,
    createSquad,
    createUser,
    type CreateUserArgs,
    SquadObservations,
    subSquadObservers,
    listPersonOrgs,
    type ListPersonOrgsResponse,
    listUsersByPerson,
    subOrgOverviews,
    subSquadOverviews,
    subUserSquadLists,
} from "../api/squads";
import * as d from "../domain/domain";
import * as migrateV17 from "../domain/migrate/v17";
import { UserObservation } from "../domain/presence";
import { OrgOverview, SquadOverview } from "../domain/squads";
import log from "../misc/log";
import { arraysAreEqual, setUnion } from "../misc/primatives";
import { Diff, Optional, optionalFriendly, partialRecord, TypedKeys } from "../misc/types";
import {
    checkPersistor,
    clearKey,
    fetchIdbStore,
    idbStore,
    localStorageStore,
    simpleIdbTranslate,
} from "../persist/shared";
import type {
    Connection,
    IdbStoreUpdate,
    IdbStoreUpdateWithClear,
    PersistenceUpdate,
    RWTransaction,
} from "../persist/types";
import { appAppendListener, type AppAppendListener } from "../store/middleware";
import { createAppSelector } from "../store/redux";
import type { RootState } from "../store/types";
import { resetStore, selectCurrentOrgId, selectCurrentPersonId, selectCurrentUserId } from "./auth";
import { selectOrgsInterest, selectSquadsInterest, startManagingInterest } from "./interest";
import { createProxiedAsyncThunk } from "./proxiedThunk";
import { memoizeOptions, shallowArrayEqualSelectorCreator } from "./selectors";
import { createStreamingAsyncThunk } from "./streamingThunk";
import { unaryThunkHandler } from "./thunk";

const squadsAdapter = createEntityAdapter({
    selectId: (squad: SquadOverview) => squad.id,
});

const orgsAdapter = createEntityAdapter({
    selectId: (org: OrgOverview) => org.id,
});

export const streamOrgOverviews = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.OrgId>>,
    OrgOverview
>(
    "squads/streamOrgOverviews",
    {
        rpc: ({ arg, signal }) => subOrgOverviews(arg, signal),
        parser: ({ thunkAPI, content: orgs }) => {
            thunkAPI.dispatch(updateOrgs(orgs));
        },
    },
);

export const streamSquadOverviews = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.SquadId>>,
    SquadOverview
>(
    "squads/streamSquadOverviews",
    {
        rpc: ({ arg, signal }) => subSquadOverviews(arg, signal),
        parser: ({ thunkAPI, content: squads }) => {
            thunkAPI.dispatch(updateSquads(squads));
        },
    },
);

export const subUserSquadListsThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.UserId>>,
    d.SquadId[]
>(
    "squads/subUserSquadLists",
    {
        rpc: ({ arg, signal }) => subUserSquadLists(arg, signal),
        parser: ({ thunkAPI, content }) =>
            content.forEach(squadIds =>
                thunkAPI.dispatch(
                    squadSlice.actions.updateCurrentUserSquads(
                        squadIds,
                    ),
                )
            ),
    },
);

// `startManagingInterest` needs a selector returning a list as input,
// but for selecting our own squads it's only ever our current user.
// So this selector is just a simple adaptor.
const selectCurrentUserIdAsSingletonList = createAppSelector(
    [selectCurrentUserId],
    userId => [userId].filter(x => !!x),
);

export const startSubUserSquadListsStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectCurrentUserIdAsSingletonList,
    subUserSquadListsThunk,
    "subUserSquadLists",
    appendListener,
);

export const fetchCurrentPersonMemberships = createProxiedAsyncThunk<ListPersonOrgsResponse, void>(
    "squads/fetchCurrentPersonMemberships",
    async (_, thunkAPI) => {
        const store = thunkAPI.getState();
        const currentPersonId = selectCurrentPersonId(store);
        if (!currentPersonId) {
            return thunkAPI.rejectWithValue({
                error: `Cannot fetch current memberships with empty person id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            listPersonOrgs(currentPersonId),
            "fetchCurrentPersonMemberships",
        );
    },
);

export const fetchUsersForPerson = createProxiedAsyncThunk(
    "squads/fetchUsersForPerson",
    async ({ personId }: { personId: d.PersonId; }, thunkAPI) => {
        log.info(`Fetching users for person ${personId}`);

        return await unaryThunkHandler(
            thunkAPI,
            listUsersByPerson(personId),
            `fetchUsersForPerson ${personId}`,
        );
    },
);

export const subSquadObserversThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.SquadId>>,
    SquadObservations
>(
    "bonds/subSquadObservers",
    {
        rpc: ({ arg, signal }) => subSquadObservers(arg, signal),
        parser: ({ thunkAPI, content }) => {
            content
                .filterLatest(obs => obs.viewId)
                .forEach(msg => {
                    thunkAPI.dispatch(streamedSquadObservers(msg));
                });
        },
    },
);

interface CreateOrgArgs {
    name: string;
}
export const createNewOrg = createProxiedAsyncThunk(
    "squads/createOrg",
    async (args: CreateOrgArgs, thunkAPI) => {
        const store = thunkAPI.getState();
        const personId = selectCurrentPersonId(store);

        if (args.name == "" || !personId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to create org without name or user ID`,
            });
        }

        log.info(`Creating org`);

        return await unaryThunkHandler(
            thunkAPI,
            createOrg({
                name: args.name,
                initalPerson: personId,
            }),
            `createOrg`,
        );
    },
);

export const addUserToOrg = createProxiedAsyncThunk(
    "squads/createUser",
    async (args: CreateUserArgs, thunkAPI) => {
        log.info(`Adding person ${args.personId} to org ${args.orgId}`);

        return await unaryThunkHandler(
            thunkAPI,
            createUser(args),
            `createUser ${args.personId} to org ${args.orgId}`,
        );
    },
);

interface CreateSquadArgs {
    name: string;
    orgId: d.OrgId;
}
export const createNewSquad = createProxiedAsyncThunk(
    "squads/createSquad",
    async (args: CreateSquadArgs, thunkAPI) => {
        if (args.name == "") {
            throw thunkAPI.rejectWithValue({
                error: `Tried to create squad without name or user ID`,
            });
        }

        const store = thunkAPI.getState();
        const userId = selectCurrentUserId(store)!;

        log.info(`Creating squad`);

        return await unaryThunkHandler(
            thunkAPI,
            createSquad({
                orgId: args.orgId,
                name: args.name,
                initalUser: userId,
            }),
            `createSquad`,
        );
    },
);

export const addUserToSquad = createProxiedAsyncThunk(
    "squads/addSqaudMember",
    async (args: AddSquadMemberArgs, thunkAPI) => {
        log.info(`Adding user ${args.userId} to squad ${args.squadId}`);

        return await unaryThunkHandler(
            thunkAPI,
            addSquadMember(args),
            `addSquadMember ${args.userId} to squad ${args.squadId}`,
        );
    },
);

export interface SquadState {
    orgs: EntityState<OrgOverview, d.OrgId>;
    squads: EntityState<SquadOverview, d.SquadId>;
    currentPersonOrgIds: Array<d.OrgId>;
    currentUserSquadIds: Array<d.SquadId>;
    squadSizes: Record<d.SquadId, number>;
    observers: Record<d.SquadId, Array<UserObservation>>;
    updates: SquadsUpdate[];
}

const getInitialState = (props?: {
    orgs?: Record<d.OrgId, OrgOverview>;
    squads?: Record<d.SquadId, SquadOverview>;
    userOrgs?: Array<d.OrgId>;
    userSquads?: Array<d.SquadId>;
}): SquadState => ({
    orgs: orgsAdapter.getInitialState({}, props?.orgs ?? {}),
    squads: squadsAdapter.getInitialState({}, props?.squads ?? {}),
    currentPersonOrgIds: props?.userOrgs ?? [],
    currentUserSquadIds: props?.userSquads ?? [],
    squadSizes: {},
    observers: {},
    updates: [],
});

export const squadSlice = createSlice({
    name: "squads",
    initialState: getInitialState(),
    selectors: {
        currentPersonOrgIds: state => state.currentPersonOrgIds,
        currentUserSquadIds: state => state.currentUserSquadIds,
        observers: (state, squadId?: d.SquadId): Optional<UserObservation[]> =>
            squadId && state.observers[squadId],
    },
    reducers: {
        updateOrgs: (state, { payload: orgs }: PayloadAction<Array<OrgOverview>>) => {
            state.orgs = orgsAdapter.upsertMany(state.orgs, orgs);

            state.updates = orgs.map(org => [stores.orgs, org.id, org]);
        },
        updateSquads: (state, { payload: squads }: PayloadAction<Array<SquadOverview>>) => {
            squadsAdapter.upsertMany(state.squads, squads);
            squads.forEach(so => {
                state.squadSizes[so.id] = so.userIds.length;
            });

            state.updates = squads.map(so => [stores.squads, so.id, so]);
        },
        updateCurrentUserSquads: (
            state,
            { payload: squadIds }: PayloadAction<Array<d.SquadId>>,
        ) => {
            state.currentUserSquadIds = squadIds;
            state = updatePersistedSquadMembership(state);
        },
        // Useful for testing and for ladle stories.
        updateMemberships: (
            state,
            action: PayloadAction<{ orgIds: d.OrgId[]; squadIds: d.SquadId[]; }>,
        ) => {
            state.currentPersonOrgIds = action.payload.orgIds;
            state.currentUserSquadIds = action.payload.squadIds;

            state = updatePersistedSquadMembership(state);
            state = updatePersistedOrgMembership(state);
        },
        streamedSquadObservers: (
            state,
            { payload: observers }: PayloadAction<SquadObservations>,
        ) => {
            state.observers[observers.viewId] = observers.observations;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCurrentPersonMemberships.fulfilled, (state, action) => {
            state.currentPersonOrgIds = action.payload.orgIds;

            state = updatePersistedOrgMembership(state);
        });
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

function updatePersistedSquadMembership(state: SquadState): SquadState {
    state.updates = [[stores.squadMembership, clearKey]];
    state.currentUserSquadIds.forEach(squadId => {
        state.updates.push([stores.squadMembership, squadId, true]);
    });
    return state;
}

function updatePersistedOrgMembership(state: SquadState): SquadState {
    state.updates = [[stores.orgMembership, clearKey]];
    state.currentPersonOrgIds.forEach(orgId => {
        state.updates.push([stores.orgMembership, orgId, true]);
    });
    return state;
}

export const {
    updateOrgs,
    updateSquads,
    updateMemberships,
    streamedSquadObservers,
} = squadSlice.actions;

const selectors = squadSlice.getSelectors((state: RootState) => state.squads);
export const {
    currentPersonOrgIds: selectCurrentOrgIds,
    currentUserSquadIds: selectCurrentSquadIds,
} = selectors;

const squadSelectors = squadsAdapter.getSelectors<RootState>(
    state => state.squads.squads,
);
const orgSelectors = orgsAdapter.getSelectors<RootState>(
    state => state.squads.orgs,
);

export const selectSquadIds = squadSelectors.selectIds;
export const selectOrgIds = orgSelectors.selectIds;
export const selectOrg = optionalFriendly(orgSelectors.selectById);

export const selectCurrentOrg = (state: RootState): Optional<OrgOverview> =>
    selectOrg(state, selectCurrentOrgId(state));

// Select our own orgs, and any additional orgs with interest
const selectCurrentAndInterestedOrgIds = createAppSelector(
    [selectCurrentOrgIds, selectOrgsInterest],
    (currentIds, interestedIds) => currentIds.concat(interestedIds).removeDuplicates(),
    memoizeOptions.lruShallow,
);
export const startOrgInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectCurrentAndInterestedOrgIds,
    streamOrgOverviews,
    "subOrgOverviews",
    appendListener,
);

// Select our own squads, and any additional squads with interest
const selectCurrentAndInterestedSquadIds = createAppSelector(
    [selectCurrentSquadIds, selectSquadsInterest],
    (currentIds, interestedIds) => currentIds.concat(interestedIds).removeDuplicates(),
    memoizeOptions.lruShallow,
);
export const startSquadInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectCurrentAndInterestedSquadIds,
    streamSquadOverviews,
    "subSquadOverviews",
    appendListener,
);

const selectSquadIdsOrderedBySquadName = createSelector(
    [squadSelectors.selectAll],
    sos => sos.sort((a, b) => a.name.localeCompare(b.name)).map(bov => bov.id),
);

export const selectCurrentSquadIdsOrderedBySquadName = createSelector(
    [selectCurrentSquadIds, selectSquadIdsOrderedBySquadName],
    (currentSquadIds, orderedSquadIds) =>
        orderedSquadIds.filter(id => currentSquadIds.includes(id)),
);

export const selectAllSquads = squadSelectors.selectAll;

export const selectSquadById = optionalFriendly(squadSelectors.selectById);

export const selectSquadSize = (id: Optional<d.SquadId>) => (state: RootState): Optional<number> =>
    id && state.squads.squadSizes[id];

const selectSquadEntities = partialRecord(squadSelectors.selectEntities);
const isSquad = (so: Optional<SquadOverview>): so is SquadOverview => !!so;

export const selectUserIdsBySquadIds = createSelector(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadRecord, ids) =>
        ids.map(id => squadRecord[id])
            .filter(isSquad)
            .flatMap(so => so.userIds)
            .removeDuplicates(),
    memoizeOptions.weakMapShallow,
);

export const selectSquads = createAppSelector(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadMap, ids) => ids.map(id => squadMap[id]).filter(isSquad),
    memoizeOptions.weakMapShallow,
);

const selectOrgEntities = partialRecord(orgSelectors.selectEntities);
const isOrg = (oo: Optional<OrgOverview>): oo is OrgOverview => !!oo;

export const selectOrgs = createAppSelector(
    [selectOrgEntities, (_, ids: d.OrgId[]) => ids],
    (orgMap, ids) => ids.map(id => orgMap[id]).filter(isOrg),
    memoizeOptions.weakMapShallow,
);

export const selectUserIdsKnownToCurrentUser = shallowArrayEqualSelectorCreator(
    [selectSquadEntities],
    squadMap =>
        Object.values(squadMap)
            .flatMap(so => so?.userIds ?? [])
            .removeDuplicates(),
);

export const selectSquadIdsContainingUserIds = createAppSelector(
    [selectAllSquads, (_, userIds: d.UserId[]) => userIds],
    (squads, userIds) =>
        squads.filter(so => userIds.every(userId => so.userIds.includes(userId)))
            .map(so => so.id),
    memoizeOptions.weakMapShallow,
);

const squadArraysAreEqual = function (a: SquadOverview[], b: SquadOverview[]): boolean {
    if (a.length != b.length) return false;
    return a.every((v, i) =>
        v.id === b[i].id && v.name === b[i].name && arraysAreEqual(v.userIds, b[i].userIds)
    );
};

const selectSquadEntitiesByIds = createAppSelector(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadMap, ids) => ids.map(id => squadMap[id]).filter(isSquad),
    {
        ...memoizeOptions.weakMapShallow,
        memoizeOptions: {
            resultEqualityCheck: squadArraysAreEqual,
        },
    },
);

function sortSquadOverviewsByAudienceSize(a: SquadOverview, b: SquadOverview): number {
    return b.userIds.length - a.userIds.length;
}

export const selectKnownSquadNamesSortedByAudienceSize = shallowArrayEqualSelectorCreator(
    [selectSquadEntitiesByIds],
    squads =>
        squads
            .sort(sortSquadOverviewsByAudienceSize)
            .map(so => so.name),
);

const squadNames = shallowArrayEqualSelectorCreator(
    [selectSquadEntities, (_, ids: d.SquadId[]) => ids],
    (squadMap, ids) =>
        ids.map(id => squadMap[id])
            .filter(isSquad)
            .map(so => so.name),
);

export const selectKnownSquadNames = shallowArrayEqualSelectorCreator(
    [squadNames],
    (names: Optional<string>[]) => names.filter(n => n) as string[],
);

const observationSetSelector = createAppSelector(
    [selectors.observers],
    obs => obs && new Set(obs),
    memoizeOptions.weakMapShallow,
);

const setIsNotUndefined = <T>(s: Set<T> | undefined): s is Set<T> => !!s;

export const selectObservationsForSquadIds = createAppSelector(
    [state => state, (_, ids: d.SquadId[]) => ids],
    (state, ids) => {
        const sets = ids.map(id => observationSetSelector(state, id));
        return setUnion(...sets.filter(setIsNotUndefined));
    },
    memoizeOptions.weakMapShallow,
);

export const startSquadObserversInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectSquadsInterest,
    subSquadObserversThunk,
    "squadObserversManager",
    appendListener,
);

export const reducer = squadSlice.reducer;

// Persistence.

const stores = {
    orgs: idbStore<d.OrgId, OrgOverview>("orgs-2", 17),
    _orgs1: idbStore<d.OrgId, migrateV17.OrgOverview>("orgs", 1, 16),
    squads: idbStore<d.SquadId, SquadOverview>("squads", 1),
    orgMembership: idbStore<d.OrgId, true>("org-membership", 16),
    squadMembership: idbStore<d.SquadId, true>("squad-membership", 16),
    _squadMembership1: localStorageStore<d.SquadId[]>("memberships/squad", 1, 15),
};

type SquadsUpdate =
    | IdbStoreUpdate<typeof stores.orgs>
    | IdbStoreUpdate<typeof stores.squads>
    | IdbStoreUpdateWithClear<typeof stores.orgMembership>
    | IdbStoreUpdateWithClear<typeof stores.squadMembership>;

const persist = (
    previousState: SquadState,
    currentState: SquadState,
): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async (conn: Connection) => {
    const orgs = await fetchIdbStore(conn, stores.orgs);
    const squads = await fetchIdbStore(conn, stores.squads);

    const userOrgs = await fetchIdbStore(conn, stores.orgMembership);
    const userSquads = await fetchIdbStore(conn, stores.squadMembership);

    return getInitialState({
        orgs,
        squads,
        userOrgs: TypedKeys(userOrgs),
        userSquads: TypedKeys(userSquads),
    });
};

const migrate = async (tx: RWTransaction, oldVersion: number) => {
    if (oldVersion < 17) {
        await simpleIdbTranslate(
            tx,
            stores._orgs1,
            stores.orgs,
            migrateV17.translateOrgsOverview,
        );
    }
};

export const persistor = {
    stores,
    hydrate,
    persist,
    migrate,
};
checkPersistor<SquadState>(persistor);

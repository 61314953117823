import { Optional } from "../../misc/types";
import * as d from "../../domain/domain";
import useSelectorArgs from "../useSelectorArgs";
import { selectCurrentLiveBondId, selectLiveCallIdByBondId } from "../../features/bonds";
import { useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { closeLiveView, selectCurrentCallId, setCurrentCallId } from "../../features/calls";
import useRtcClientWithMediaControls from "./useRtcClientWithMediaControls";
import { useInspectSingleRtcSessionLock } from "../createSharedWebLock";
import useLocalDispatch from "../useLocalDispatch";

export interface UseRtcSessionMultitaskingProps {
    bondId: Optional<d.BondId>;
    disableMultitasking?: boolean;
}

/** @function Given the current bond, manage the call to join.
 * In particular, this enables "multitasking", whereby we can stay in a call
 * after leaving the corresponding bond.
 *
 * @param bondId the ID of the current bond, intended to be from the address parameters.
 *
 * @param disableMultitasking [false] optional flag to leave a call whenever we leave its bond.
 *
 * @returns An object containing various call participant arrays, media controls,
 * a leave callback, and whether we are currently multitasking.
 */
export default function useRtcSessionMultitasking(props: UseRtcSessionMultitaskingProps) {
    /*
    NB: Throughout this function, we have the following definitions:
    * "current bond" - the bond we are current looking at; the path is /bond/{currentBondId}
    * "current call" - the call we are trying to join or have joined; corresponds to an RTC session.
    * "current _live_ bond" - the bond to which the current call belongs.
    */

    const { bondId, disableMultitasking } = props;

    const isMultitaskingDisabled = disableMultitasking ?? false;

    const dispatch = useAppDispatch();
    const localDispatch = useLocalDispatch();

    const currentCallId = useAppSelector(selectCurrentCallId);
    const currentLiveBondId = useAppSelector(selectCurrentLiveBondId);

    // Mark the bond's call as the current call once we learn that the current bond is live.
    const liveBondCallId = useSelectorArgs(selectLiveCallIdByBondId, bondId);
    useEffect(() => {
        liveBondCallId && dispatch(setCurrentCallId(liveBondCallId));
    }, [dispatch, liveBondCallId]);

    // only switch from current call when new bond has its own live call
    const callId = liveBondCallId ?? currentCallId;

    // When the current bond goes live, leave the current call before switching to the new call.
    // Only do this once we have a non-null current callId to prevent us leaving before we've joined.
    //
    // Need this is a ref so that we can define the function after joining the call.
    const leaveSessionBeforeJoiningNewCall = useRef(() => {});
    useEffect(() => {
        if (currentCallId && callId !== currentCallId) {
            leaveSessionBeforeJoiningNewCall.current();
        }
    }, [callId, currentCallId]);

    // We are multitasking if we are live in a bond but not currently in that bond.
    const isMultitaskingInAnyTab = !!currentLiveBondId && currentLiveBondId !== bondId;

    // Multitasking only applies in the tab with the rtc lock.
    const haveSingleSessionLock = useInspectSingleRtcSessionLock();
    const isMultitasking = isMultitaskingInAnyTab && haveSingleSessionLock;

    const rtcWithMediaControls = useRtcClientWithMediaControls({ bondId, callId });
    const { leaveSession } = rtcWithMediaControls;

    useEffect(() => {
        leaveSessionBeforeJoiningNewCall.current = leaveSession;
    }, [leaveSession]);

    useEffect(() => {
        if (currentLiveBondId !== bondId && isMultitaskingDisabled) {
            leaveSessionBeforeJoiningNewCall.current();
        }
    }, [currentLiveBondId, bondId, isMultitaskingDisabled]);

    // Reset live (grid) view state on render and changing bond.
    // This guarantees that we don't automatically show the call grid.
    //
    // It's a bit gross to put this here, but it means we are able to navigate straight into
    // the grid view from the multitasking controls.
    useEffect(() => {
        if (currentLiveBondId !== bondId) {
            localDispatch(closeLiveView());
        }
    }, [localDispatch, bondId, currentLiveBondId]);

    return useMemo(() => (
        { isMultitasking, ...rtcWithMediaControls }
    ), [isMultitasking, rtcWithMediaControls]);
}

import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";

import type { BondContemporaries, BondOverviewDU, DeletedBondDU } from "../api/bonds";
import {
    BondObservations,
    getShareableBondInviteLink,
    inviteUserToBond,
    inviteUserToBondViaEmail,
    observeBond,
    observeBondPreviews,
    PrivacyLevel,
    redeemBondInvite,
    retrieveBondInvite,
    setArchiveBond,
    setFollowBond,
    subArchivedBonds,
    subBondCatchupSummaries,
    subBondContemporaries,
    subBondObservers,
    subBonds,
    updateBondTitle,
    subBond,
} from "../api/bonds";
import { BondOverview, CatchupKnowledge } from "../domain/bonds";
import { isNullRole } from "../domain/calls";
import { DraftTarget, DraftType } from "../domain/channels";
import * as d from "../domain/domain";
import { UserObservation } from "../domain/presence";
import { userSortByBondLiveActivity, userSortByPresence } from "../domain/users";
import { ConnectionStatus } from "../features/connection";
import log from "../misc/log";
import { union } from "../misc/primatives";
import {
    Diff,
    Optional,
    optionalFriendly,
    partialRecord,
    SerializableSet,
    TypedKeys,
} from "../misc/types";
import { separateDiscriminatedUnion } from "../misc/utils";
import { checkPersistor, clearKey, fetchIdbStore, idbStore } from "../persist/shared";
import type {
    Connection,
    IdbStoreUpdate,
    IdbStoreUpdateWithClear,
    PersistenceUpdate,
    RWTransaction,
} from "../persist/types";
import { appAppendListener, type AppAppendListener } from "../store/middleware";
import { createAppSelector } from "../store/redux";
import type { RootState } from "../store/types";
import { resetStore, selectCurrentUserId } from "./auth";
import { createBondFromMessageThunk } from "./bondCreation";
import { selectCallById, selectCurrentCallId, startCall } from "./calls";
import { selectUserIdSetWithMentions, selectUserIdSetWithUnread } from "./channels";
import { updateConnectionStatus } from "./connection";
import {
    selectBondInterest,
    selectInvitedBondInterest,
    selectVisibleBondInterest,
    startManagingInterest,
} from "./interest";
import { multiRpcStreamManager } from "./middleware";
import { createProxiedAsyncThunk } from "./proxiedThunk";
import { createSelectorPair, memoizeOptions, shallowArrayEqualSelectorCreator } from "./selectors";
import { selectUserIdsBySquadIds } from "./squads";
import { createStreamingAsyncThunk } from "./streamingThunk";
import { TrimmedThunkAPI, unaryThunkHandler } from "./thunk";
import { selectUsers } from "./users";

const bondsAdapter = createEntityAdapter({
    selectId: (bond: BondOverview) => bond.id,
    sortComparer: (a: BondOverview, b: BondOverview) => {
        if (a.liveCallIds.length > 0 && b.liveCallIds.length == 0) return -1;
        if (a.liveCallIds.length == 0 && b.liveCallIds.length > 0) return 1;
        return b.lastActivityAt - a.lastActivityAt;
    },
});

// TODO: should be easy to specify the return type of `action` parameter -
// but what is it?
type StreamedStoreUpdater = ReturnType<typeof streamedBonds>;
type WrappedValue<T> = T extends BondOverviewDU ? BondOverview : never;

const getOverview = (v: BondOverviewDU) => v.overview;

const bondStreamParser = <T extends BondOverviewDU>(
    getObject: (v: T) => WrappedValue<T>,
    action: (ts: WrappedValue<T>[]) => StreamedStoreUpdater,
) =>
(
    { thunkAPI, content: batchedMsgs }: {
        thunkAPI: TrimmedThunkAPI;
        content: (T | DeletedBondDU)[];
    },
) => {
    const [deleted, views] = separateDiscriminatedUnion<DeletedBondDU, T>(
        msg => msg.case === "deleted",
        batchedMsgs,
    );

    if (views.length) {
        const items = views.map(getObject).filterLatest(v => v.id);
        thunkAPI.dispatch(action(items));
    }

    if (deleted.length) {
        const ids = deleted.map(v => v.deletedId);
        thunkAPI.dispatch(removeBonds(ids));
    }
};

export const subBondsThunk = createStreamingAsyncThunk(
    "bonds/subBonds",
    {
        rpc: ({ state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return subBonds(userId, signal);
        },
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: args => bondStreamParser(getOverview, streamedBonds)(args),
    },
);

export const streamArchivedBondsList = createStreamingAsyncThunk(
    "bonds/streamArchivedList",
    {
        rpc: ({ state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return subArchivedBonds(userId, signal);
        },
        parser: ({ thunkAPI, content: responses }) => {
            if (responses.length === 0) return;
            thunkAPI.dispatch(streamedArchivedBonds(responses));
        },
    },
);

export const observeCurrentUserBondPreviews = createStreamingAsyncThunk<
    d.SquadId
>(
    "bonds/observePreviews",
    {
        rpc: ({ arg: squadId, state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return observeBondPreviews(squadId, userId, signal);
        },
        parser: () => {},
        logId: ({ arg: squadId }) => `observe for squad:${squadId}`,
    },
);

interface SubBondThunkArgs {
    bondId: d.BondId;
}

export const subBondThunk = createStreamingAsyncThunk<
    SubBondThunkArgs,
    BondOverviewDU | DeletedBondDU
>(
    "bonds/subBond",
    {
        rpc: ({ arg: { bondId }, signal }) => {
            if (!bondId) return;
            return subBond(bondId, signal);
        },
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: args => bondStreamParser(getOverview, streamedBonds)(args),
        logId: ({ arg: { bondId } }) => `bond overview id:${bondId}`,
    },
);

interface ObserveBondThunkArgs {
    bondId: Optional<d.BondId>;
    doObserve: boolean;
}
const observeBondThunk = createStreamingAsyncThunk<
    ObserveBondThunkArgs,
    BondOverviewDU | DeletedBondDU
>(
    "bonds/streamOverview",
    {
        rpc: ({ arg: { bondId, doObserve }, signal }) => {
            if (!bondId) return;
            return observeBond(bondId, signal, doObserve);
        },
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: args => bondStreamParser(getOverview, streamedBonds)(args),
        logId: ({ arg: { bondId, doObserve } }) => `observe bond:${bondId} observe${doObserve}`,
    },
);

export const streamAndObserveBond = (bondId: Optional<d.BondId>) =>
    observeBondThunk({ bondId, doObserve: true });

export const visibleBondObservationManager = multiRpcStreamManager(
    selectVisibleBondInterest,
    streamAndObserveBond,
    "streamAndObserveBond",
);

export const streamBond = (bondId: d.BondId) => observeBondThunk({ bondId, doObserve: false });
export const bondStreamingManager = multiRpcStreamManager(
    selectBondInterest,
    streamBond,
    "streamBond",
);

export const invitedBondStreamingManager = multiRpcStreamManager(
    selectInvitedBondInterest,
    (bId: d.BondId) => subBondThunk({ bondId: bId }),
    "streamInvitedBond",
);

const subBondObserversThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.BondId>>,
    BondObservations
>(
    "bonds/subBondObservers",
    {
        rpc: ({ arg, signal }) => subBondObservers(arg, signal),
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: ({ thunkAPI, content: msgs }) => {
            const action = bondsSlice.actions.streamedManyBondObservers(
                msgs.filterLatest(msg => msg.viewId),
            );
            thunkAPI.dispatch(action);
        },
    },
);
export const startSubBondObserversStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectBondInterest,
    subBondObserversThunk,
    "subBondObservers",
    appendListener,
);

interface FollowBondArgs {
    bondId: d.BondId;
    follow: boolean;
}

export const followBond = createProxiedAsyncThunk(
    "bonds/follow",
    async (args: FollowBondArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({ error: `Tried to (un)follow bond without a bond id` });
        }

        log.info(`${args.follow ? "F" : "Unf"}ollowing ${args.bondId}`);

        return await unaryThunkHandler(
            thunkAPI,
            setFollowBond(args),
            `setFollowBond ${args.follow ? "true" : "false"} for ${args.bondId}`,
        );
    },
);

interface ArchiveBondArgs {
    bondId: d.BondId;
    archive: boolean;
    channelId?: d.ChannelId;
}

export const archiveBond = createProxiedAsyncThunk(
    "bonds/archive",
    async (args: ArchiveBondArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to (un)archive bond without a bond id`,
            });
        }

        log.info(`${args.archive ? "Archiving" : "Unarchiving"} ${args.bondId}`);

        await unaryThunkHandler(
            thunkAPI,
            setArchiveBond(args),
            `setArchiveBond ${args.archive ? "true" : "false"} for ${args.bondId}`,
        );
    },
);

interface UpdateBondTitleArgs {
    bondId: d.BondId;
    title: string;
}

export const renameBondTitle = createProxiedAsyncThunk(
    "bonds/titleupdate",
    async (args: UpdateBondTitleArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({ error: `Tried to rename bond without a bond id` });
        }

        log.info(`Renaming ${args.bondId}`);

        return await unaryThunkHandler(
            thunkAPI,
            updateBondTitle(args),
            `updateBondTitle ${args.bondId}`,
        );
    },
);

interface InviteUserToBondArgs {
    bondId: d.BondId;
    invitedUserId: d.UserId;
}

export const inviteUserToBondThunk = createProxiedAsyncThunk(
    "bonds/invite",
    async (args: InviteUserToBondArgs, thunkAPI) => {
        if (!args || !args.bondId || !args.invitedUserId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to invite user to bond without a bond id`,
            });
        }

        log.info(`Inviting ${args.invitedUserId} to ${args.bondId}`);

        return await unaryThunkHandler(
            thunkAPI,
            inviteUserToBond(args),
            `inviteUserToBond ${args.invitedUserId} to ${args.bondId}`,
        );
    },
);

interface InviteUserToBondViaEmailArgs {
    bondId: d.BondId;
    invitedEmailAddress: string;
}

export const inviteUserToBondViaEmailThunk = createProxiedAsyncThunk(
    "bonds/inviteUserToBondViaEmail",
    async (args: InviteUserToBondViaEmailArgs, thunkAPI) => {
        if (!args || !args.bondId || !args.invitedEmailAddress) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to invite user to bond without a bond id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            inviteUserToBondViaEmail(args),
            `inviteUserToBond for ${args.bondId}`,
        );
    },
);

interface GetShareableBondInviteLinkArgs {
    bondId: d.BondId;
}

export const getShareableBondInviteLinkThunk = createProxiedAsyncThunk(
    "bonds/getShareableBondInviteLink",
    async (args: GetShareableBondInviteLinkArgs, thunkAPI) => {
        if (!args || !args.bondId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to invite user to bond without a bond id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            getShareableBondInviteLink(args),
            `getShareableBondInviteLink for ${args.bondId}`,
        );
    },
);

interface RetriveBondInviteArgs {
    opaqueCode: string;
}

export const retrieveBondInviteThunk = createProxiedAsyncThunk(
    "bonds/retrieveBondInvite",
    async (args: RetriveBondInviteArgs, thunkAPI) => {
        if (!args) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to retrieve invite without code`,
            });
        }

        const resp = await unaryThunkHandler(
            thunkAPI,
            retrieveBondInvite(args),
            `retrieveBondInvite with code ${args.opaqueCode}`,
        );
        return resp?.invite;
    },
);

interface RedeemBondInviteArgs {
    opaqueCode: string;
}

export const redeemBondInviteThunk = createProxiedAsyncThunk(
    "bonds/redeemBondInvite",
    async (args: RedeemBondInviteArgs, thunkAPI) => {
        if (!args) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to redeem invite without code`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            redeemBondInvite(args),
            `redeemBondInvite with code ${args.opaqueCode}`,
        );
    },
);

export const subBondContemporariesThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.BondId>>,
    BondContemporaries
>(
    "users/subBondContemporaries",
    {
        rpc: ({ arg, signal }) => subBondContemporaries(arg, signal),
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: ({ thunkAPI, content: msgs }) => {
            const action = bondsSlice.actions.streamedManyBondContemporaries(msgs);
            thunkAPI.dispatch(action);
        },
    },
);

export const subBondCatchupSummariesThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.BondId>>,
    CatchupKnowledge
>(
    "users/subBondCatchupSummaries",
    {
        rpc: ({ arg, state, signal }) => {
            const userId = selectCurrentUserId(state)!;
            return subBondCatchupSummaries(arg, { userId }, signal);
        },
        // Action to dispatch is defined after this function call, hence the lambda.
        parser: ({ thunkAPI, content: msgs }) => {
            const action = bondsSlice.actions.streamedCatchupSummaries(msgs);
            thunkAPI.dispatch(action);
        },
    },
);

export interface BondState {
    bonds: EntityState<BondOverview, d.BondId>;
    channelIndex: Record<d.ChannelId, d.BondId>;
    liveCallIndex: Record<d.CallId, d.BondId>;
    archivedBonds: SerializableSet<d.BondId>;
    observers: Record<d.BondId, Array<UserObservation>>;
    contemporaries: Record<d.BondId, Array<d.UserId>>;
    catchupKnowledge: Record<d.BondId, CatchupKnowledge>;

    // Not persisted. Interpreted from the current URL.
    selectedBond?: d.BondId;

    updates: BondUpdate[];

    cardViewScrollTop: number;
}

const formChannelIndex = (bonds: BondOverview[]): Record<d.ChannelId, d.BondId> =>
    bonds.reduce((index, { id: bondId, channelId }) => ({ ...index, [channelId]: bondId }), {});

const formLiveCallIndex = (bonds: BondOverview[]): Record<d.CallId, d.BondId> =>
    bonds.reduce(
        (
            index,
            { id: bondId, liveCallIds },
        ) => (liveCallIds.length > 0 ? { ...index, [liveCallIds[0]]: bondId } : index),
        {},
    );

const getInitialState = (props?: {
    bonds?: Record<d.BondId, BondOverview>;
    catchupKnowledge?: Record<d.BondId, CatchupKnowledge>;
    archivedBonds?: SerializableSet<d.BondId>;
}): BondState => ({
    bonds: bondsAdapter.getInitialState({}, props?.bonds ?? {}),
    channelIndex: formChannelIndex(Object.values(props?.bonds ?? {})),
    liveCallIndex: formLiveCallIndex(Object.values(props?.bonds ?? {})),
    archivedBonds: props?.archivedBonds ?? {},
    observers: {},
    contemporaries: {},
    catchupKnowledge: props?.catchupKnowledge ?? {},
    updates: [],
    cardViewScrollTop: 0,
});

export const bondsSlice = createSlice({
    name: "bonds",
    initialState: getInitialState(),
    selectors: {
        selectedBond: state => state.selectedBond,
        archivedBonds: state => state.archivedBonds,
        channel: (state, channelId: Optional<d.ChannelId>): Optional<d.BondId> =>
            channelId && state.channelIndex[channelId],
        liveCall: (state, callId: Optional<d.CallId>): Optional<d.BondId> =>
            callId && state.liveCallIndex[callId],
        observers: (state, bondId: Optional<d.BondId>): Optional<UserObservation[]> =>
            bondId && state.observers[bondId],
        contemporaries: (state, bondId: Optional<d.BondId>): Optional<d.UserId[]> =>
            bondId && state.contemporaries[bondId],
        catchupKnowledge: (state, bondId: Optional<d.BondId>): Optional<CatchupKnowledge> =>
            bondId && state.catchupKnowledge[bondId],
        cardsViewScrollTop: state => state.cardViewScrollTop,
    },
    reducers: {
        streamedMany: (state, { payload: bos }: PayloadAction<BondOverview[]>) => {
            state.bonds = bondsAdapter.upsertMany(state.bonds, bos);
            bos.forEach(bo => {
                state.channelIndex[bo.channelId] = bo.id;
                bo.liveCallIds.forEach(callId => state.liveCallIndex[callId] = bo.id);
            });

            state.updates = bos.map(bo => [stores.bonds, bo.id, bo]);
        },
        removeMany: (state, { payload: ids }: PayloadAction<d.BondId[]>) => {
            ids.forEach(bondId => {
                const bo = state.bonds.entities[bondId];
                if (bo) {
                    delete state.channelIndex[bo.channelId];
                    bo.liveCallIds.forEach(callId => delete state.liveCallIndex[callId]);
                }
            });
            state.bonds = bondsAdapter.removeMany(state.bonds, ids);

            state.updates = ids.map(bondId => [stores.bonds, bondId]);
        },
        updateSelected: (state, { payload: id }: PayloadAction<Optional<d.BondId>>) => {
            if (state.selectedBond === id) return;

            state.selectedBond = id;
        },
        clearSelected: state => {
            delete state.selectedBond;
        },
        streamedArchivedBonds: (
            state,
            actions: PayloadAction<(Diff<d.BondId> & { overwrite?: boolean; })[]>,
        ) => {
            state.updates = [];
            actions.payload.forEach(action => {
                if (action.overwrite) {
                    state.archivedBonds = {};
                    state.updates = [[
                        stores.archivedBonds,
                        clearKey,
                    ]];
                }
                action.added?.forEach(addedId => {
                    state.archivedBonds[addedId] = true;
                    state.updates.push([
                        stores.archivedBonds,
                        addedId,
                        true,
                    ]);
                });
                action.removed?.forEach(removedId => {
                    delete state.archivedBonds[removedId];
                    state.updates.push([
                        stores.archivedBonds,
                        removedId,
                    ]);
                });
            });
        },
        streamedBondObservers: (state, action: PayloadAction<BondObservations>) => {
            const observers = action.payload;
            state.observers[observers.viewId] = observers.observations;
        },
        streamedManyBondObservers: (state, { payload: obs }: PayloadAction<BondObservations[]>) => {
            obs.forEach(observers => state.observers[observers.viewId] = observers.observations);
        },
        streamedBondContemporaries: (state, action: PayloadAction<BondContemporaries>) => {
            const { bondId, userIds } = action.payload;
            state.contemporaries[bondId] = userIds;
        },
        streamedManyBondContemporaries: (
            state,
            { payload: contemps }: PayloadAction<BondContemporaries[]>,
        ) => {
            state.updates = [];
            contemps.forEach(({ bondId, userIds }) => {
                state.contemporaries[bondId] = userIds;
            });
        },
        setCardsViewScrollTop: (state, action: PayloadAction<number>) => {
            state.cardViewScrollTop = action.payload;
        },
        streamedCatchupSummaries: (
            state,
            { payload }: PayloadAction<Array<CatchupKnowledge>>,
        ) => {
            if (payload.length == 0) return;

            payload.forEach(knowledge => {
                state.catchupKnowledge[knowledge.bondId] = knowledge;
            });

            state.updates = payload.map(knowledge => [
                stores.catchupKnowledge,
                knowledge.bondId,
                knowledge,
            ]);
        },
    },
    extraReducers: builder => {
        builder.addCase(createBondFromMessageThunk.fulfilled, (state, action) => {
            const bo = action.payload;
            state.bonds = bondsAdapter.upsertOne(state.bonds, bo);
            state.channelIndex[bo.channelId] = bo.id;

            state.updates = [[stores.bonds, bo.id, bo]];
        });
        // Add call ID to bond when going live
        builder.addCase(startCall.fulfilled, (state, action) => {
            const { bondId } = action.meta.arg;
            const callId = action.payload;
            if (!callId) {
                return;
            }

            const bo = state.bonds.entities[bondId];
            if (bo) {
                const updated = {
                    ...bo,
                    liveCallIds: [...bo.liveCallIds, callId],
                };

                state.bonds = bondsAdapter.upsertOne(state.bonds, updated);
                state.liveCallIndex[callId] = bo.id;
            }
        });
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
        builder.addCase(updateConnectionStatus, (state, action) => {
            if (action.payload !== ConnectionStatus.Connected) {
                state.observers = {};
            }
        });
    },
});

const sliceSelectors = bondsSlice.getSelectors<RootState>(state => state.bonds);

export const {
    selectedBond: selectSelectedBondId,
    cardsViewScrollTop: selectCardsViewScrollTop,
    channel: selectBondIdByChannelId,
    liveCall: selectBondIdByLiveCallId,
} = sliceSelectors;

export const {
    clearSelected: clearSelectedBond,
    streamedBondObservers,
    streamedManyBondObservers,
    streamedMany: streamedBonds,
    streamedArchivedBonds,
    updateSelected: updateSelectedBond,
    removeMany: removeBonds,
    setCardsViewScrollTop,
    streamedCatchupSummaries,
    streamedBondContemporaries,
} = bondsSlice.actions;

const bondsSelectors = bondsAdapter.getSelectors<RootState>(
    state => state.bonds.bonds,
);
export const startBondContemporariesStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    bondsSelectors.selectIds,
    subBondContemporariesThunk,
    "subBondContemporaries",
    appendListener,
);
export const startBondCatchupSummariesStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    bondsSelectors.selectIds,
    subBondCatchupSummariesThunk,
    "subBondCatchupSummaries",
    appendListener,
);

export const selectBonds = bondsSelectors.selectAll;
export const selectBondEntities = partialRecord(bondsSelectors.selectEntities);
export const selectBondIds = bondsSelectors.selectIds;

export const selectBondById = optionalFriendly(bondsSelectors.selectById);

export const selectBondByDraftTarget = (
    state: RootState,
    dt: DraftTarget,
): Optional<BondOverview> => {
    if (dt.type !== DraftType.Channel) return;
    const bondId = selectBondIdByChannelId(state, dt.channelId);
    return selectBondById(state, bondId);
};

export const selectSelectedBond = (state: RootState): Optional<BondOverview> => {
    const id = sliceSelectors.selectedBond(state);
    if (!id) {
        return undefined;
    }
    return selectBondById(state, id);
};

export const selectIsBondLive = (state: RootState, id: Optional<d.BondId>): Optional<boolean> => {
    const bond = selectBondById(state, id);
    return bond && (bond.liveCallIds?.length ?? 0) > 0;
};

export const selectIsBondOneOnOne = (
    state: RootState,
    id: Optional<d.BondId>,
): Optional<boolean> => {
    const bond = selectBondById(state, id);
    if (!bond) return;
    const bondHasNoSquads = (bond?.squadIds || []).length == 0;
    const bondHasAtMostTwoFollowers = (bond?.followers || []).length <= 2;
    return bondHasNoSquads && bondHasAtMostTwoFollowers;
};

const selectFollowers = createAppSelector(
    [selectBondById],
    bo => bo?.followers ?? [],
    memoizeOptions.weakMapShallow,
);

const followerAdjustments = { sort: userSortByPresence };
const selectSortedFollowers = (state: RootState, id: Optional<d.BondId>) => {
    const followers = selectFollowers(state, id);
    return selectUsers(state, followers, followerAdjustments);
};

export const selectSortedFollowerIdsPair = createSelectorPair(
    selectFollowers,
    createAppSelector(
        [selectSortedFollowers],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

export const selectBondContemporaries = createAppSelector(
    [sliceSelectors.contemporaries],
    contemps => contemps?.toSet(),
);

export const selectChannelIdByBondId =
    (bondId: Optional<d.BondId>) => (state: RootState): Optional<d.ChannelId> =>
        selectBondById(state, bondId)?.channelId;
export const selectLiveCallIdByBondId = createAppSelector(
    [selectBondById],
    bo => bo?.liveCallIds?.[0],
);
export const selectOrgIdByBondId =
    (id: Optional<d.BondId>) => (state: RootState): Optional<d.OrgId> =>
        selectBondById(state, id)?.orgId;

export const selectArchivedBondsSet = createAppSelector(
    [sliceSelectors.archivedBonds],
    ids => TypedKeys(ids).toSet(),
);

export const selectBondIsArchived = createAppSelector(
    [selectArchivedBondsSet, (_: RootState, id: Optional<d.BondId>) => id],
    (set, id) => !!(id && set.has(id)),
    memoizeOptions.weakMap,
);

export const selectBondIsContemporary = createAppSelector(
    [
        selectBondContemporaries,
        selectCurrentUserId,
    ],
    (set, userId) => !!(userId && set && set.has(userId)),
    memoizeOptions.weakMap,
);

export const selectMostRecentBondId = (state: RootState): Optional<d.BondId> =>
    bondsSelectors.selectIds(state)[0];

export const selectSquadIdsFromBondId = shallowArrayEqualSelectorCreator(
    [selectBondById],
    bo => bo?.squadIds ?? [],
);

export const selectBondIsFollower = createAppSelector(
    [(state, _) => selectCurrentUserId(state), selectBondById],
    (user, bo) => !!(user && bo?.followers?.includes(user)),
    memoizeOptions.weakMap,
);

export const selectBondIsPrivate = (
    state: RootState,
    id: Optional<d.BondId>,
): Optional<boolean> => {
    const bond = selectBondById(state, id);
    return bond && bond.privacy === PrivacyLevel.PRIVATE;
};

export const selectPrivateBondmates = createAppSelector(
    [selectBonds],
    overviews =>
        new Set(
            overviews
                .filter(bo => bo.privacy === PrivacyLevel.PRIVATE)
                .flatMap(bo => [bo.contributors, bo.followers, bo.externalUsers])
                .flat(),
        ),
);

const selectBondObserverIds = createAppSelector(
    [sliceSelectors.observers],
    obs => obs?.map(o => o.userId) ?? [],
    memoizeOptions.weakMapShallow,
);

const selectBondObserverSet = createAppSelector(
    [selectBondObserverIds],
    observerIds => new Set(observerIds),
    memoizeOptions.weakMapShallow,
);

const selectBondUserIdsWithMentions = (state: RootState, bondId: Optional<d.BondId>) => {
    const bo = selectBondById(state, bondId);
    return selectUserIdSetWithMentions(state, bo?.channelId);
};

const selectBondUserIdsWithUnreads = (state: RootState, bondId: Optional<d.BondId>) => {
    const bo = selectBondById(state, bondId);
    return selectUserIdSetWithUnread(state, bo?.channelId);
};

export interface LiveActivityArgs {
    videoUserSet?: Set<d.UserId>;
    liveUserSet?: Set<d.UserId>;
    orderCurrentUserFirst?: boolean;
}
const selectLiveActivityArgs = (
    _state: RootState,
    _id: Optional<d.BondId>,
    liveArgs?: LiveActivityArgs,
) => liveArgs;

const selectBondActivityAdjustments = createAppSelector(
    [
        selectBondObserverSet,
        selectBondUserIdsWithMentions,
        selectBondUserIdsWithUnreads,
        selectCurrentUserId,
        selectLiveActivityArgs,
    ],
    (observerUserSet, mentionUserSet, unreadUserSet, currentUserId, liveArgs) => ({
        sort: userSortByBondLiveActivity(
            {
                observerUserSet,
                mentionUserSet,
                unreadUserSet,
                videoUserSet: liveArgs?.videoUserSet,
                liveUserSet: liveArgs?.liveUserSet,
            },
            { currentUserId, orderCurrentUserFirst: liveArgs?.orderCurrentUserFirst },
        ),
    }),
    memoizeOptions.weakMap,
);

/**
 * Select the ID of the bond to which the current call belongs.
 */
export const selectCurrentLiveBondId = (state: RootState) => {
    const currentCallId = selectCurrentCallId(state);
    return selectBondIdByLiveCallId(state, currentCallId);
};

const selectBondLiveCall = (state: RootState, bondId: Optional<d.BondId>) => {
    const liveCallId = selectLiveCallIdByBondId(state, bondId);
    return selectCallById(state, liveCallId);
};

const selectBondLiveParticipantIds = createAppSelector(
    [selectBondLiveCall],
    co => co?.participants.filter(p => !isNullRole(p.role)).map(p => p.userId),
    memoizeOptions.weakMapShallow,
);

const selectSortedBondParticipants = (
    state: RootState,
    id: Optional<d.BondId>,
    participants: Optional<d.UserId[]>,
    liveArgs?: LiveActivityArgs,
) => {
    const adjustments = selectBondActivityAdjustments(state, id, liveArgs);
    return selectUsers(state, participants, adjustments);
};

export const selectSortedBondParticipantIdsPair = createSelectorPair(
    (
        _state: RootState,
        _id: Optional<d.BondId>,
        participants: Optional<d.UserId[]>,
        _liveArgs?: LiveActivityArgs,
    ) => participants,
    createAppSelector(
        [selectSortedBondParticipants],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

const selectSortedBondLiveParticipants = (state: RootState, id: Optional<d.BondId>) => {
    const participants = selectBondLiveParticipantIds(state, id);
    return selectSortedBondParticipants(state, id, participants);
};

export const selectSortedBondLiveParticipantIdsPair = createSelectorPair(
    selectBondLiveParticipantIds,
    createAppSelector(
        [selectSortedBondLiveParticipants],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

const selectSortedBondObservers = (state: RootState, id: Optional<d.BondId>) => {
    const observerIds = selectBondObserverIds(state, id);
    return selectSortedBondParticipants(state, id, observerIds);
};

export const selectSortedBondObserverIdsPair = createSelectorPair(
    selectBondObserverIds,
    createAppSelector(
        [selectSortedBondObservers],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

const bondDirectInvolvees = createAppSelector(
    [selectBondById],
    bo => union(bo?.contributors, bo?.followers, bo?.externalUsers),
    memoizeOptions.weakMapShallow,
);

const allBondInvolvees = createAppSelector(
    [
        (state: RootState, id: d.BondId, _squadIds: d.SquadId[]) => bondDirectInvolvees(state, id),
        (state: RootState, _: d.BondId, squadIds: d.SquadId[]) =>
            selectUserIdsBySquadIds(state, squadIds),
    ],
    (direct, indirect) => union(direct, indirect),
    memoizeOptions.weakMapShallow,
);

export const selectAllBondInvolvees = (state: RootState, id: Optional<d.BondId>) => {
    if (!id) return [];

    const squads = selectSquadIdsFromBondId(state, id);
    return allBondInvolvees(state, id, squads);
};

export const emojiRegex =
    /^(?:\p{Extended_Pictographic}|\p{Emoji_Component})+(?:\p{Emoji_Modifier}|\p{Emoji_Modifier_Base}|\p{Emoji_Presentation}|\u200d)*/u;

export const selectBondTitle = createAppSelector(
    [selectBondById],
    (bo: Optional<BondOverview>) => {
        const liveTitle = bo?.knowledge.userSpecifiedTitle || bo?.knowledge.aiGeneratedTitle || "";
        const aiGenerated = bo?.knowledge.userSpecifiedTitle === "";

        // split emoji from title ONLY if it's AI generated
        let emoji = "";
        if (aiGenerated) {
            emoji = liveTitle.match(emojiRegex)?.[0] || "";
        }
        return {
            title: liveTitle.replace(emoji, "").trim(),
            emoji: emoji,
            aiGenerated: aiGenerated,
        };
    },
    memoizeOptions.weakMap,
);

export const selectAllContentBondSummary = createAppSelector(
    [selectBondById],
    (bo: Optional<BondOverview>) => {
        if (!bo) return "";
        return bo.knowledge.summary;
    },
);

export const selectCatchupKnowledge = sliceSelectors.catchupKnowledge;

export const selectDetailedBondSummary = createAppSelector(
    [selectBondById],
    (bo: Optional<BondOverview>) => {
        if (!bo) return;

        return bo.knowledge.detailedSummary || bo.knowledge.summary;
    },
);

export const selectBondImageUrl = createAppSelector(
    [selectBondById],
    (bo: Optional<BondOverview>) => {
        const noImage = "/assets/kakapo.webp";
        if (!bo) {
            return noImage;
        }
        return bo.knowledge.imageUrl || noImage;
    },
);

export const reducer = bondsSlice.reducer;

// Persistence.

const stores = {
    bonds: idbStore<d.BondId, BondOverview>("bonds", 1),
    catchupKnowledge: idbStore<d.BondId, CatchupKnowledge>("catchup-knowledge", 1),
    archivedBonds: idbStore<d.BondId, true>("archived-bonds", 1),
};

type BondUpdate =
    | IdbStoreUpdate<typeof stores.bonds>
    | IdbStoreUpdate<typeof stores.catchupKnowledge>
    | IdbStoreUpdateWithClear<typeof stores.archivedBonds>;

const persist = (previousState: BondState, currentState: BondState): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async (conn: Connection) => {
    const bonds = await fetchIdbStore(conn, stores.bonds);
    const catchupKnowledge = await fetchIdbStore(conn, stores.catchupKnowledge);
    const archivedBonds = await fetchIdbStore(conn, stores.archivedBonds);

    return getInitialState({ bonds, catchupKnowledge, archivedBonds });
};

const migrate = async (_tx: RWTransaction, oldVersion: number) => {
    if (oldVersion < 14) {
        // Clean up legacy localStorage keys.
        localStorage.removeItem("archived/archivedbonds");
        localStorage.removeItem("archived/bonds-record");
        // TODO: remove once oldestMigratableVersion > 13
    }
};

export const persistor = {
    stores,
    persist,
    hydrate,
    migrate,
};
checkPersistor<BondState>(persistor);

import { useState } from "react";
import { UserId } from "../../domain/domain";
import { fetchContactsBookThunk } from "../../features/users";
import log from "../../misc/log";
import { useAppDispatch } from "../../store/redux";
import { useConnectedEffect } from "../useConnectedEffect";
import { useInterestedUsers } from "./useInterest";

// Fetch the list of all the current user's potential contacts and register interest in them
export const useInterestedContacts = () => {
    const dispatch = useAppDispatch();

    const [contactIds, setContactIds] = useState<Array<UserId>>([]);

    useConnectedEffect(() => {
        dispatch(fetchContactsBookThunk())
            .unwrap()
            .then(cs => setContactIds(cs))
            .catch(e => {
                log.error("Failed to fetch contacts", e);
            });
    }, [dispatch]);

    useInterestedUsers(contactIds);
};

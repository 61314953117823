import { UnknownAction } from "@reduxjs/toolkit";

import SharedLogWorker from "@/misc/log/archiveWorker?sharedworker";
import * as d from "../domain/domain";
import { getLocalPreferredUserId } from "../features/auth";
import { getDeviceTag } from "../features/meta";
import { dispatchThunkResponse } from "../features/proxiedThunk";
import { promiseWithResolvers } from "../misc/promises";
import { getHydrationData } from "../persist/persist";
import {
    isProxiedResponse,
    listenForProxiedDispatches,
    type ProxiedMessage,
    ProxiedMessageDirection,
} from "../store/proxy";
import { setupStore } from "../store/setup";
import type { AppStore } from "../store/types";
import Overseer from "./overseer.ts?sharedworker";
import { workerInitRequest, type WorkerInitResponse } from "./types";
import { isSharedWorker, sharedWorkerAvailable } from "./util";
import { workerOptions } from "@/misc/log";
import { isNativePlatform } from "@/misc/capacitor";

let worker: SharedWorker;

const spawnWorker = async (userId: d.UserId, deviceTag: string): Promise<AppStore> => {
    if (isSharedWorker()) {
        throw new Error(`Cannot spawn workers from within a SharedWorker`);
    }

    worker = new Overseer({ name: `overseer-${userId}` });

    const statePromise = promiseWithResolvers<WorkerInitResponse["payload"]>();

    worker.port.addEventListener(
        "message",
        ({ data: { payload } }: MessageEvent<WorkerInitResponse>) => {
            statePromise.resolve(payload);
        },
        { once: true },
    );

    worker.port.start();

    // Capture all messages broadcast by the overseer.
    // When we receive the full `state`, we will apply any
    // actions that have been broadcast since the serial number
    // provided to us at the same time as the `state`.
    const q: [number, UnknownAction][] = [];

    const onMessage = (msg: ProxiedMessage) => {
        if (!isProxiedResponse(msg)) return;

        const { seqNo, action } = msg;

        q.push([seqNo, action]);
    };

    const stopListening = listenForProxiedDispatches(
        userId,
        ProxiedMessageDirection.Broadcast,
        onMessage,
    );

    // Create a port for the overseer to use for logging.
    const port = new SharedLogWorker(workerOptions).port;

    // Open our connection to the shared worker.
    worker.port.postMessage(workerInitRequest({ userId, deviceTag, port }), [port]);

    const { state, readFromSeqNo } = await statePromise.promise;

    const store = setupStore(state, { proxy: true, dispatchThunkResponse });

    const i = q.findIndex(([seqNo, _]) => seqNo === readFromSeqNo);
    q.slice(i).map(([_, action]) => action).forEach(store.dispatch);
    // This is a Javascript-wut API, but we want to clear out references to
    // actions that we no longer need references to, without redefining `q`.
    q.length = 0;

    stopListening();

    return store;
};

/** Create a store that will *not* proxy to an overseer.
 *
 * This is currently only for the "shared worker unavailable" case.
 */
const fetchLocalStore = async (userId: d.UserId): Promise<AppStore> => {
    const data = await getHydrationData(userId);
    // TODO: implement tab leadership contest, winning tab becomes
    // the "overseer" in spirit, and persists, manages connection, etc.
    return setupStore(data, { persist: true, manageConnection: true, startListeners: true });
};

/** Get a handle to a working store.
 *
 * If shared workers are available, this means a store that's up-to-date with
 * the overseer, and automatically applying updates broadcast from the overseer.
 *
 * If shared workers aren't available, that means a store hydrated with data
 * from IndexedDB, and that is managing its own connection.
 */
export const startStore = async (): Promise<AppStore> => {
    const userId = getLocalPreferredUserId();
    const deviceTag = getDeviceTag();

    if (!userId) {
        // Don't start listeners - there's nothing for them to do.
        return setupStore({}, { manageConnection: true });
    }

    if (!sharedWorkerAvailable() || isNativePlatform) return await fetchLocalStore(userId);

    return await spawnWorker(userId, deviceTag);
};

import { createProxiedAsyncThunk } from "./proxiedThunk";
import { selectCurrentUserId } from "../features/auth";
import { getBondTitleSuggestion } from "../api/intel";
import { unaryThunkHandler } from "./thunk";

interface fetchBondTitleSuggestionArgs {
    bondContentDraft: string;
}

export const fetchBondTitleSuggestionThunk = createProxiedAsyncThunk(
    "intel/fetchBondTitleSuggestion",
    async ({ bondContentDraft }: fetchBondTitleSuggestionArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state);
        if (!userId) {
            throw thunkAPI.rejectWithValue({
                error: `Tried to fetch bond title suggestion but did not find current user id`,
            });
        }

        return await unaryThunkHandler(
            thunkAPI,
            getBondTitleSuggestion({
                userId,
                bondContentDraft,
            }),
            "fetchBondTitleSuggestion",
        );
    },
);

import { useAppDispatch } from "../store/redux";
import useAddressParams from "../hooks/useAddressParams";
import {
    redeemBondInviteThunk,
    retrieveBondInviteThunk,
    selectBondById,
    selectBondTitle,
} from "../features/bonds";
import { useNavigate } from "react-router-dom";
import * as d from "../domain/domain";
import { useConnectedEffect } from "../hooks/useConnectedEffect";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Optional } from "../misc/types";
import useSelectorArgs from "../hooks/useSelectorArgs";
import classNames from "classnames";
import { selectCurrentUserId, updateAuthInviteCode } from "../features/auth";
import log from "../misc/log";
import { selectUser } from "../features/users";
import { BondInvite } from "../domain/bonds";
import { useInterestedInvitedBond } from "../hooks/interest/useInterest";

enum InviteStatus {
    Unknown,
    RetrievingDetails,
    ConfirmingJoin,
    Redeeming,
    Failed,
    Unauthorized,
    Expired,
}

export default function InviteRedeptionView(): React.JSX.Element {
    const { opaqueInviteCode } = useAddressParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [inviteStatus, setInviteStatus] = useState<InviteStatus>(
        InviteStatus.RetrievingDetails,
    );

    const currentUserId = useSelectorArgs(selectCurrentUserId);
    const [retrievedBondId, setRetrievedBondId] = useState<Optional<d.BondId>>(undefined);
    const retrievedBond = useSelectorArgs(selectBondById, retrievedBondId);

    const alreadyInBond = useMemo(() => {
        return retrievedBondId && retrievedBond && currentUserId &&
            retrievedBond.followers.includes(currentUserId);
    }, [currentUserId, retrievedBond, retrievedBondId]);

    const { email: currentUserEmail } = useSelectorArgs(selectUser, currentUserId) || {};

    useEffect(() => {
        if (opaqueInviteCode) {
            dispatch(updateAuthInviteCode(opaqueInviteCode));
        }

        return () => {
            dispatch(updateAuthInviteCode(undefined));
        };
    }, [dispatch, opaqueInviteCode]);

    // Stream live information about the bond specified by the invite
    useInterestedInvitedBond(retrievedBondId);

    const inviteIsForCurrentUser = useCallback((bi: BondInvite) => {
        if (bi.target.case == "common") {
            return true;
        }

        const invitee = bi.target.invitee;
        if (invitee.case === "userId") {
            return invitee.userId === currentUserId;
        }
        else {
            return invitee.email === currentUserEmail;
        }
    }, [currentUserId, currentUserEmail]);

    const retrieveInvite = useCallback(async () => {
        if (!opaqueInviteCode) {
            return;
        }

        log.info(`Retrieving bond invite code: '${opaqueInviteCode}'`);

        const bondInvite = await dispatch(
            retrieveBondInviteThunk({ opaqueCode: opaqueInviteCode }),
        ).unwrap().catch(e => {
            log.error("Failed to find invite", e);
            throw new Error("Failed to find invite");
        });

        if (!bondInvite) {
            return;
        }

        if (!inviteIsForCurrentUser(bondInvite)) {
            setInviteStatus(InviteStatus.Unauthorized);
            return;
        }

        if (bondInvite.expiry.expired) {
            setInviteStatus(InviteStatus.Expired);
            return;
        }

        setInviteStatus(InviteStatus.ConfirmingJoin);
        setRetrievedBondId(bondInvite.bondId);
    }, [dispatch, inviteIsForCurrentUser, opaqueInviteCode]);

    const redeemAndRedirect = useCallback(async () => {
        if (!opaqueInviteCode) {
            return;
        }

        const bondId = await dispatch(
            redeemBondInviteThunk({ opaqueCode: opaqueInviteCode }),
        ).unwrap().catch(e => {
            console.error("Failed to redeem invite", e);
            throw new Error("Failed to redeem invite");
        });

        if (!bondId) {
            return;
        }
        navigate("/bond/" + d.extractUUID(bondId));
    }, [dispatch, navigate, opaqueInviteCode]);

    // State machine for the invitation flow
    useConnectedEffect(() => {
        switch (inviteStatus) {
            case InviteStatus.RetrievingDetails:
                retrieveInvite().catch(e => {
                    log.error(e);
                    setInviteStatus(InviteStatus.Unknown);
                });
                break;

            case InviteStatus.ConfirmingJoin:
                if (retrievedBondId && alreadyInBond) {
                    log.debug("Redirecting into already-joined bond");
                    navigate("/bond/" + d.extractUUID(retrievedBondId), { replace: true });
                }
                break;

            case InviteStatus.Redeeming:
                redeemAndRedirect().catch(e => {
                    log.error(e);
                    setInviteStatus(InviteStatus.Failed);
                });
                break;

            case InviteStatus.Failed:
                break;

            case InviteStatus.Expired:
                break;

            case InviteStatus.Unauthorized:
                break;

            case InviteStatus.Unknown:
                break;
        }
    }, [
        navigate,
        inviteStatus,
        alreadyInBond,
        retrievedBondId,
        redeemAndRedirect,
        retrieveInvite,
    ]);

    const joinBondCb = useCallback(() => {
        setInviteStatus(InviteStatus.Redeeming);
    }, []);

    // The invitation flow state determines what to render
    const htmlForCurrentState = useMemo((): React.JSX.Element => {
        switch (inviteStatus) {
            case InviteStatus.RetrievingDetails:
                return <>Retrieving bond for invite...</>;

            case InviteStatus.ConfirmingJoin:
                return (
                    <JoinBondConfirmation
                        retrievedBondId={retrievedBondId}
                        joinCb={joinBondCb}
                    />
                );

            case InviteStatus.Redeeming:
                return <>Joining bond...</>;

            case InviteStatus.Failed:
                return <>Failed to redeem bond invite.</>;

            case InviteStatus.Expired:
                return <>This bond invite has expired.</>;

            case InviteStatus.Unauthorized:
                return <>You cannot use this bond invite.</>;

            case InviteStatus.Unknown:
                return <>An error ocurred.</>;
        }
    }, [inviteStatus, retrievedBondId, joinBondCb]);

    return (
        <main className="l-main">
            {htmlForCurrentState}
        </main>
    );
}

function JoinBondConfirmation(
    props: { retrievedBondId?: d.BondId; joinCb: () => void; },
): React.JSX.Element {
    const { retrievedBondId, joinCb } = props;

    const retrievedBond = useSelectorArgs(selectBondById, retrievedBondId);
    const retrievedBondTitle = useSelectorArgs(selectBondTitle, retrievedBondId);

    const cardImageWrapperClasses = classNames("c-bond-invite__image-wrapper");
    const cardImageClasses = classNames("c-bond-invite__image");

    return (
        <div className="c-bond-invite-wrapper">
            <div className="c-bond-invite">
                {(retrievedBond && retrievedBondTitle) ? (
                    <>
                        {retrievedBond.knowledge.imageUrl && (
                            <figure className={cardImageWrapperClasses}>
                                <img
                                    src={retrievedBond.knowledge.imageUrl}
                                    alt=""
                                    className={cardImageClasses}
                                />
                            </figure>
                        )}
                        <div className="c-bond-invite__details">
                            <h2 className="c-bond-invite__title">
                                Join bond "{retrievedBondTitle.title}" with{" "}
                                {retrievedBond.followers.length} members?
                            </h2>
                            <p className="c-bond-invite__summary">
                                "{retrievedBond.knowledge.summary}"
                            </p>
                        </div>
                    </>
                ) : (
                    <div className="c-bond-invite__details">
                        <p className="c-bond-invite__summary">
                            Failed to load details for ({retrievedBondId}).
                        </p>
                    </div>
                )}
                <div className="c-bond-invite__footer">
                    <button
                        className="c-btn c-btn--primary"
                        onClick={joinCb}
                    >
                        Join
                    </button>
                </div>
            </div>
        </div>
    );
}

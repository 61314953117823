import classNames from "classnames";
import * as d from "../../domain/domain";
import { archiveBond, followBond } from "../../features/bonds";
import { useAppDispatch } from "../../store/redux";
import {
    selectPublishedSequenceNumber,
    updateStagedSequenceNumberForTests,
    updateStagedSequenceNumberToLocalMax,
    updateUserReadSequenceNumber,
} from "../../features/channels";
import useSelectorArgs from "../../hooks/useSelectorArgs";

export function BondDismissButton(
    { id, channelId, isArchived, isMobile }: {
        id: d.BondId;
        channelId?: d.ChannelId;
        isArchived: boolean;
        isMobile: boolean;
    },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const onClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        const shouldArchive = !isArchived;

        dispatch(
            archiveBond({
                bondId: id,
                archive: shouldArchive,
                channelId: channelId,
            }),
        );
    };

    const actionName = {
        1: ["Restore bond", "Restore"],
        0: ["Dismiss bond", "Dismiss"],
    }[isArchived ? 1 : 0];

    if (isMobile) {
        const classes = classNames("c-btn-card", {
            "c-btn-dismiss": !isArchived,
            "c-btn-restore": isArchived,
        });
        const divClasses = classNames({
            "c-btn-dismiss__icon": !isArchived,
            "c-btn-restore__icon": isArchived,
        });

        return (
            <button className={classes} onClick={onClick} aria-label={actionName[0]}>
                <div className={divClasses}></div>
                {actionName[1]}
            </button>
        );
    }

    const classes = classNames({
        "c-btn-icon-dismiss": !isArchived,
        "c-btn-icon-restore": isArchived,
    });

    return (
        <button
            className={classes}
            aria-label={actionName[0]}
            title={actionName[0]}
            onClick={onClick}
        >
            {actionName[1]}
        </button>
    );
}

export function BondFollowButton(
    { id, isFollowed, isMobile }: { id: d.BondId; isFollowed: boolean; isMobile: boolean; },
) {
    const dispatch = useAppDispatch();

    const onClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        const shouldFollow = !isFollowed;
        dispatch(followBond({ bondId: id, follow: shouldFollow }));
    };

    const actionName = {
        1: ["Leave bond", "Leave"],
        0: ["Join bond", "Join"],
    }[isFollowed ? 1 : 0];

    if (isMobile) {
        const classes = classNames("c-btn-card", {
            "c-btn-leave": isFollowed,
            "c-btn-join": !isFollowed,
        });
        const divClasses = classNames({
            "c-btn-leave__icon": isFollowed,
            "c-btn-join__icon": !isFollowed,
        });

        return (
            <button className={classes} onClick={onClick} aria-label={actionName[0]}>
                <div className={divClasses}></div>
                {actionName[1]}
            </button>
        );
    }

    const classes = classNames({
        "c-btn-icon-join": !isFollowed,
        "c-btn-icon-leave": isFollowed,
    });

    return (
        <button
            className={classes}
            aria-label={actionName[0]}
            title={actionName[0]}
            onClick={onClick}
        >
            {actionName[1]}
        </button>
    );
}

export function BondReadButton(
    { channelId, isRead, isMobile }: {
        channelId?: d.ChannelId;
        isRead: boolean;
        isMobile: boolean;
    },
) {
    const dispatch = useAppDispatch();
    const curPubSeqNo = useSelectorArgs(selectPublishedSequenceNumber, channelId);

    const onClick: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation();
        if (!channelId) {
            return;
        }

        if (isRead) {
            if (curPubSeqNo !== undefined && curPubSeqNo > 0) {
                // This is a nasty bodge to get mark-as-unread in without any bigger changes to
                // the system. We need to update our local staged sequence number down, then
                // update the cloud, else we'll just tell the cloud it was the original number
                // immediately.
                // Yuck!
                const seqNum = curPubSeqNo - 1;

                dispatch(
                    updateStagedSequenceNumberForTests({
                        channelId,
                        sequenceNumber: seqNum,
                    }),
                );
                dispatch(updateUserReadSequenceNumber({ channelId, seqNum }));
            }
        }
        else {
            dispatch(
                updateStagedSequenceNumberToLocalMax(channelId),
            );
        }
    };

    const actionName = {
        1: ["Mark bond as unread", "Unread"],
        0: ["Mark bond as read", "Read"],
    }[isRead ? 1 : 0];

    if (isMobile) {
        const classes = classNames("c-btn-card", {
            "c-btn-read": !isRead,
            "c-btn-unread": isRead,
        });
        const divClasses = classNames({
            "c-btn-read__icon": !isRead,
            "c-btn-unread__icon": isRead,
        });

        return (
            <button className={classes} onClick={onClick} aria-label={actionName[0]}>
                <div className={divClasses}></div>
                {actionName[1]}
            </button>
        );
    }

    const classes = classNames({
        "c-btn-icon-read": !isRead,
        "c-btn-icon-unread": isRead,
    });

    return (
        <button
            className={classes}
            aria-label={actionName[0]}
            title={actionName[0]}
            onClick={onClick}
        >
            {actionName[1]}
        </button>
    );
}

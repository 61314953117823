import classNames from "classnames";
import { FC, useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useHighlightedIndex } from "../hooks/useHighlightedIndex";
import usePrevious from "../hooks/usePrevious";
import { similaritySearch, similaritySort } from "../misc/similarStrings";
import { indexNestedArray, stringCompare } from "../misc/utils";

/**
 * Adapter used to extract suggestion-list relevant attributes from a generic suggestion data type
 */
export interface SuggestionEntityAdapter<E> {
    /**
     * Return an id which can be used as a react element key
     */
    id: (e: E) => string;
    /**
     * Return a string which can be used to sort the suggestions when no search query is present
     */
    sortKey: (e: E) => string;
    /**
     * Return a string which can be used to search the suggestions via a search query
     */
    searchKey: (e: E) => string;
    /**
     * Return a string which is applied as the title of the suggestion button element
     */
    title?: (e: E) => string;
    /**
     * Return an action which is called when the suggestion is selected or clicked
     */
    action?: (e: E) => () => void;
    /**
     * Return true if a suggestion row should be highlighted, otherwise the default highlight
     * behaviour will be followed
     */
    overrideHighlight?: (e: E) => boolean;
}

export interface SuggestionContentProps<E> {
    entity: E;
    highlighted: boolean;
}

interface SuggestionSubdomain<E> {
    entities: E[];
    key: string;
    adapter: SuggestionEntityAdapter<E>;
    Content?: FC<SuggestionContentProps<E>>;
    classNameModifier?: string;
    filter?: boolean;
}

export type SuggestionDomain<D extends any[]> = {
    [K in keyof D]: SuggestionSubdomain<D[K]>;
};

interface SuggestionsListProps<D extends any[]> {
    /**
     * The complete domain of possible suggestions. This is a list of groups ("subdomains"), each
     * containing suggestions of a particular type, along with some subdomain-specific metadata
     */
    domain: SuggestionDomain<D>;
    /**
     * The current search query which is used to filter the suggestions using `similaritySearch`
     */
    query: string;

    /**
     * Whether arrow key control should currently be enabled for the list
     */
    arrowKeysActive: boolean;
    /**
     * When arrow key control is active, whether tab/shift-tab should also be an accepted input
     */
    allowTab?: boolean;
    /**
     * If true, the list begins with no element highlighted, and will return to this state when
     * `arrowKeysActive` is unset. Otherwise, the first suggestion begins highlighted
     */
    useUnselectedState?: boolean;
    /**
     * Additional general action that is called whenever a suggestion is selected using enter.
     * This may be used (for example) to clear the search query
     */
    enterAction?: () => void;
    /**
     * Additional general action that is called whenever a suggestion is selected using the mouse
     */
    clickAction?: () => void;

    /**
     * Similarity threshold used for `similaritySearch` and `similaritySort`
     */
    similarityThreshold?: number;

    /**
     * Class applied to each suggestion group in the list
     */
    groupClassName?: string;
    /**
     * Class applied to each suggestion in the list. This class is also used as a base for the
     * `--highlighted` and suggestion-specific (if present) modifiers
     */
    suggestionClassName: string;

    /**
     * Callback which is run whenever the filtered list of suggestions changes
     */
    onSuggestionsChange?: (suggestionGroups: SuggestionDomain<any[]>) => void;
}

/**
 * Render a grouped list of suggestions which may be highlighted using the arrow keys, selected
 * using click or enter, and filtered using a query. The component provides options for styling
 * and disabling features (such as arrow key selection)
 */
export const SuggestionsList = <D extends any[]>({
    domain,
    query,
    arrowKeysActive,
    allowTab,
    useUnselectedState,
    enterAction,
    clickAction,
    similarityThreshold,
    groupClassName,
    suggestionClassName,
    onSuggestionsChange,
}: SuggestionsListProps<D>): React.JSX.Element => {
    similarityThreshold ??= -100000;

    const suggest = useCallback(
        <E,>(entities: E[], adapter: SuggestionEntityAdapter<E>): E[] => {
            if (!query) {
                return entities
                    .sort((a, b) => stringCompare(adapter.sortKey(a), adapter.sortKey(b)));
            }

            return entities
                .filter(e => similaritySearch(query, adapter.searchKey(e), similarityThreshold))
                .sort((a, b) => similaritySort(query, adapter.searchKey(a), adapter.searchKey(b)));
        },
        [query, similarityThreshold],
    );

    const groups = useMemo(
        () =>
            domain.map(subdomain => ({
                ...subdomain,
                entities: (subdomain.filter ?? true) ?
                    suggest(subdomain.entities, subdomain.adapter) :
                    subdomain.entities,
            }))
                .filter(({ entities }) => entities.length > 0),
        [domain, suggest],
    );

    const indexedGroups = useMemo(
        () => indexNestedArray(groups.map(({ entities }) => entities)),
        [groups],
    );

    // Run the suggestion change callback
    const prevGroups = usePrevious(groups);
    useLayoutEffect(() => {
        if (prevGroups != groups) {
            onSuggestionsChange?.(groups);
        }
    }, [prevGroups, groups, onSuggestionsChange]);

    const {
        highlightedIndex,
        offsetHighlightedIndex,
        scrollRefs,
    } = useHighlightedIndex<HTMLButtonElement>(
        arrowKeysActive,
        groups.flatMap(({ entities, adapter }) => entities.map(e => adapter.action?.(e))),
        { allowTab, useUnselectedState, extraAction: enterAction },
    );

    // Force an element to highlight when the query is first filled in
    const prevQuery = usePrevious(query);
    useEffect(() => {
        if (prevQuery == "" && query != "") {
            offsetHighlightedIndex(0);
        }
    }, [prevQuery, query, offsetHighlightedIndex]);

    const suggestions = useMemo(
        () =>
            indexedGroups
                .map((indexedEntities, i) => {
                    const {
                        key,
                        adapter,
                        Content,
                        classNameModifier,
                    } = groups[i];

                    return (
                        <div key={key} className={groupClassName ?? ""}>
                            {indexedEntities.map(([j, e]) => (
                                <button
                                    ref={scrollRefs.current?.[j]}
                                    key={adapter.id(e)}
                                    className={classNames(
                                        suggestionClassName,
                                        {
                                            [`${suggestionClassName}--highlighted`]:
                                                highlightedIndex == j ||
                                                adapter.overrideHighlight?.(e),
                                            [`${suggestionClassName}--${classNameModifier}`]:
                                                classNameModifier !== undefined,
                                        },
                                    )}
                                    title={adapter.title?.(e)}
                                    onClick={() => {
                                        adapter.action?.(e)();
                                        clickAction?.();
                                    }}
                                >
                                    {Content && (
                                        <Content entity={e} highlighted={highlightedIndex == j} />
                                    )}
                                </button>
                            ))}
                        </div>
                    );
                }),
        [
            indexedGroups,
            groups,
            groupClassName,
            scrollRefs,
            suggestionClassName,
            highlightedIndex,
            clickAction,
        ],
    );
    return <>{suggestions}</>;
};

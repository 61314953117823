import * as d from "../domain/domain";
import { backoffManagerCreator } from "../ds/backoff";
import { filterRecordByEntry } from "../misc/primatives";
import { TypedEntries } from "../misc/types";
import {
    type AppAppendListener,
    appAppendListener,
    ListenerAPI,
    statesDifferBySelectors,
} from "../store/middleware";
import { createAppSelector } from "../store/redux";
import { RootState } from "../store/types";
import { selectSuggestedBonds } from "./bondCreation";
import {
    observeCurrentUserBondPreviews,
    selectBondEntities,
    selectBondIds,
    selectBonds,
} from "./bonds";
import {
    catchupMessages,
    selectAllChannelMaps,
    selectChannelInfosForSeqNumUpdate,
    selectFirstUnsentMessages,
    subChannelMsgSeqNumsThunk,
    updateUserReadSequenceNumber,
} from "./channels";
import { sendMessageThunk } from "./chats";
import { selectVisibleChannelInterest, startManagingInterest } from "./interest";
import { multiRpcStreamManager, StartConnectedStatefulListenerArgs } from "./middleware";
import { memoizeOptions } from "./selectors";
import { selectCurrentSquadIds } from "./squads";

// Avoid circular dependency between bonds and bondCreation slices.
export const selectSuggestedChannelIds = createAppSelector(
    [selectSuggestedBonds, selectBondEntities],
    (suggestedBonds, bonds): Partial<Record<d.BondId, d.ChannelId>> => {
        const entries = suggestedBonds
            .filter(sb => bonds[sb.bondId]?.channelId)
            .map(sb => [sb.bondId, bonds[sb.bondId]!.channelId]);
        return Object.fromEntries(entries);
    },
    memoizeOptions.weakMapShallow,
);

const selectChannelsWithUnfetchedMessages = createAppSelector(
    [
        selectBonds,
        selectAllChannelMaps,
        selectVisibleChannelInterest,
    ],
    (orderedBonds, cm, visibleChannelIds) =>
        orderedBonds
            .filter(
                ({ channelId, maxSequenceNumber }) => {
                    const local = cm[channelId]?.localSequenceNumber;
                    return !visibleChannelIds.includes(channelId) && maxSequenceNumber !== 0 &&
                        (local === undefined || local < maxSequenceNumber);
                },
            ).map(bo => bo.channelId),
);

export const startChannelMsgSeqNoStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(
    selectBondIds,
    subChannelMsgSeqNumsThunk,
    "bondMsgSeqNos",
    appendListener,
);

export const bondPreviewStreamingManager = multiRpcStreamManager(
    selectCurrentSquadIds,
    observeCurrentUserBondPreviews,
    "bondPreviewStreamManager",
);

export const messageFetcher: StartConnectedStatefulListenerArgs = [
    () => {
        const backoff = backoffManagerCreator();

        return async (api: ListenerAPI, _firstState: RootState) => {
            while (!api.signal.aborted) {
                const state = api.getState();
                const potentials = selectChannelsWithUnfetchedMessages(state);

                if (potentials.length === 0) break;

                const cb = backoff.begin();

                const channelId = potentials[0];

                try {
                    await api.dispatch(catchupMessages({ channelId })).unwrap();
                    cb(true);
                }
                catch (e) {
                    cb(false);

                    const delay = backoff.getDelay();

                    await new Promise(r => setTimeout(r, delay));
                }
            }
        };
    },
    selectChannelsWithUnfetchedMessages,
    "messageFetcher",
];

export const messageReadUpdater: StartConnectedStatefulListenerArgs = [
    () => {
        const backoff = backoffManagerCreator();
        const outstanding = new Map<d.ChannelId, number>();

        return async (api: ListenerAPI, _firstState: RootState) => {
            let i = 0;
            while (!api.signal.aborted && i++ < 10) {
                const state = api.getState();
                const discrepant = selectChannelInfosForSeqNumUpdate(state);

                const updated = [...outstanding.entries()].filter(([cid, sn]) => {
                    const d = discrepant[cid];
                    return d === undefined || sn < d;
                });
                updated.forEach(([cid, _]) => outstanding.delete(cid));

                const potential = filterRecordByEntry(discrepant, (cid, sn) => {
                    const o = outstanding.get(cid);
                    return o === undefined || sn! < o;
                });

                if (Object.keys(potential).length === 0) break;

                const cb = backoff.begin();

                const [channelId, seqNum] = TypedEntries(potential)[0];

                try {
                    await api.dispatch(updateUserReadSequenceNumber({ channelId, seqNum: seqNum! }))
                        .unwrap();
                    outstanding.set(channelId, seqNum!);
                    cb(true);
                }
                catch (e) {
                    cb(false);

                    const delay = backoff.getDelay();

                    await new Promise(r => setTimeout(r, delay));
                }
            }
        };
    },
    selectChannelInfosForSeqNumUpdate,
    "messageReadUpdater",
];

export const messageSender: StartConnectedStatefulListenerArgs = [
    () => {
        const backoff = backoffManagerCreator();
        const stateChanged = statesDifferBySelectors(selectFirstUnsentMessages);

        return async (api: ListenerAPI, _firstState: RootState) => {
            while (!api.signal.aborted) {
                const state = api.getState();
                const msgs = selectFirstUnsentMessages(state);

                if (msgs.length === 0) break;

                const cb = backoff.begin();

                const msg = msgs[0];
                const now = Date.now();
                const nextAttempt = msg.backoffState?.nextAttempt ?? now;

                if (nextAttempt <= now) {
                    try {
                        await api.dispatch(sendMessageThunk(msg)).unwrap();
                        cb(true);
                    }
                    catch (e) {
                        cb(false);

                        const delay = backoff.getDelay();

                        await new Promise(r => setTimeout(r, delay));
                    }
                }
                else {
                    await api.take(stateChanged, nextAttempt - now);
                }
            }
        };
    },
    selectFirstUnsentMessages,
    "messageSender",
];

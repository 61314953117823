import BondsListView from "./BondsListView";
import { useEffect, useMemo } from "react";
import { useNavigateBack } from "../hooks/useNavigateBack";
import { selectSquadById } from "../features/squads";
import useAddressParams from "../hooks/useAddressParams";
import useSelectorArgs from "../hooks/useSelectorArgs";

export function SquadView(): React.JSX.Element {
    const { navigate } = useNavigateBack();
    const { squadId } = useAddressParams();
    const squad = useSelectorArgs(selectSquadById, squadId);

    const shouldRedirect = useMemo(() => !squadId || !squad, [squadId, squad]);

    useEffect(() => {
        if (shouldRedirect) {
            const t = setTimeout(() => {
                navigate("/bond");
            }, 1 * 1000);

            return () => {
                clearTimeout(t);
            };
        }
    }, [navigate, shouldRedirect]);

    if (shouldRedirect) {
        return <h1>No squad found. Redirecting...</h1>;
    }

    return <BondsListView />;
}

import React from "react";
import classNames from "classnames";
import { isMobileBrowser } from "../../misc/mobile";

export const LightboxTitle: React.FC<{
    filename: string;
    inlinableIndex: number;
    inlinableAttachmentsLength: number;
}> = ({ filename, inlinableIndex, inlinableAttachmentsLength }) => {
    const indexClasses = classNames("c-lightbox__title-index", {
        "c-lightbox__title-index--desktop": !isMobileBrowser(),
    });

    if (inlinableAttachmentsLength === 1) {
        return <span data-testid="lightbox-title">{filename}</span>;
    }

    return (
        <>
            <span data-testid="lightbox-title" className="u-truncate">{filename}</span>
            <span className={indexClasses}>
                {`${inlinableIndex + 1} of ${inlinableAttachmentsLength}`}
            </span>
        </>
    );
};

import * as d from "../domain/domain";
import { IntelService } from "../../gen/proto/intel/intel_connect";
import { transport } from "./transport";
import { createPromiseClient } from "@connectrpc/connect";
import { SuggestBondTitleRequest } from "../../gen/proto/intel/intel_pb";
import { pbUserId } from "./util";
import log from "../misc/log";

export const service = createPromiseClient(IntelService, transport);
export default service;

export interface getBondTitleSuggestionArgs {
    userId: d.UserId;
    bondContentDraft: string;
}

export async function getBondTitleSuggestion(
    args: getBondTitleSuggestionArgs,
): Promise<string> {
    const req = new SuggestBondTitleRequest({
        userId: pbUserId(args.userId),
        bondContentDraft: args.bondContentDraft,
    });

    const resp = await service.suggestBondTitle(req).catch(e => {
        log.error(e);
        return Promise.reject(e);
    });

    return resp.bondTitleSuggestion ?? "";
}

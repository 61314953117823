import { DependencyList, EffectCallback } from "react";
import { useSelectorEffect } from "./useSelectorEffect";
import { selectConnectedWithUserId, selectNotConnectedWithUserId } from "../features/connection";

/** @function Fire an effect only when: The client is connected to our backend
 * and the user is authenticated.
 */
export function useConnectedEffect(effect: EffectCallback, deps: DependencyList) {
    useSelectorEffect({ effect, deps, selector: selectConnectedWithUserId });
}

/** @function Fire an effect only when: The client is not connected to our backend
 * or the user is not authenticated.
 */
export function useDisconnectedEffect(effect: EffectCallback, deps: DependencyList) {
    useSelectorEffect({ effect, deps, selector: selectNotConnectedWithUserId });
}

// ~polyfill for Promise.withResolvers(), which was only introduced recently and is not supported everywhere.
// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers
export function promiseWithResolvers<T>() {
    let resolver: ((value: T) => void) | undefined = undefined;
    let rejecter: ((value: any) => void) | undefined = undefined;
    const promise = new Promise<T>((resolve, reject) => {
        resolver = resolve;
        rejecter = reject;
    });
    return {
        promise: promise,
        resolve: resolver!,
        reject: rejecter!,
    };
}

export type PromiseWithResolvers<T> = ReturnType<typeof promiseWithResolvers<T>>;

export function delay(ms: number): Promise<void> {
    if (ms < 0) return Promise.resolve();

    return new Promise(r => setTimeout(r, ms));
}

import {
    BackoffProps,
    BackoffState,
    backoffUpdater,
    defaultBackoffProps,
    defaultBackoffState,
} from "../domain/backoff";

export type BackoffResultCallback = (success: boolean) => void;

export interface BackoffManager {
    /** Is the thing-with-backoff currently allowed to attempt? */
    permitted: () => boolean;
    /** Signal that the thing-with-backoff is starting an attempt now.
     */
    begin: () => BackoffResultCallback;
    /** Get the number of milliseconds until the next attempt could be made.
     *
     * For almost any imaginable consumer, this will be less than (2^31 - 1),
     * and hence a `setTimeout(..., delay)` will suffice to cause an update
     * without overflowing the signed integer used in the implementation.
     */
    getDelay: () => number;
}

export const backoffManagerCreator = (
    props: BackoffProps = defaultBackoffProps,
): BackoffManager => {
    const failureUpdater = backoffUpdater(props);

    let state: BackoffState = defaultBackoffState();

    const permitted = () => (state.nextAttempt ?? 0) <= Date.now();

    const makeBegin = () => {
        let fired: boolean = false;

        return (success: boolean) => {
            if (!permitted()) return;

            if (fired) return;
            fired = true;

            if (success) {
                state = defaultBackoffState();
            }
            else {
                state = failureUpdater(state.attempts);
            }

            latestBegin = makeBegin();
        };
    };

    let latestBegin = makeBegin();

    return {
        begin: () => latestBegin,
        permitted,
        getDelay: () => permitted() ? 0 : state.nextAttempt! - Date.now(),
    };
};

import classNames from "classnames";
import React, { useCallback, useState } from "react";

import OfflineIndicator from "../components/OfflineIndicator";
import { OrgOverview } from "../domain/squads";
import { UserDefinition } from "../domain/users";
import {
    selectAlternativeLogins,
    selectCurrentOrgId,
    selectCurrentUserId,
    setLocalPreferredUserId,
} from "../features/auth";
import { selectOrg } from "../features/squads";
import { selectCurrentUser } from "../features/users";
import { useInterestedOrgs } from "../hooks/interest/useInterest";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { Optional } from "../misc/types";
import { useAppSelector } from "../store/redux";

function orgName(org: Optional<OrgOverview>): string {
    return org?.personal ? "My Account" : org?.name || "Unknown Org";
}

function OrgSelectorItem(
    { user }: { user: UserDefinition; },
): React.JSX.Element {
    const currentUserId = useAppSelector(selectCurrentUserId);
    const selected = user.userId === currentUserId;
    const classes = classNames("c-org-selection__item", {
        "c-org-selection__item--selected": selected,
    });
    const org = useSelectorArgs(selectOrg, user.orgId);

    const chooseUser = useCallback(async (userDef: UserDefinition) => {
        if (userDef.userId === currentUserId) {
            return;
        }

        setLocalPreferredUserId(userDef.userId);
        // As we are changing the preferred user ID, refresh the page to reload
        // the IndexedDB and preserve websocket connection accuracy.
        window.location.reload();
    }, [currentUserId]);

    return (
        <button className={classes} onClick={() => chooseUser(user)}>
            {orgName(org)}
        </button>
    );
}

export function UserInfoAndOrgSelector({ location }: { location?: "isAside"; }): React.JSX.Element {
    const user = useAppSelector(selectCurrentUser);
    const orgId = useAppSelector(selectCurrentOrgId);
    const org = useSelectorArgs(selectOrg, orgId);
    const alternativeLoginUsers = useAppSelector(selectAlternativeLogins);

    const [orgSelectorShowing, setOrgSelectorShowing] = useState(false);
    const classes = classNames("c-aside__human", "c-aside-human", {
        "c-aside-human--aside": location === "isAside",
    });
    const orgSelectorClasses = classNames("c-org-selection", {
        "is-visible": orgSelectorShowing,
    });

    const interestedOrgs = [
        orgId,
        ...(orgSelectorShowing ? alternativeLoginUsers.map(u => u.orgId) : []),
    ].filter(x => !!x);
    useInterestedOrgs(interestedOrgs);

    return (
        <div className={classes}>
            <OfflineIndicator />
            <button
                className="c-aside-human__name c-org-selector"
                title="Show orgs"
                onClick={() => setOrgSelectorShowing(x => !x)}
            >
                {user?.name}
                <div className="c-org-selector__icon"></div>
            </button>

            <div className={orgSelectorClasses}>
                {alternativeLoginUsers.map(user => (
                    <OrgSelectorItem
                        key={user.orgId}
                        user={user}
                    />
                ))}
            </div>

            <div className="c-aside-human__meta">
                {orgName(org)} <span className="c-middot">&#183;</span> {user?.nickname}
            </div>
        </div>
    );
}

import useResizeObserver from "@react-hook/resize-observer";
import { ChangeEventHandler, FC, useCallback, useRef, useState } from "react";
import {
    selectBondUserSpecifiedTitle,
    updateUserSpecifiedTitle,
    selectBondTitleSuggestionWithEmojiSplit,
} from "../features/bondCreation";
import { useAppDispatch, useAppSelector } from "../store/redux";
import AutoExpandInput from "./gui/AutoExpandInput";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";

interface NewBondTitleBarProps {
    backAction: () => void;
    tabIndex?: number;
}

export const NewBondTitleBar: FC<NewBondTitleBarProps> = ({ backAction, tabIndex }) => {
    const dispatch = useAppDispatch();

    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");
    const userSpecifiedTitle = useAppSelector(selectBondUserSpecifiedTitle);
    const bondTitleSuggestion = useAppSelector(selectBondTitleSuggestionWithEmojiSplit);

    const barRef = useRef<HTMLDivElement>(null);

    const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
        const title = e.currentTarget.value;
        dispatch(updateUserSpecifiedTitle(title));
    }, [dispatch]);

    // Limit the max width of the title input to the title bar width less a fixed margin
    const [maxWidth, setMaxWidth] = useState("unset");
    useResizeObserver(
        barRef,
        e => {
            // In a horizontal writing mode, `inlineSize` is equivalent to width
            const width = e.contentBoxSize?.[0].inlineSize;
            if (width == undefined) {
                setMaxWidth("unset");
            }
            setMaxWidth(`${width - 110}px`);
        },
    );

    const titleEmoji = showEmoji && bondTitleSuggestion.emoji ?
        bondTitleSuggestion.emoji + " " : "";
    const hasDefaultPlaceholder = titleEmoji + bondTitleSuggestion.title === "";
    const placeholder = titleEmoji + bondTitleSuggestion.title || "New bond";
    return (
        <div ref={barRef} className="c-new-bond-title-bar">
            <button
                className="c-btn-return c-new-bond-title-bar__btn-back"
                onClick={backAction}
                title="Return"
            >
                Return
            </button>
            <AutoExpandInput
                style={{ maxWidth }}
                onChange={onInputChange}
                placeholder={placeholder}
                value={userSpecifiedTitle}
                tabIndex={tabIndex}
                hasDefaultPlaceholder={hasDefaultPlaceholder}
            />
        </div>
    );
};

export default NewBondTitleBar;

import { useCallback, useEffect } from "react";

import { type PromiseWithResolvers, promiseWithResolvers } from "../misc/promises";
import createSharedStateHook, { type SharedStateSetter } from "./useSharedState";
import log from "../misc/log";
import usePrevious from "./usePrevious";
import useOnComponentUnmount from "./useOnComponentUnmount";

interface LockState {
    isLocked: boolean;
    refCount: number;
}

export default function createSharedWebLock(
    lockName: string,
): [(predicate: boolean) => boolean, () => boolean] {
    const useSharedLockState = createSharedStateHook<LockState>({
        isLocked: false,
        refCount: 0,
    });

    let lockPWR: PromiseWithResolvers<void> | null = null;
    let lockRequest: AbortController | null = null;

    const acquireLock = (setSharedState: SharedStateSetter<LockState>) => {
        if (lockRequest) return; // Lock acquisition already in progress

        lockRequest = new AbortController();
        lockPWR = promiseWithResolvers();

        navigator.locks.request(
            lockName,
            { signal: lockRequest.signal },
            async lock => {
                if (lock) {
                    if (!lockPWR) {
                        log.warn(
                            `Acquired lock ${lock.name} but prepared promise no longer available`,
                        );
                        return;
                    }

                    log.info(`Obtained lock ${lock.name}`);
                    setSharedState(prev => ({ ...prev, isLocked: true }));

                    return lockPWR.promise;
                }
                else {
                    log.info(`Failed to obtain lock ${lockName}`);
                }
            },
        ).catch(() => {});
    };

    const releaseLock = () => {
        if (lockRequest) {
            lockRequest.abort("release");
            lockRequest = null;
        }
        if (lockPWR) {
            Promise.race([lockPWR.promise, true]).then(v => {
                v && log.info(`Releasing lock ${lockName}`);
            });
            lockPWR.resolve();
            lockPWR = null;
        }
    };

    function useTakeLock(predicate: boolean) {
        const [lockState, setSharedLockState] = useSharedLockState();
        const previousPredicate = usePrevious(predicate);

        const increment = useCallback(() => {
            let acquire = false;

            setSharedLockState(prev => {
                if (prev.refCount === 0) acquire = true;

                return { ...prev, refCount: prev.refCount + 1 };
            });

            if (acquire) acquireLock(setSharedLockState);
        }, [setSharedLockState]);

        const decrement = useCallback(() => {
            let release = false;

            setSharedLockState(prev => {
                const refCount = prev.refCount - 1;

                if (refCount === 0) release = true;

                return { refCount, isLocked: prev.isLocked && refCount > 0 };
            });

            if (release) releaseLock();
        }, [setSharedLockState]);

        useEffect(() => {
            if (previousPredicate === predicate) return;

            if (previousPredicate === undefined) {
                // Initial mount.
                if (predicate) increment();
            }
            else if (predicate) increment();
            else decrement();
        }, [previousPredicate, predicate, increment, decrement]);

        useOnComponentUnmount(() => {
            predicate && decrement();
        });

        return lockState.isLocked;
    }

    function useInspectLock() {
        const [lockState] = useSharedLockState();
        return lockState.isLocked;
    }

    return [useTakeLock, useInspectLock];
}

export const [
    useTakeSingleRtcSessionLock,
    useInspectSingleRtcSessionLock,
] = createSharedWebLock("beyond-single-call");

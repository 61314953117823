import { useCallback, useEffect, useRef } from "react";
import { Focusable } from "../misc/types";
import useSelectorArgs from "./useSelectorArgs";
import { MetaInterestCounterKey, selectInterestInKey } from "../features/interest";

// isFocusable returns true if the element provided is one that can be focused.
const isFocusable = (elem: Element | null): boolean =>
    elem instanceof HTMLInputElement ||
    elem instanceof HTMLSelectElement ||
    elem instanceof HTMLTextAreaElement ||
    elem instanceof HTMLAnchorElement ||
    elem instanceof HTMLDivElement && elem.isContentEditable;

export default function useKeypressFocus(refToFocus: React.RefObject<Focusable>) {
    // Track composer target element focus.
    const focusedRef = useRef(false);

    // Do not refocus if there is something blocking hotkeys in the dom.
    const hotkeyBlockingInterest = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockHotkey,
    );

    // Listen for keypresses and redirect them to the target element.
    useEffect(() => {
        const check = (e: KeyboardEvent) => {
            // IME event is `e.isComposing || e.keyCode === 229`
            // See https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event
            // So ignore non-IME and non-single-character inputs.
            if (!(e.isComposing || e.keyCode === 229) && e.key.length != 1) return;

            // Ignore keyboard shortcuts.
            if (e.metaKey || e.ctrlKey) return;

            // Focus the target if there is nothing else focused.
            if (
                !isFocusable(document.activeElement) && !focusedRef.current &&
                !hotkeyBlockingInterest
            ) {
                refToFocus.current?.focus();
            }
        };

        document.addEventListener("keydown", check);
        return () => document.removeEventListener("keydown", check);
    }, [hotkeyBlockingInterest, refToFocus]);

    return useCallback((focused: boolean) => {
        focusedRef.current = focused;
    }, []);
}

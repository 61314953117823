import { nanoid } from "@reduxjs/toolkit";
import { contentToMarkupDelta } from "../misc/messageContent";
import { Optional } from "../misc/types";
import {
    ChangeDelta,
    CompactMarkupDelta,
    EmitterSource,
    EmitterSources,
    MarkupDelta,
    MentionOp,
    PlainChangeDelta,
    PlainMarkupDelta,
    annotateMarkupDeltaOps,
    changeDeltaBuilder,
    compactMarkupDelta,
    composeDeltas,
    getMentions,
    isMentionOp,
    markupDeltaBuilder,
    parseMarkupDelta,
    plainifyChangeDelta,
    renderDeltaToTextForDisplay,
    replaceChange,
} from "./delta";
import * as d from "./domain";
import { ContentMention, Mention } from "./mentions";

/**
 * @deprecated
 */
export interface DraftChatContent_V1 {
    /**
     * @deprecated
     */
    ver: 1 | undefined;

    /**
     * @deprecated
     */
    draftId: d.UnsentMessageId;

    /**
     * The current draft message text.
     * @deprecated
     */
    draftMessage: string;

    /**
     * Always set to be draftMessage.trim(). This is used to contain the
     * version of the message that is used to decide if the message
     * is sendable and is the version sent.
     * @deprecated
     */
    trimmedMessage?: string;

    /**
     * List of non-overlapping character ranges representing mentions
     * in the text, ordered by start offset
     * @deprecated
     */
    mentions?: ContentMention[];

    /**
     * Hint for the message composer to update the caret position (e.g. after
     * a text modification), with a unique id to enable idempotence
     * @deprecated
     */
    caretHint: { location?: number; id: string; };

    /**
     * Track the length of the message prefix and any (usually whitespace)
     * padding between the prefix and the body. The prefix is brittle: it will
     * immediately become part of the message body if it is modified by the
     * user. The padding will shrink as it is modified but will not convert the
     * rest of the prefix.
     */
    prefix?: {
        length: number;
        padding: number;
    };
}

export interface DraftChatContent_V2 {
    ver: 2;

    draftId: d.UnsentMessageId;

    /**
     * Quill Delta markup describing the message with formatting and embeds.
     */
    draftMarkup: CompactMarkupDelta;

    /**
     * Plaintext version of the message, parsed from the message markup.
     * This can be used for prompting AI models, but should not be
     * displayed to the user unless the message is plaintext-only.
     */
    draftMessage: string;

    /**
     * Track the last change to the draft markup that was made directly though
     * Redux, ignoring changes that came from the message composer. This is used
     * to relay the change back to the message composer efficiently---without it
     * we would have to replace the entire contents of the composer with the new
     * version whenever a change were detected.
     */
    lastApiChange: Optional<PlainChangeDelta>;
}

/**
 * An EnrichedChatMessageContent extended with information derived from it.
 *
 * It is used to prepare messages for sending and deciding their sendability.
 */
export type DraftChatContent =
    | DraftChatContent_V1
    | DraftChatContent_V2;

/**
 * Generate a unique hint id
 * @deprecated
 */
export const genCaretHintId = () => nanoid(16);

/**
 * Generate a unique local message id
 */
export const genLocalMessageId = (): d.UnsentMessageId => nanoid();

/**
 * @deprecated
 */
export const isDraftContentV1 = (
    content: Optional<DraftChatContent>,
): content is DraftChatContent_V1 => !!content && (content.ver ?? 1) == 1;

export const isDraftContentV2 = (
    content: Optional<DraftChatContent>,
): content is DraftChatContent_V2 => !!content && content.ver == 2;

/**
 * Create a new blank version 1 draft
 * @deprecated
 */
export const createBlankDraftContentV1 = (): DraftChatContent_V1 => {
    return {
        ver: 1,
        draftId: genLocalMessageId(),
        draftMessage: "",
        mentions: [],
        trimmedMessage: "",
        caretHint: { id: genCaretHintId() },
    };
};

/**
 * Create a new blank version 2 draft
 */
export const createBlankDraftContentV2 = (
    makeClearChange: boolean = false,
): DraftChatContent_V2 => {
    const lastApiChange = makeClearChange ?
        changeDeltaBuilder()
            .delete(Infinity)
            .buildPlain()
        : undefined;
    return {
        ver: 2,
        draftId: genLocalMessageId(),
        draftMarkup: markupDeltaBuilder().buildCompact(),
        draftMessage: "",
        lastApiChange,
    };
};

/**
 * Convert a draft content instance to the version 1 format. If it is already
 * in that format return it unmodified
 * @deprecated
 */
export const convertDraftContentToV1 = (
    content: DraftChatContent,
): DraftChatContent_V1 => {
    const contentVer = content.ver ?? 1;

    if (contentVer == 1) {
        return content as DraftChatContent_V1;
    }

    const draftMessage = getDraftMessageText(content) ?? "";
    return {
        ver: 1,
        draftId: content.draftId,
        draftMessage,
        mentions: getDraftContentMentions(content),
        trimmedMessage: draftMessage.trim(),
        caretHint: { id: genCaretHintId() },
    };
};

/**
 * Convert a draft content instance to the version 2 format. If it is already
 * in that format return it unmodified
 */
export const convertDraftContentToV2 = (
    content: DraftChatContent,
): DraftChatContent_V2 => {
    const contentVer = content.ver ?? 1;

    if (contentVer == 2) {
        return content as DraftChatContent_V2;
    }

    const draftMessage = getDraftMessageText(content) ?? "";
    return {
        ver: 2,
        draftId: content.draftId,
        draftMarkup: markupDeltaBuilder().insert(draftMessage).buildCompact(),
        draftMessage: draftMessage,
        lastApiChange: undefined,
    };
};

/**
 * Return the string message text from a draft message content instance
 */
export const getDraftMessageText = (content: Optional<DraftChatContent>) => content?.draftMessage;

/**
 * Perform a string slice on the message text from a message content instance
 * @deprecated
 */
const sliceDraftMessageText = (
    content: Optional<DraftChatContent>,
    start?: number,
    end?: number,
) => getDraftMessageText(content)?.slice(start, end);

/**
 * Return the body text (full text less prefix) from a message content instance
 */
export const getDraftBodyText = (content: Optional<DraftChatContent>) =>
    switchDraftContentVersion<Optional<string>>(
        content,
        c1 => sliceDraftMessageText(c1, c1?.prefix && (c1.prefix.length + c1.prefix.padding)),
        c2 => getDraftMessageText(c2),
        () => undefined,
    );

/**
 * Return true if the whitespace-trimmed body text of a draft message content is empty
 */
export const isDraftBodyEmpty = (content: Optional<DraftChatContent>) =>
    (getDraftBodyText(content)?.trim().length ?? 0) == 0;

/**
 * Return the `MarkupDelta` message markup from a draft message content instance. If the message
 * version does not store markup, generate markup from the text and mentions list
 */
export const getDraftMessageMarkup = (content: Optional<DraftChatContent>) =>
    switchDraftContentVersion<Optional<MarkupDelta>>(
        content,
        c1 =>
            contentToMarkupDelta(
                getDraftMessageText(c1)!,
                getDraftContentMentions(c1) ?? [],
            ),
        c2 => parseMarkupDelta(c2.draftMarkup),
        () => undefined,
    );

/**
 * Return a list of content mentions from a draft content instance
 * @deprecated
 */
export const getDraftContentMentions = (
    content: Optional<DraftChatContent>,
): Optional<ContentMention[]> =>
    switchDraftContentVersion(
        content,
        c1 => c1.mentions,
        // This case is useful for keeping your drafts when the rich text composer feature flag is turned off
        c2 =>
            annotateMarkupDeltaOps(getDraftMessageMarkup(c2))
                ?.windows(2)
                .filter((v): v is [[MentionOp, number], [any, number]] => isMentionOp(v[0][0]))
                .map(([[op, startOffset], [_, endOffset]]) => ({
                    ...op.insert.mention,
                    startOffset,
                    endOffset,
                })),
        () => undefined,
    );

/**
 * Return a list of mentions from a draft content instance
 */
export const getDraftMentions = (content: Optional<DraftChatContent>): Mention[] =>
    switchDraftContentVersion<Optional<Mention[]>>(
        content,
        c1 => c1.mentions,
        c2 => getMentions(getDraftMessageMarkup(c2)),
        () => undefined,
    ) ?? [];

/**
 * Get the count of mentions present in the draft content
 */
export const getDraftMentionsCount = (
    content: Optional<DraftChatContent>,
): number =>
    switchDraftContentVersion<number>(
        content,
        c1 => c1.mentions?.length ?? 0,
        c2 => c2.draftMarkup.filter(isMentionOp).length,
        () => 0,
    );

/**
 * Get the length in characters of the whitespace-trimmed message from the draft
 */
export const getDraftTrimmedLength = (
    content: Optional<DraftChatContent>,
): number =>
    switchDraftContentVersion<number>(
        content,
        c1 => c1.trimmedMessage?.length ?? 0,
        c2 => c2.draftMessage?.trim().length ?? 0,
        () => 0,
    );

/**
 * Return the last api change that was applied to a draft, or undefined if the last change
 * was not an api change, or if the draft is not version 2. An api change is a change made
 * directly to the store, rather than via a Quill editor
 */
export const getDraftLastApiChange = (
    content: Optional<DraftChatContent>,
): Optional<PlainChangeDelta> =>
    switchDraftContentVersion<Optional<PlainChangeDelta>>(
        content,
        _ => undefined,
        c2 => c2.lastApiChange,
        () => undefined,
    );

/**
 * Return the prefix section (including padding) from the draft message text in a
 * draft message content instance
 * @deprecated
 */
export const getDraftPrefixWithPadding = (content: Optional<DraftChatContent>): string =>
    switchDraftContentVersion<Optional<string>>(
        content,
        c1 =>
            sliceDraftMessageText(
                c1,
                0,
                (c1?.prefix && (c1.prefix.length + c1.prefix.padding)) ?? 0,
            ),
        _ => undefined,
        () => undefined,
    ) ?? "";

/**
 * Return a list of content mentions from the prefix section of a message content instance
 * @deprecated
 */
export const getDraftPrefixContentMentions = (
    content: Optional<DraftChatContent>,
): ContentMention[] =>
    switchDraftContentVersion<Optional<ContentMention[]>>(
        content,
        c1 =>
            c1?.prefix &&
            getDraftContentMentions(c1)
                ?.filter(m => m.endOffset <= c1.prefix!.length),
        _ => undefined,
        () => undefined,
    ) ?? [];

/**
 * Return a new draft equivalent to an existing draft but with the provided markup
 */
export const withMarkup = (
    content: DraftChatContent_V2,
    markup: MarkupDelta | PlainMarkupDelta,
    source: EmitterSource,
): DraftChatContent_V2 => ({
    ...content,
    draftMarkup: compactMarkupDelta(markup),
    draftMessage: renderDeltaToTextForDisplay(markup),
    lastApiChange: (source == EmitterSources.API) ?
        plainifyChangeDelta(replaceChange(markup)) :
        undefined,
});

/**
 * Return a new draft with markup produced by applying a change delta to an existing draft
 */
export const withChange = (
    content: DraftChatContent_V2,
    change: ChangeDelta | PlainChangeDelta,
    source: EmitterSource,
): DraftChatContent_V2 => {
    const markup = parseMarkupDelta(content.draftMarkup);
    const newMarkup = composeDeltas(markup, change);

    return {
        ...content,
        draftMarkup: compactMarkupDelta(newMarkup),
        draftMessage: renderDeltaToTextForDisplay(newMarkup),
        lastApiChange: (source == EmitterSources.API) ? plainifyChangeDelta(change) : undefined,
    };
};

/**
 * Produce a different result depending on the draft content type. If the draft
 * type is not recognised, use a default result
 */
export const switchDraftContentVersion = <T>(
    content: Optional<DraftChatContent>,
    ver1Map: (c1: DraftChatContent_V1) => T,
    ver2Map: (c2: DraftChatContent_V2) => T,
    defaultThunk: () => T,
): T => {
    if (content) {
        switch (content.ver) {
            case undefined:
            case 1:
                return ver1Map(content as DraftChatContent_V1);
            case 2:
                return ver2Map(content as DraftChatContent_V2);
        }
    }
    return defaultThunk();
};

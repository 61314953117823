import MediaDeviceSelector from "../components/gui/MediaDeviceSelector";
import VideoPreview from "../components/gui/VideoPreview";
import AudioPreview from "../components/gui/AudioPreview";
import { DeviceKind } from "../domain/mediaDevices";
import { DevicePermissions, selectDevicePermissions } from "../features/mediaDevices";
import useEnumeratedDevices from "../hooks/media/useEnumeratedDevices";
import useDialogOpenRef from "../hooks/useDialogOpenRef";
import { useCallback } from "react";
import useDialogOutsideClick from "../hooks/useDialogOutsideClick";
import { CloseButton } from "../components/buttons/Close";
import useSelectorArgs from "../hooks/useSelectorArgs";
import SquadManagementSettings from "../components/SquadManagementSettings";
import { isNativePlatform } from "../misc/capacitor";
import { useNavigateBack } from "../hooks/useNavigateBack";

export default function SettingsView(): React.JSX.Element {
    const { navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });

    // Assume video permissions === all permissions (which it is with
    // enumeratedDevices this high in the component tree)
    const permissions = useSelectorArgs(selectDevicePermissions, DeviceKind.VideoInput);

    const dialogRef = useDialogOpenRef();

    const closeModal = useCallback(() => {
        navigateBack();
    }, [navigateBack]);

    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    // Request and listen to list of media devices
    useEnumeratedDevices();

    function getPermissionString(permissions: DevicePermissions) {
        switch (permissions) {
            case DevicePermissions.None:
            case DevicePermissions.Pending:
                return "Waiting for permissions...";
            case DevicePermissions.Denied:
                return "Permissions denied";
        }
    }

    return (
        <dialog
            className="c-dialog c-dialog--settings"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="c-dialog__header">
                <h1 className="c-dialog__title">Settings</h1>
                <CloseButton side="right" onClick={closeModal} />
            </header>
            <div className="c-dialog__content">
                <div className="c-settings__video">
                    <div className="c-settings__video-preview">
                        {getPermissionString(permissions) ?
                            <>{getPermissionString(permissions)}</> :
                            <VideoPreview className="c-settings-video" />}
                    </div>
                    <MediaDeviceSelector kind={DeviceKind.VideoInput} />
                </div>
                <div className="c-settings__audio">
                    <AudioPreview className="c-settings-audio" />
                </div>
                {!isNativePlatform &&
                    (
                        <div className="c-settings__audio">
                            <div className="c-settings-controls">
                                <MediaDeviceSelector kind={DeviceKind.AudioOutput} />
                            </div>
                        </div>
                    )}

                <SquadManagementSettings />
            </div>
        </dialog>
    );
}

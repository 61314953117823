import { selectSquadById } from "../../features/squads";
import * as d from "../../domain/domain";
import SensitiveText from "../gui/SensitiveText";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import { useLocation } from "react-router-dom";
import useAddressParams from "../../hooks/useAddressParams";
import classNames from "classnames";
import LinkButton from "../gui/LinkButton";
import { selectSquadIsUnread } from "../../features/channels";

function isSquadSelected(
    id: d.SquadId,
    pathname: string,
    pathSquadId: d.SquadId | undefined,
): boolean {
    return pathname.startsWith("/squad") && pathSquadId === id;
}

function SquadEntry(
    props: {
        id: d.SquadId;
        onClick?: (what: "squad", id: d.SquadId) => void;
        showUnreadCount?: boolean;
    },
) {
    const { id } = props;
    const squad = useSelectorArgs(selectSquadById, id);
    const { pathname } = useLocation();
    const { squadId: pathSquadId } = useAddressParams();
    const squadIsUnread = useSelectorArgs(selectSquadIsUnread, id);
    const thisSquadSelected = isSquadSelected(id, pathname, pathSquadId);

    if (!squad) {
        return <></>;
    }

    const classes = classNames("c-squad-listing__squad", {
        "is-selected": thisSquadSelected,
        "is-unread": squadIsUnread,
    });

    return (
        <LinkButton
            to={`/squad/${d.extractUUID(id)}`}
            className={classes}
        >
            <span className="c-squad-label">
                <SensitiveText>{squad.name}</SensitiveText>
            </span>
        </LinkButton>
    );
}

export default SquadEntry;

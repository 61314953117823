import { App } from "@capacitor/app";
import { useState, useEffect } from "react";

import { selectBackendInfo } from "../features/meta";
import { useAppSelector } from "../store/redux";
import { isNativeIosPlatform, isNativePlatform } from "../misc/capacitor";
import classNames from "classnames";

export function VersionInfo({ location }: { location?: "isAside"; }): React.JSX.Element {
    const backendInfo = useAppSelector(selectBackendInfo);

    const [nativeVersion, setNativeVersion] = useState<string>("");
    useEffect(() => {
        if (isNativePlatform) {
            const prefix = isNativeIosPlatform ? "v" : "";

            App.getInfo().then(info => setNativeVersion(prefix + info.version));
        }
    }, []);

    const outerClassNames = classNames("c-aside__versions", {
        "c-aside__versions--aside": location === "isAside",
    });
    const innerClassNames = "c-aside-version";

    return (
        <div className={outerClassNames}>
            <div className={innerClassNames}>Backend: {backendInfo?.version}</div>
            <div className={innerClassNames}>Instance: {backendInfo?.instance}</div>

            {(!isNativePlatform || nativeVersion !== __BEYOND_FRONTEND_VERSION__) && (
                <div className={innerClassNames}>
                    Frontend: {__BEYOND_FRONTEND_VERSION__}
                </div>
            )}

            {isNativePlatform && (
                <div className={innerClassNames}>
                    App version: {nativeVersion}
                </div>
            )}
        </div>
    );
}

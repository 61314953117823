import { useEffect, useRef, useState } from "react";

import log from "../misc/log";
import { promiseWithResolvers, type PromiseWithResolvers } from "../misc/promises";
import useOnComponentUnmount from "./useOnComponentUnmount";

/** Oneshot attempt to take out a lock.
 *
 * @param name the name of the lock to attempt to take
 * @returns `true` if we took the lock, `false` if we failed, otherwise
 * `undefined` if we are still waiting for the resolution
 */
export function useWebLockOnceOnly(name: string): boolean | undefined {
    // `webLock` is resolved with `true` when we want to release the lock.
    // If we are not holding the lock, this does nothing.
    const webLock = useRef<PromiseWithResolvers<boolean>>(promiseWithResolvers());

    // Used to check that we're still the latest request.
    const attemptNumber = useRef<number>(0);

    // To work around the mount/unmount/remount of StrictMode, we need a way to
    // serialise the *necessarily async* `useEffect` cleanup callback and the
    // next run through the `useEffect`.
    const canAttemptLock = useRef<PromiseWithResolvers<void>>(promiseWithResolvers());

    // The actual state of whether we have obtained the lock.
    const [haveLock, setHaveLock] = useState<boolean | undefined>();

    useEffect(() => {
        // `++var` because we will want to check we are still the *current* value.
        const n = ++attemptNumber.current;
        const wl = webLock.current;
        const cal = canAttemptLock.current;

        if (n === 1) {
            cal.resolve();
        }

        cal.promise.then(() => {
            if (n !== attemptNumber.current) return;

            canAttemptLock.current = promiseWithResolvers();

            log.debug(`Attempting to take lock '${name}' (${n})`);

            navigator.locks.request("beyond", { ifAvailable: true }, function lockCb(lock) {
                if (lock === null) {
                    log.warn(`Could not obtain lock '${name}' (${n})`);
                    setHaveLock(false);
                }
                else {
                    log.debug(`Obtained lock '${name}' (${n})`);
                    setHaveLock(true);
                    return wl.promise;
                }
            });
        });

        return () => {
            log.debug(`Cleanup lock '${name}' (${n})`);

            // Calling `resolve` on a promise that's already been resolved is an
            // error-free no-op.
            wl.resolve(true);
            setHaveLock(undefined);

            webLock.current = promiseWithResolvers();

            canAttemptLock.current.resolve();
        };
    }, [name, setHaveLock]);

    return haveLock;
}

/** Attempt to take out a lock.
 *
 * If taken, it will only be released when the component is unmounted.
 *
 * @param name the name of the lock to attempt to take
 * @returns `true` if we have the lock, `false` otherwise
 */
export function useWebLock(name: string): boolean {
    // `webLock` is resolved with `true` when we want to release the lock.
    // If we are not holding the lock, this does nothing.
    const webLockRef = useRef(promiseWithResolvers<void>());
    const pwr = webLockRef.current;
    const abortRef = useRef(new AbortController());
    const signal = abortRef.current.signal;

    const [haveLock, setHaveLock] = useState<boolean>(false);

    useEffect(() => {
        log.debug(`Attempting to take lock '${name}'`);

        navigator.locks.request(name, { signal }, function lockCb(lock) {
            if (lock === null) {
                log.debug(`Failed to obtain lock '${name}'`);
            }
            else {
                log.debug(`Obtained lock '${name}'`);
                setHaveLock(true);
                return pwr.promise;
            }
        }).catch(() => {});
    }, [name, setHaveLock, pwr, signal]);

    useOnComponentUnmount(() => {
        abortRef.current.abort("unmounting");
        pwr.resolve();
        // For StrictMode...
        abortRef.current = new AbortController();
        webLockRef.current = promiseWithResolvers();
    });

    return haveLock;
}

export default useWebLock;

import { nanoid, isAction, type UnknownAction, type Action } from "@reduxjs/toolkit";

import type { Optional } from "../misc/types";

/** This action must *only* be run against the local store.
 */
const localActionKey = "BOND_LOCAL_ACTION";

/** This action is part of a proxied action.
 * This can be either about to be pushed to the overseer,
 * or a message from the overseer.
 */
const proxyActionKey = "BOND_PROXIED_ACTION";

const getActionTag = (tag: string) => (action: any): Optional<string> => {
    const actionAny = action as any;

    if (!isAction(action)) return actionAny[tag];

    if (actionAny[tag]) return actionAny[tag];

    const metaAny = actionAny.meta as any;

    if (!metaAny) return;

    if (metaAny[tag]) return metaAny[tag];

    return (metaAny.arg as any)?.[tag];
};
const hasActionTag = (tag: string) => (action: any): boolean => !!getActionTag(tag)(action);

export const getActionLocalTag = getActionTag(localActionKey);
export const actionHasLocalTag = hasActionTag(localActionKey);

export const getActionProxyTag = getActionTag(proxyActionKey);
export const actionHasProxyTag = hasActionTag(proxyActionKey);

export const createLocalTag = (requestId: string) => ({ [localActionKey]: requestId });

const tagActionlike = (tag: string) => {
    const getExistingTag = getActionTag(tag);

    return <T>(action: T, id?: string): T => {
        if (!action) throw new Error(`cannot tag falsy value`);

        if (typeof action !== "object" && typeof action !== "function") {
            throw new Error(`Cannot tag non-object/function ${action}`);
        }

        const existingTag = getExistingTag(action);
        if (existingTag && (id === undefined || id === existingTag)) return action;

        id ??= nanoid();

        const actionAny = action as any;

        if (!("meta" in action)) {
            actionAny[tag] = id;
        }
        else {
            (actionAny.meta as any)[tag] = id;
        }

        return action;
    };
};

/** Tag a thunk as "local only". Idempotent if the same id is provided
 * (including "no id").
 */
export const tagLocalThunk = tagActionlike(localActionKey);
/** Tag a thunk as "to be proxied". Idempotent if the same id is provided
 * (including "no id").
 */
export const tagProxyThunk = tagActionlike(proxyActionKey);

/** Tag an action as "local only". Idempotent if the same id is provided
 * (including "no id").
 */
export const tagLocalAction = <A extends Action = UnknownAction>(action: A, id?: string): A =>
    tagLocalThunk(action, id);
/** Tag an action as "to be proxied". Idempotent if the same id is provided
 * (including "no id").
 */
export const tagProxyAction = <A extends Action = UnknownAction>(action: A, id?: string): A =>
    tagProxyThunk(action, id);

import WebSocket from "isomorphic-ws";

import transport, {
    setAuthInviteCode,
    setBearerToken,
    WebsocketAuthMessage,
    WebsocketError,
    WebsocketErrorCode,
    WebsocketRpcs,
    WebsocketRpcsInterface,
} from "../api/transport";
import { fromProtoUserId, pbUserId } from "../api/util";
import { backoffManagerCreator, type BackoffResultCallback } from "../ds/backoff";
import { getEnvironmentConfig } from "../misc/environment";
import log from "../misc/log";
import { appAppendListener, type AppAppendListener } from "../store/middleware";
import { ListenerAPI, startLongLivedListener, statesDifferBySelectors } from "../store/middleware";
import type { AppStore, RootState } from "../store/types";
import { isSharedWorker } from "../workers/util";
import {
    checkRefreshToken,
    fetchCurrentUserId,
    selectAuthenticated,
    selectAuthInviteCode,
    selectBearerToken,
    selectCurrentOrgId,
    selectCurrentUserId,
    setLocalPreferredUserId,
} from "./auth";
import {
    ConnectionStatus,
    selectConnectionStatus,
    selectLastConnectionCheckTime,
    selectOnlineStatus,
    updateConnectionStatus,
    updateLastConnectionCheckTime,
    updateOnlineStatus,
} from "./connection";
import { selectConnectionIdentifiers } from "./meta";

// The number of milliseconds after running the auth callback that we should
// wait before allowing ourselves to mark a websocket connection as
// "successfully connected."
export const connectionSuccessfulDelay = 2048;

export const clientConnectionProtocolVersion = 2;

const targetConfig = getEnvironmentConfig();
const targetURL = new URL(targetConfig.apiBaseUrl + "/client");
const defaultFactory = () => new WebsocketRpcs();

interface ManageConnectionParameters {
    rpcs?: () => WebsocketRpcsInterface;
}

const getConnectionManager = (factory: () => WebsocketRpcsInterface) => {
    const backoffManager = backoffManagerCreator();
    let backoffCallback: BackoffResultCallback | undefined;

    let ws: WebsocketRpcsInterface | undefined = undefined;

    const transition = (api: ListenerAPI, status: ConnectionStatus) =>
        api.dispatch(updateConnectionStatus(status));

    const errorTransition = (
        api: ListenerAPI,
        code: WebsocketErrorCode,
        backoffCallback: (success: boolean) => void,
    ) => {
        backoffCallback(false);
        ws = undefined;

        if (code === WebsocketErrorCode.Unauthorised) {
            transition(api, ConnectionStatus.ClearAuth);
        }
        else if (code === WebsocketErrorCode.UnsupportedProtocol) {
            log.error(`Outdated websocket protocol; Refresh page to update`);
        }
        else {
            transition(api, ConnectionStatus.AwaitReconnect);
        }
    };

    const ensureUserIdAndOrgId = (api: ListenerAPI, state: RootState) => {
        const haveUserId = selectCurrentUserId(state) !== undefined;
        const haveOrgId = selectCurrentOrgId(state) !== undefined;

        if (haveUserId && haveOrgId) return;

        const fetch = api.dispatch(fetchCurrentUserId());

        // Only wait for the dispatch to complete when necessary.
        if (haveUserId || isSharedWorker()) return;

        fetch.unwrap().then(
            resp => {
                // If we got a user ID, refresh the page to reload the IndexedDB.
                if (!resp?.userId) return;

                log.info(`Got new user id ${resp.userId}, reloading`);

                setLocalPreferredUserId(resp.userId);

                window.location.reload();
            },
            e => {
                // ???
                log.error(`Failed to get preferred local id`, e);
            },
        );
    };

    return (api: ListenerAPI, state: RootState) => {
        const authenticated = selectAuthenticated(state);
        const status = selectConnectionStatus(state);
        // This is: "does the browser think we're online?"
        const online = selectOnlineStatus(state);

        const token = selectBearerToken(state);
        const currentUserId = selectCurrentUserId(state);

        switch (status) {
            case ConnectionStatus.ShouldConnect: {
                if (!online) return;

                if (!token || !authenticated) {
                    log.error(`Want to connect but not authenticated`);
                    transition(api, ConnectionStatus.ClearAuth);
                    return;
                }

                const newWs = factory();
                ws = newWs;

                const backoffCb = backoffManager.begin();
                backoffCallback = backoffCb;

                log.info(`New websocket`);

                newWs.disconnected.wait().then((err: WebsocketError) => {
                    // It's possible that by the time we're woken up here, we're no longer the active
                    // websocket. In such a case, we need to ignore this event.
                    if (newWs != ws) return;

                    errorTransition(api, err.code, backoffCb);
                });

                newWs.connected.wait().then((err: WebsocketError | null) => {
                    if (newWs != ws) return;

                    if (err) {
                        errorTransition(api, err.code, backoffCb);
                    }
                    else {
                        // Don't transition to `Connected` here - wait for the auth callback
                        // to complete.
                    }
                });

                const connectionIdentifiers = selectConnectionIdentifiers(state);

                const authMessage = new WebsocketAuthMessage({
                    ...connectionIdentifiers,
                    userId: currentUserId && pbUserId(currentUserId),
                    authToken: token ?? "",
                    protocolVersion: clientConnectionProtocolVersion,
                });

                newWs.connect(targetURL, async (connectedWs: WebSocket) => {
                    connectedWs.send(authMessage.toBinary());

                    log.info(
                        "Sent pre-GOAT websocket authentication message",
                        {
                            deviceTag: authMessage.deviceTag,
                            connTag: `${authMessage.connTagPrefix}::${authMessage.connTagNum}`,
                            userId: authMessage.userId &&
                                fromProtoUserId(authMessage.userId),
                        },
                    );

                    // We want to start sending any RPC requests we've
                    // been storing up, so we should mark ourselves as
                    // connected in the redux store.
                    transition(api, ConnectionStatus.Connected);

                    // We do not want to consider ourselves "successfully connected" from
                    // the perspective of the backoffManager here. It's possible that
                    // we're about to be booted off by the server if, say, our token has
                    // expired and we haven't noticed.
                    // Instead, only successfully complete the action after a delay.
                    setTimeout(() => {
                        const isSuccess = connectedWs.readyState === WebSocket.OPEN;

                        // This is idempotent, so it doesn't matter if the
                        // `websocketErrorTransition` pathway already called it.
                        backoffCb(isSuccess);
                    }, connectionSuccessfulDelay);

                    // Once we're connected, make sure we have a userId
                    // and an orgId.
                    ensureUserIdAndOrgId(api, state);
                });

                transport.reset(newWs, new DOMException("reset", "AbortError"));

                return;
            }
            case ConnectionStatus.AwaitReconnect: {
                const delay = backoffManager.getDelay();
                if (delay === 0) {
                    transition(api, ConnectionStatus.ShouldConnect);
                }
                else {
                    // Fire and forget - if we no longer care about this
                    // update when it fires, that's fine.
                    setTimeout(() => {
                        const now = Date.now();
                        const action = updateLastConnectionCheckTime(now);
                        api.dispatch(action);
                    }, delay);
                }
                return;
            }
            case ConnectionStatus.ClearAuth: {
                log.info(`Lost authentication, closing websocket`);
                ws?.done();
                ws = undefined;

                if (backoffCallback) {
                    // This is a "success" pathway, because to be here we are electing
                    // to be good citizens and tear down the websocket pre-emptively.
                    // In reality, I don't think it matters which method we call.
                    backoffCallback(true);
                    backoffCallback = undefined;
                }

                api.dispatch(checkRefreshToken());

                transition(api, ConnectionStatus.AwaitAuth);

                return;
            }
        }
    };
};

export const startManagingConnection = (
    appendListener: AppAppendListener = appAppendListener,
    params?: ManageConnectionParameters,
) => {
    const { rpcs } = params ?? {};
    const cleanup = [];

    {
        const predicate = statesDifferBySelectors(selectBearerToken);
        const c = appendListener(
            {
                predicate,
                effect: (_action, api) => {
                    const state = api.getState();
                    const token = selectBearerToken(state);
                    if (token) {
                        setBearerToken(token);
                    }
                },
            },
        );
        cleanup.push(c);
    }

    {
        // This may need to be a map or list of codes in the future.
        const predicate = statesDifferBySelectors(selectAuthInviteCode);
        const c = appendListener(
            {
                predicate,
                effect: (_action, api) => {
                    const state = api.getState();
                    const inviteCode = selectAuthInviteCode(state);
                    setAuthInviteCode(inviteCode || null);
                },
            },
        );
        cleanup.push(c);
    }

    {
        const connectionComparer = statesDifferBySelectors(
            selectAuthenticated,
            selectConnectionStatus,
            selectOnlineStatus,
            selectLastConnectionCheckTime,
        );

        const connectionManager = getConnectionManager(rpcs ?? defaultFactory);

        const c = startLongLivedListener(
            connectionComparer,
            connectionManager,
            "connection-manager",
            appendListener,
        );

        cleanup.push(c);
    }

    return cleanup;
};

export const startDetectingOnlineStatus = (store: AppStore) => {
    const target = self ?? window;

    let online = target.navigator.onLine;

    const listenerGenerator = (o: boolean) => () => {
        if (o === online) return;

        online = o;
        store.dispatch(updateOnlineStatus(online));
    };

    const lOnline = listenerGenerator(true);
    const lOffline = listenerGenerator(false);

    target.addEventListener("online", lOnline);
    target.addEventListener("offline", lOffline);

    store.dispatch(updateOnlineStatus(online));

    return () => {
        target.removeEventListener("online", lOnline);
        target.removeEventListener("offline", lOffline);
    };
};

import type { BrowserStore } from "./store";
import type { AnyBrowserStoreUpdate } from "./updates";

export const storageCommit = (updates: AnyBrowserStoreUpdate[]) => {
    updates.forEach(([store, value]) => storageWrite(store, value));
};

/** Get the requested Storage instance.
 *
 * @param store the BrowserStore wanting to interact with a Storage
 * @returns the Storage, or undefined (e.g. if running in a worker context)
 */
const getStorage = (store: BrowserStore) =>
    store.storeType === "local" ? self.localStorage : self.sessionStorage;

export const storageWrite = <V>(store: BrowserStore<V>, value?: V) => {
    const storage = getStorage(store);
    if (!storage) return;

    if (value !== undefined) {
        const s = store.raw ? value as string : JSON.stringify(value);
        storage.setItem(store.name, s);
    }
    else {
        storage.removeItem(store.name);
    }
};

export const storageRead = <V>(store: BrowserStore<V>): V | undefined => {
    const storage = getStorage(store);
    if (!storage) return;

    const state = storage.getItem(store.name);
    if (!state) return undefined;
    if (store.raw) return state as V;
    return JSON.parse(state) as V;
};

export const storageRemove = <V>(store: BrowserStore<V>) => {
    const storage = getStorage(store);
    if (!storage) return;

    storage.removeItem(store.name);
};

export const getStorageMethods = <V>(store: BrowserStore<V>) => ({
    get: () => storageRead(store),
    set: (v: V) => storageWrite(store, v),
    clear: () => storageRemove(store),
});

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "oidc-client-ts";

import { whoAmI, WhoAmIResponse } from "../api/client";
import { listUsersByPerson } from "../api/squads";
import type { BearerToken } from "../api/transport";
import * as d from "../domain/domain";
import { UserDefinition } from "../domain/users";
import log from "../misc/log";
import { Optional } from "../misc/types";
import { checkPersistor, localStorageStore } from "../persist/shared";
import { getStorageMethods } from "../persist/storage";
import type { Connection } from "../persist/types";
import { createAppSelector } from "../store/redux";
import type { RootState } from "../store/types";
import { createProxiedAsyncThunk } from "./proxiedThunk";
import { unaryThunkHandler } from "./thunk";

export enum AuthStatus {
    // The page is loading.
    Unknown,
    // We have confirmed we do not have any valid credentials.
    Unauthenticated,
    // We have confirmed we have a valid access token.
    Authenticated,
    // We are throwing away any authentication data we have, and
    // will purge any cached data we have.
    ClearingAuthentication,
    // Our access token has expired, we are checking if we might
    // be able to use a refresh token.
    CheckingForRefresh,
    // Our access token has expired, and we are trying to use
    // a refresh token.
    Refreshing,
}

export const fetchCurrentUserId = createProxiedAsyncThunk<WhoAmIResponse | undefined, void>(
    "clients/whoAmI",
    async (_, thunkAPI) => {
        return await unaryThunkHandler(
            thunkAPI,
            whoAmI(),
            `fetchCurrentUserId`,
        );
    },
);

export const fetchCurrentPersonUserRefs = createProxiedAsyncThunk<UserDefinition[], void>(
    "squads/fetchCurrentPersonUserRefs",
    async (_, thunkAPI) => {
        const personId = selectCurrentPersonId(thunkAPI.getState());
        if (!personId) {
            return thunkAPI.rejectWithValue({ error: `no personId in OIDC state` });
        }

        return await unaryThunkHandler(
            thunkAPI,
            listUsersByPerson(personId),
            `fetchCurrentPersonUserRefs ${personId}`,
        );
    },
);

export interface OidcState {
    oidc: string;
    token: BearerToken; // derived from oidc
    personId: d.PersonId; // derived from oidc
    expired?: boolean; // derived from oidc
    // Derived from oidc. Ultimately optional in the protocol, we've
    // configured Zitadel to return it, so it *not* being present should be
    // an exceptional case.
    expiresAt?: number;
}

const debug = false;
export const deriveOidcState = (u: User): OidcState =>
    (debug && log.debug(`User access token update`, {
        sessionState: u.session_state,
        accessToken: u.access_token,
        refreshToken: u.refresh_token,
        scope: u.scope,
        expired: u.expired,
        expiresAt: u.expires_at && (u.expires_at * 1000),
        state: u.state,
        profile: u.profile,
    })) || {
        oidc: u.toStorageString(),
        token: u.access_token,
        personId: d.fromRawPersonId(u.profile.sub),
        expired: u.expired,
        expiresAt: u.expires_at && (u.expires_at * 1000),
    };

export interface AuthState {
    oidc?: OidcState;
    authInviteCode: Optional<string>;
    userId: Optional<d.UserId>;
    orgId: Optional<d.OrgId>;
    status: AuthStatus;
    alternativeLoginUsers: Array<UserDefinition>;
}

const getInitialState = (props?: Partial<AuthState>): AuthState => ({
    oidc: props?.oidc,
    authInviteCode: undefined,
    userId: props?.userId,
    orgId: props?.orgId,
    status: props?.status ?? AuthStatus.Unknown,
    alternativeLoginUsers: [],
});

// * preferredUserId *
// The value held in AuthState.userId is the ID of the user who is currently
// logged in to this page. It is also stored in local storage, and used by the
// page on startup to determine which user to seed the websocket connection
// with, and which IndexedDB to load.
// Because of this, upon changing the value of AuthState.userId we must also
// reload the page (or redirect to e.g. the login page) to ensure that the
// websocket connection is coherent, and the correct IndexedDB is loaded.

const authSlice = createSlice({
    name: "auth",
    initialState: getInitialState(),
    selectors: {
        oidc: state => state.oidc,
        token: state => state.oidc?.token,
        tokenExpired: state => state.oidc?.expired,
        tokenExpiresAt: state => state.oidc?.expiresAt,
        authInviteCode: state => state.authInviteCode,
        orgId: state => state.orgId,
        authenticated: state => state.oidc ? true : undefined,
        status: state => state.status,
        preferredUserId: state => state.userId,
        alternativeLoginUsers: state => state.alternativeLoginUsers,
    },
    reducers: {
        updateOidc: (state, { payload: oidc }: PayloadAction<OidcState>) => {
            if (state.oidc && state.oidc.personId !== oidc.personId) {
                state.userId = undefined;
                state.orgId = undefined;
            }

            state.oidc = oidc;
            if (oidc) {
                state.status = oidc.expired ? AuthStatus.CheckingForRefresh
                    : AuthStatus.Authenticated;
            }
        },
        updateAuthInviteCode: (state, { payload: code }: PayloadAction<Optional<string>>) => {
            state.authInviteCode = code;
        },
        updateCurrentUserId: (state, { payload: userId }: PayloadAction<Optional<d.UserId>>) => {
            // Useful for tests, but avoid for real code.
            state.userId = userId;
        },
        updateCurrentOrgId: (state, { payload: orgId }: PayloadAction<Optional<d.OrgId>>) => {
            // Useful for tests, but avoid for real code.
            state.orgId = orgId;
        },
        clearCurrentUserId: state => {
            state.userId = undefined;
            state.orgId = undefined;
        },
        updateStatus: (state, { payload: status }: PayloadAction<AuthStatus>) => {
            state.status = status;
        },
        reset: _state => {
            // n.b. don't include `oidc` as we use this action
            // to reset the store after signing out!
            return getInitialState();
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCurrentUserId.fulfilled, (state, { payload: data }) => {
            if (!data) return;

            state.orgId = data.orgId;
            state.userId = data.userId;
        });
        builder.addCase(fetchCurrentPersonUserRefs.fulfilled, (state, { payload: users }) => {
            state.alternativeLoginUsers = users;
        });
    },
});

export { selectCurrentUserId } from "../store/redux";

const authSelectors = authSlice.getSelectors((state: RootState) => state.auth);
export const {
    authenticated: selectAuthenticated,
    status: selectAuthStatus,
    token: selectBearerToken,
    tokenExpired: selectTokenExpired,
    tokenExpiresAt: selectTokenExpiresAt,
    authInviteCode: selectAuthInviteCode,
    orgId: selectCurrentOrgId,
    preferredUserId: selectPreferredUserId,
    alternativeLoginUsers: selectAlternativeLogins,
} = authSelectors;

export const {
    clearCurrentUserId,
    reset: resetStore,
    updateCurrentUserId,
    updateCurrentOrgId,
    updateOidc,
    updateAuthInviteCode,
    updateStatus: updateAuthStatus,
} = authSlice.actions;

export const selectCurrentPersonId = createAppSelector(
    [authSelectors.oidc],
    oidcstate => {
        return oidcstate?.personId;
    },
);

export const resetAuth = () => updateAuthStatus(AuthStatus.ClearingAuthentication);
export const checkRefreshToken = () => updateAuthStatus(AuthStatus.CheckingForRefresh);
export const isClearingAuth = (state: RootState) =>
    authSelectors.status(state) === AuthStatus.ClearingAuthentication;

export const reducer = authSlice.reducer;

// Persistence.

const preferredUserId = localStorageStore<d.UserId>("auth/preferredUserId", 1);
export const {
    get: getLocalPreferredUserId,
    set: setLocalPreferredUserId,
    clear: clearLocalPreferredUserId,
} = getStorageMethods(preferredUserId);

export const hydrate = async (_conn: Connection) => {
    const storedUserId = getLocalPreferredUserId();

    return getInitialState({
        status: AuthStatus.Unknown,
        userId: storedUserId,
    });
};

export const persistor = {
    stores: {},
    hydrate,
};
checkPersistor<AuthState>(persistor);

import omit from "lodash.omit";
import { FC, HTMLProps, MouseEventHandler, useCallback, useRef } from "react";

interface AutoExpandInputProps
    extends Omit<HTMLProps<HTMLInputElement>, "ref" | "className" | "type">
{
    hasDefaultPlaceholder: boolean;
}

export const AutoExpandInput: FC<AutoExpandInputProps> = props => {
    const {
        onMouseDown,
        onMouseUp,
        placeholder,
        value,
        style,
        hasDefaultPlaceholder,
    } = props;
    const inputProps = omit(
        props,
        "onMouseDown",
        "onMouseUp",
        "style",
        "value",
        "hasDefaultPlaceholder",
    );

    const textRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const selectAllRef = useRef<boolean>(false);

    const valueForSpan = value ?? placeholder;
    const valueForInput = value ?? ((!hasDefaultPlaceholder && placeholder) || "");

    const onInputMouseDown: MouseEventHandler<HTMLInputElement> = useCallback(e => {
        selectAllRef.current = document.activeElement !== inputRef.current;

        if (selectAllRef.current) {
            inputRef.current?.select();
        }

        onMouseDown?.(e);
    }, [onMouseDown]);

    const onInputMouseUp: MouseEventHandler<HTMLInputElement> = useCallback(e => {
        if (selectAllRef.current) {
            e.preventDefault();
        }

        selectAllRef.current = false;

        onMouseUp?.(e);
    }, [onMouseUp]);

    // FIXME: Make the placeholder transparent when we display a title suggestion
    const classNameSpan = hasDefaultPlaceholder ? "c-autoexpand__text" : "c-autoexpand__text";
    return (
        <div className="c-autoexpand" style={style}>
            <span
                ref={textRef}
                className={classNameSpan}
                aria-hidden="true"
            >
                {valueForSpan}
            </span>
            <input
                ref={inputRef}
                className="c-autoexpand__input"
                type="text"
                onMouseDown={onInputMouseDown}
                onMouseUp={onInputMouseUp}
                value={valueForInput}
                {...inputProps}
            />
        </div>
    );
};

export default AutoExpandInput;

import { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import log from "../misc/log";
import { updateUserDetails } from "../api/auth";

export default function UserDetailsView(): React.JSX.Element {
    const [params, _] = useSearchParams();
    const callbackUrl = params.get("callback_url");
    const authReqId = params.get("auth_request_id");

    const { userId } = useLocation().state;

    const [isFailed, setIsFailed] = useState(false);
    const [username, setUsername] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [nickname, setNickname] = useState("");

    const updateDetails = useCallback(async () => {
        try {
            if (!username || !displayName || !nickname) {
                setIsFailed(true);
                return;
            }

            const resp = await updateUserDetails(userId, username, displayName, nickname);
            if (!resp.isSuccess) {
                log.error("Failed to update user details:", resp.reason);
                setIsFailed(true);
                return;
            }

            window.location.replace(`${callbackUrl}${authReqId}`);
        }
        catch (err) {
            log.error("Failed to update user details", err);
            setIsFailed(true);
        }
    }, [authReqId, callbackUrl, displayName, nickname, userId, username]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                updateDetails();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [updateDetails]);

    if (!callbackUrl || !authReqId || !userId) {
        log.error("No callback URL or auth request ID or user ID provided");
        return <Navigate to="/login" />;
    }

    // TODO: consolidate form elements - maybe https://react-hook-form.com/?
    return (
        <div className="c-signin-wrapper">
            <fieldset className="c-signin">
                <>
                    <div className="c-signin__element c-signin__element--active">
                        <label htmlFor="username" className="c-signin__label">Username</label>
                        <div className="c-signin__value">
                            <input
                                type="text"
                                id="username"
                                name="signin-input"
                                placeholder="Enter your username"
                                value={username}
                                autoComplete="off"
                                maxLength={100}
                                className="c-signin__input"
                                onChange={e => setUsername(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="c-signin__element c-signin__element--active">
                        <label htmlFor="displayName" className="c-signin__label">
                            Display Name
                        </label>
                        <div className="c-signin__value">
                            <input
                                type="text"
                                id="displayName"
                                name="signin-input"
                                placeholder="Enter your display name"
                                value={displayName}
                                autoComplete="off"
                                maxLength={100}
                                className="c-signin__input"
                                onChange={e => setDisplayName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="c-signin__element c-signin__element--active">
                        <label htmlFor="nickname" className="c-signin__label">Nickname</label>
                        <div className="c-signin__value">
                            <input
                                type="text"
                                id="nickname"
                                name="signin-input"
                                placeholder="Enter your nickname"
                                value={nickname}
                                autoComplete="off"
                                maxLength={100}
                                className="c-signin__input"
                                onChange={e => setNickname(e.target.value)}
                            />
                        </div>
                    </div>
                </>
                <button
                    className="cp-btn cp-btn-signin"
                    title="Continue"
                    onClick={updateDetails}
                >
                    Continue
                </button>
                {isFailed && (
                    <div className="c-signin__element c-signin__element--error">
                        <label className="c-signin__error">Failed to update user details</label>
                    </div>
                )}
            </fieldset>
        </div>
    );
}

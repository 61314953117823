import AvatarUploadControls from "../components/AvatarUploadControls";
import useDialogOpenRef from "../hooks/useDialogOpenRef";
import { useCallback } from "react";
import useDialogOutsideClick from "../hooks/useDialogOutsideClick";
import { CloseButton } from "../components/buttons/Close";
import { useNavigateBack } from "../hooks/useNavigateBack";

export function AvatarView(): React.JSX.Element {
    const { navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });

    const dialogRef = useDialogOpenRef();

    const closeModal = useCallback(() => {
        navigateBack();
    }, [navigateBack]);

    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    return (
        <dialog
            className="c-dialog c-dialog--settings"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="c-dialog__header">
                <h1 className="c-dialog__title">Avatar</h1>
                <CloseButton side="right" onClick={closeModal} />
            </header>
            <div className="c-dialog__content">
                <AvatarUploadControls />
            </div>
        </dialog>
    );
}

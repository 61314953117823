import * as d from "../domain/domain";
import { startConnectionRttWatcher } from "../misc/connectionRttWatcher";
import { appAppendListener, type AppAppendListener } from "../store/middleware";
import { fetchCurrentPersonUserRefs } from "./auth";
import {
    bondStreamingManager,
    invitedBondStreamingManager,
    startBondCatchupSummariesStream,
    startBondContemporariesStream,
    startSubBondObserversStream,
    streamArchivedBondsList,
    subBondsThunk,
    visibleBondObservationManager,
} from "./bonds";
import { startCallInterestStream } from "./calls";
import { visibleChannelMessageStreamingManager } from "./chats";
import { sustainBrowserFocusReport, sustainTypingActivityReport } from "./connection";
import { selectActiveForNotifications, selectAnyTabActive, selectTypingInterest } from "./interest";
import { getBackendInfo } from "./meta";
import {
    startConnectedEffectListener,
    startConnectedListener,
    startConnectedStatefulListener,
} from "./middleware";
import { streamNotifications } from "./notifications";
import {
    bondPreviewStreamingManager,
    messageFetcher,
    messageReadUpdater,
    messageSender,
    startChannelMsgSeqNoStream,
} from "./shared";
import {
    fetchCurrentPersonMemberships,
    startOrgInterestStream,
    startSquadInterestStream,
    startSquadObserversInterestStream,
    startSubUserSquadListsStream,
} from "./squads";
import { startUserInterestStream } from "./users";

export const startManagingAllInterest = (
    appendListener: AppAppendListener = appAppendListener,
) => [
    startBondCatchupSummariesStream(appendListener),
    startBondContemporariesStream(appendListener),
    startCallInterestStream(appendListener),
    startChannelMsgSeqNoStream(appendListener),
    startOrgInterestStream(appendListener),
    startSquadInterestStream(appendListener),
    startSquadObserversInterestStream(appendListener),
    startSubBondObserversStream(appendListener),
    startSubUserSquadListsStream(appendListener),
    startUserInterestStream(appendListener),

    startConnectedStatefulListener(appendListener)(...visibleChannelMessageStreamingManager),
    startConnectedStatefulListener(appendListener)(...visibleBondObservationManager),
    startConnectedStatefulListener(appendListener)(...bondStreamingManager),
    startConnectedStatefulListener(appendListener)(...invitedBondStreamingManager),
    startConnectedStatefulListener(appendListener)(...bondPreviewStreamingManager),
];

export const startConnectedListeners = (
    appendListener: AppAppendListener = appAppendListener,
    userId?: d.UserId,
) => [
    startConnectedListener(getBackendInfo, { appendListener }),
    ...userId ? [
        startConnectedListener(fetchCurrentPersonMemberships, { appendListener }),
        startConnectedListener(fetchCurrentPersonUserRefs, { appendListener }),

        startConnectedListener(subBondsThunk, { appendListener }),
        startConnectedListener(streamArchivedBondsList, { appendListener }),

        ...startManagingAllInterest(appendListener),

        // TODO: tests for these
        startConnectedListener(streamNotifications, {
            appendListener,
            argGen: selectActiveForNotifications,
            abortOldThunk: true,
        }),
        startConnectedListener(sustainBrowserFocusReport, {
            appendListener,
            extraSelectors: [selectAnyTabActive],
        }),
        startConnectedListener(sustainTypingActivityReport, {
            appendListener,
            extraSelectors: [selectTypingInterest],
        }),

        startConnectedEffectListener(startConnectionRttWatcher, appendListener),

        startConnectedStatefulListener(appendListener)(...messageFetcher),
        startConnectedStatefulListener(appendListener)(...messageReadUpdater),
        startConnectedStatefulListener(appendListener)(...messageSender),
    ] : [],
];

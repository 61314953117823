import { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import log from "../misc/log";
import { verifyPasscode } from "../api/auth";

export default function PasscodeView(): React.JSX.Element {
    const [params, _] = useSearchParams();
    const callbackUrl = params.get("callback_url");
    const authReqId = params.get("auth_request_id");

    const { isNewUser, userEmail, userId } = useLocation().state;
    const navigate = useNavigate();

    const [otp, setOtp] = useState("");

    const [isFailed, setIsFailed] = useState(false);

    const signIn = useCallback(async () => {
        try {
            if (!authReqId || !callbackUrl || !userId) {
                setIsFailed(true);
                return;
            }

            const resp = await verifyPasscode(authReqId, userId, otp);
            if (!resp.isSuccess) {
                log.error("Failed to sign in", resp.reason);
                setIsFailed(true);
                return;
            }

            if (isNewUser) {
                const userDetailsUrl =
                    `/login/details?callback_url=${callbackUrl}&auth_request_id=${authReqId}`;
                navigate(userDetailsUrl, { replace: true, state: { userId } });
                return;
            }

            window.location.replace(`${callbackUrl}${authReqId}`);
        }
        catch (err) {
            log.error("Failed to sign in with passcode", err);
            setIsFailed(true);
        }
    }, [authReqId, callbackUrl, isNewUser, navigate, otp, userId]);

    useEffect(() => {
        if (otp.length >= 8) {
            signIn();
        }
    }, [otp, signIn]);

    if (!callbackUrl || !authReqId) {
        log.error("No callback URL or auth request ID provided");
        return <Navigate to="/login" />;
    }

    if (!userId || !userEmail) {
        log.error("No user ID or email provided");
        return <Navigate to="/login" />;
    }

    return (
        <>
            <p>
                NB: your OTP is 11111111, and you will sign in after you type the 8th character
            </p>
            <div className="c-signin-wrapper">
                <fieldset className="c-signin">
                    <>
                        <div>
                            <div className="c-signin__value">Hello {userEmail}!</div>
                        </div>
                        <div className="c-signin__element c-signin__element--active">
                            <label htmlFor="otp" className="c-signin__label">Passcode</label>
                            <div className="c-signin__value">
                                <input
                                    type="text"
                                    id="otp"
                                    name="signin-input"
                                    placeholder="Enter 8-digit OTP"
                                    maxLength={8}
                                    value={otp}
                                    autoComplete="off"
                                    className="c-signin__input"
                                    onChange={e => setOtp(e.target.value)}
                                />
                            </div>
                        </div>
                    </>
                    {isFailed && (
                        <div className="c-signin__element c-signin__element--error">
                            <label className="c-signin__error">Failed to sign in</label>
                        </div>
                    )}
                </fieldset>
            </div>
        </>
    );
}

import { useCallback, useState } from "react";

import useRtcSessionContext from "@/hooks/rtc/useRtcSessionContext";
import useDialogOpenRef from "@/hooks/useDialogOpenRef";
import useDialogOutsideClick from "@/hooks/useDialogOutsideClick";
import { useNavigateBack } from "@/hooks/useNavigateBack";
import log from "@/misc/log";

import { CloseButton } from "./buttons/Close";
import { FeatureFlagged } from "./FeatureFlags";
import classNames from "classnames";

function CallLocationModal(): JSX.Element {
    const { navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });
    const { updateLocationConfig } = useRtcSessionContext();

    const closeModal = useCallback(() => {
        log.debug("Closing modal: call location");
        navigateBack();
    }, [navigateBack]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const [locationEntry, setLocationEntry] = useState("");
    const [headsetMode, setHeadsetMode] = useState(false);

    const toggleButtonClassName = classNames(
        "cp-btn-call-location",
        "cp-btn-call-location--headset",
        {
            "is-active": headsetMode,
            "is-disabled": !headsetMode,
        },
    );

    const onConfirm = useCallback(() => {
        updateLocationConfig?.(locationEntry, headsetMode);
        closeModal();
    }, [updateLocationConfig, closeModal, locationEntry, headsetMode]);

    return (
        <dialog
            className="c-dialog c-dialog--add-people"
            onClose={closeModal}
            onClick={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="c-dialog__header c-dialog__header--centered">
                <h1 className="c-dialog__title">What is your live location?</h1>
                <CloseButton side="right" onClick={closeModal} />
            </header>

            <article className="c-dialog__content-wrapper">
                <div className="c-dialog__content c-dialog__content--has-scroll">
                    <div className="c-form-element-new">
                        <input
                            className="c-input-new c-input-new--full c-input-new--solid"
                            type="text"
                            value={locationEntry}
                            onChange={e => setLocationEntry(e.target.value.toLocaleLowerCase())}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    onConfirm();
                                }
                            }}
                        />
                    </div>
                    <div
                        className="c-form-element-new"
                        style={{ display: "flex", flexDirection: "row" }}
                    >
                        <div style={{ width: "100%", alignContent: "center" }}>
                            <b>Headset mode:</b> keep audio from live location?
                        </div>
                        <button
                            className={toggleButtonClassName}
                            onClick={() => setHeadsetMode(prev => !prev)}
                        >
                            Headset mode
                        </button>
                    </div>
                    <div className="c-form-element-new">
                        <button
                            className="c-input-new c-input-new--full c-input-new--solid"
                            onClick={onConfirm}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </article>
        </dialog>
    );
}

export default function FlaggedCallLocationModal(): JSX.Element {
    return (
        <FeatureFlagged flag="call-location-controls" match={true}>
            <CallLocationModal />
        </FeatureFlagged>
    );
}

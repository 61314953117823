import { memo, useMemo } from "react";
import {
    AnyOfficialMessage,
    AnyUnsentLocalMessage,
    getMsgTs,
    getSenderId,
} from "../../domain/messages";
import { selectCurrentUserId } from "../../features/auth";
import { selectUser } from "../../features/users";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import shallowEqual from "../../misc/shallowEqual";
import { useAppSelector } from "../../store/redux";
import Avatar from "../gui/Avatar";
import TimeAgo from "../gui/TimeAgo";

const jsonSyntaxRegexp =
    /"(?:\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(?:\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?|[[\]{},\s]/g;

interface SyntaxHighlightJsonLineProps {
    line: string;
}
const SyntaxHighlightJsonLine = memo(
    ({ line }: SyntaxHighlightJsonLineProps): React.JSX.Element => {
        const result = [];

        let match;
        while (match = jsonSyntaxRegexp.exec(line), match) {
            let style = {};
            if (/-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?$/.test(match[0])) {
                style = { color: "orangered" };
            }
            else if (/^"/.test(match[0])) {
                if (/:$/.test(match[0])) {
                    style = { color: "lightgreen" };
                }
                else {
                    style = { color: "lightblue" };
                }
            }
            else if (/true|false/.test(match[0])) {
                style = { color: "orange" };
            }
            else if (/null/.test(match[0])) {
                style = { color: "orange" };
            }
            if (shallowEqual(style, {})) {
                result.push(match[0]);
            }
            else {
                result.push(<span style={style}>{match[0]}</span>);
            }
        }

        return (
            <p
                style={{
                    lineHeight: "1.2",
                    fontSize: "8pt",
                    fontFamily: "monospace",
                    marginBottom: "-4px",
                }}
            >
                {result}
            </p>
        );
    },
);

interface JsonPrettyPrintProps {
    data: any;
}
const JsonPrettyPrint = ({ data }: JsonPrettyPrintProps): React.JSX.Element => {
    const jsonStr = useMemo(
        () => JSON.stringify(data, undefined, 2),
        [data],
    );

    const lines = useMemo(
        () =>
            jsonStr.split("\n")
                .map((line, i) => <SyntaxHighlightJsonLine key={i} line={line} />),
        [jsonStr],
    );

    return <>{lines}</>;
};

export interface DebugMessageViewProps {
    msg?: AnyOfficialMessage | AnyUnsentLocalMessage;
}
export const DebugMessageView = ({ msg }: DebugMessageViewProps): React.JSX.Element => {
    const currentUserId = useAppSelector(selectCurrentUserId);
    const senderId = getSenderId(msg, currentUserId);

    const sender = useSelectorArgs(selectUser, senderId);

    if (!msg) {
        return <div className="c-message c-message--unknown"></div>;
    }

    const userId = sender?.id;
    const name = sender?.name ?? "unknown";
    const ts = getMsgTs(msg);

    return (
        <div className="c-message">
            {userId && (
                <Avatar
                    userId={userId}
                    showPresence={false}
                    hidden={!name}
                />
            )}
            <div className="c-message__content">
                {(name || ts) && (
                    <div className="c-message__meta">
                        {name &&
                            (
                                <span className="c-message__author">
                                    {name}
                                </span>
                            )}
                        {ts && (
                            <span className="c-message__timestamp">
                                <TimeAgo from={ts?.valueOf() || 0} live={true} precise={true} />
                            </span>
                        )}
                    </div>
                )}
                <div className="c-message__post">
                    <JsonPrettyPrint data={msg} />
                </div>
            </div>
        </div>
    );
};

export default DebugMessageView;

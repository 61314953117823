import * as d from "../domain/domain";
import React from "react";
import {
    selectAllContentBondSummary,
    selectIsBondLive,
    selectCatchupKnowledge,
    selectChannelIdByBondId,
    selectIsBondOneOnOne,
} from "../features/bonds";
import { Optional } from "../misc/types";
import useSelectorArgs from "../hooks/useSelectorArgs";
import SensitiveText from "./gui/SensitiveText";
import { selectUser } from "../features/users";
import { selectIsRead, selectMessageIdsByChannelId } from "../features/channels";
import classNames from "classnames";
import { useAppSelector } from "../store/redux";
import { selectCurrentUserId } from "../features/auth";
import { selectAttachmentMimeTypeById, selectMessage } from "../features/chats";
import {
    isOfficialMessage,
    OfficialMessage,
    OfficialChatMessage,
    OfficialMessageType,
    CallEndedMessage,
    isOfficialChatMessage,
} from "../domain/messages";
import { isInlinableImage } from "../misc/attachments";
import { RenderChatMessageView } from "./messages/ChatMessageView";
import { userSortByNickname } from "../domain/users";
import { selectUsers } from "../features/users";
import { getUserNamesString, getWasOrWereString } from "../misc/utils";

const sortByNickname = { sort: userSortByNickname };

function PreviewSender(
    { userId, isRead }: { userId: Optional<d.UserId>; isRead: boolean; },
): React.JSX.Element {
    const currentUserId = useAppSelector(selectCurrentUserId);
    const user = useSelectorArgs(selectUser, userId);
    const classes = classNames("c-mention", {
        "c-mention--read": isRead,
    });
    const userName = userId == currentUserId ? "You" : user?.nickname || "";

    return (
        <span className={classes}>
            {userName + ": "}
        </span>
    );
}

const validVideoTypes = new Set([
    "video/mp4",
    "video/mpeg",
    "video/ogg",
    "video/mp2t",
    "video/webm",
    "video/3gpp",
    "video/3gpp2",
]);

function isVideo(mimeType: string): boolean {
    return validVideoTypes.has(mimeType);
}

function stringifyAttachments(attachmentMimeTypes: string[]) {
    if (attachmentMimeTypes.length == 0) return "";
    const numberOfImages = attachmentMimeTypes.filter(t => isInlinableImage(t)).length;
    const numberOfVideos = attachmentMimeTypes.filter(t => isVideo(t)).length;
    const numberOfFiles =
        attachmentMimeTypes.filter(t => !isInlinableImage(t) && !isVideo(t)).length;

    const imageOrImages = numberOfImages == 1 ? "image" : "images";
    const videoOrVideos = numberOfVideos == 1 ? "video" : "videos";
    const fileOrFiles = numberOfFiles == 1 ? "file" : "files";
    const listOfAttachmentTypes = [
        ...numberOfImages > 0
            ? [`${Intl.NumberFormat("en-GB").format(numberOfImages)} ${imageOrImages}`] : [],
        ...numberOfVideos > 0
            ? [`${Intl.NumberFormat("en-GB").format(numberOfVideos)} ${videoOrVideos}`] : [],
        ...numberOfFiles > 0 ?
            [`${Intl.NumberFormat("en-GB").format(numberOfFiles)} ${fileOrFiles}`]
            : [],
    ];

    const formatterLists = new Intl.ListFormat("en", {
        style: "long",
        type: "conjunction",
    });

    const formattedFileNames = formatterLists.format(listOfAttachmentTypes);
    return `${formattedFileNames}.`;
}

export interface PreviewLastMessageProps {
    lastMessage: OfficialMessage;
}

export function PreviewLastMessage(
    { lastMessage }: PreviewLastMessageProps,
): React.JSX.Element {
    switch (lastMessage.type) {
        case OfficialMessageType.Chat: {
            return <PreviewChatMessage message={lastMessage as OfficialChatMessage} />;
        }
        case OfficialMessageType.CallEnd: {
            return <PreviewCallEndMessage message={lastMessage as CallEndedMessage} />;
        }
        default: {
            // This case (with call start messages) should not happen, as we
            // display a live summary while the bond is live.
            return <div className="c-message c-message--unknown"></div>;
        }
    }
}

export function PreviewCallEndMessage(
    { message }: { message: CallEndedMessage; },
): React.JSX.Element {
    const participants = useSelectorArgs(selectUsers, message.participantIds, sortByNickname);

    // FIXME: Replace your name with "You".
    const wasOrWere = getWasOrWereString(participants.length);
    return (
        <SensitiveText>
            {`${getUserNamesString(participants)} ${wasOrWere} live.`}
        </SensitiveText>
    );
}

export function PreviewChatMessage(
    { message }: { message: OfficialChatMessage; },
): React.JSX.Element {
    const attachmentFilenames = useSelectorArgs(
        selectAttachmentMimeTypeById,
        message.attachmentIds,
    );
    if (message.content?.message) {
        return <RenderChatMessageView content={message.content} inline={true} />;
    }

    const attachmentDescription = stringifyAttachments(attachmentFilenames);
    return (
        <SensitiveText>
            {attachmentDescription}
        </SensitiveText>
    );
}

export default function BondCardContributorsAndSummary(
    { bondId }: { bondId: d.BondId; },
): React.JSX.Element {
    const bondIsLive = useSelectorArgs(selectIsBondLive, bondId);
    const channelId = useAppSelector(selectChannelIdByBondId(bondId));
    const isBondOneOnOne = useSelectorArgs(selectIsBondOneOnOne, bondId);
    const isRead = useSelectorArgs(selectIsRead, channelId);
    const allContentSummary = useSelectorArgs(selectAllContentBondSummary, bondId);
    const messageIds = useAppSelector(selectMessageIdsByChannelId(channelId));
    const lastMessage = useSelectorArgs(
        selectMessage,
        messageIds[messageIds.length - 1],
    );
    const catchupKnowledge = useSelectorArgs(selectCatchupKnowledge, bondId);
    const currentUserId = useAppSelector(selectCurrentUserId);
    if (bondIsLive) {
        // FIXME: The all content summary is the live call summary in this case. This summary is too long
        // Need to make this shorter.
        return (
            <>
                <span className="c-card__ai-summary"></span>
                <SensitiveText>{allContentSummary}</SensitiveText>;
            </>
        );
    }

    if (!lastMessage) return <></>;
    // If it is not an official message, then it does not have a sequence number
    // We only display the message in that case
    if (!isOfficialMessage(lastMessage)) {
        return (
            <>
                <PreviewSender userId={currentUserId} isRead={true} />
                <SensitiveText>{lastMessage.content?.message || ""}</SensitiveText>
            </>
        );
    }

    // If we have a catchup summary of this message, display the summary
    if (
        lastMessage.sequenceNumber <= (catchupKnowledge?.lastSummarisedSeq ?? -1)
    ) {
        return (
            <>
                <span className="c-card__ai-summary"></span>
                <SensitiveText>{catchupKnowledge?.summary || allContentSummary}</SensitiveText>
            </>
        );
    }

    // Preview the sender for certain types of chat messages
    let senderPreview = <></>;
    if (isOfficialChatMessage(lastMessage)) {
        const youAreTheLastContributor = currentUserId == lastMessage.senderId;
        senderPreview = (
            <>
                {(!isBondOneOnOne || youAreTheLastContributor) && (
                    <PreviewSender
                        userId={lastMessage.senderId}
                        isRead={isRead}
                    />
                )}
            </>
        );
    }

    return (
        <>
            {senderPreview}
            <PreviewLastMessage lastMessage={lastMessage} />
        </>
    );
}

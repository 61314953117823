import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import * as clapi from "../api/client";
import { checkPersistor } from "../persist/shared";
import type { RootState } from "../store/types";
import {
    AuthStatus,
    OidcState,
    resetStore,
    selectCurrentUserId,
    updateAuthStatus,
    updateOidc,
} from "./auth";
import { createStreamingAsyncThunk } from "./streamingThunk";

export const sustainBrowserFocusReport = createStreamingAsyncThunk("calls/reportBrowserFocus", {
    rpc: ({ signal }) => clapi.sustainBrowserFocusReport(signal),
    parser: () => {},
});

export const sustainTypingActivityReport = createStreamingAsyncThunk("calls/reportTypingActivity", {
    rpc: ({ signal }) => clapi.sustainTypingActivityReport(signal),
    parser: () => {},
});

export enum ConnectionStatus {
    // Waiting for auth credentials (new or renewed).
    AwaitAuth,
    // Should attempt to connect.
    ShouldConnect,
    // Websocket is connected.
    Connected,
    // Waiting in backoff for reconnection.
    AwaitReconnect,
    // Auth error on websocket.
    ClearAuth,
}

export interface ConnectionState {
    /** Whether the tab is visible, in-focus and recently interacted with.
     *
     * This is a *local* property to the tab.
     * The overseer has a meta-interest key for the union across all tabs.
     */
    active: boolean;
    // Whether the client is connected to the internet
    online: boolean;
    // The status of the client's connection to our backend
    status: ConnectionStatus;
    // A field to allow delaying execution of the connection state machine.
    lastConnectionCheckTime: number;
}

const getInitialState = (props?: Partial<ConnectionState>): ConnectionState => ({
    active: true,
    online: navigator.onLine,
    status: props?.status ?? ConnectionStatus.AwaitAuth,
    lastConnectionCheckTime: Date.now(),
});

const connSlice = createSlice({
    name: "connection",
    initialState: getInitialState(),
    selectors: {
        active: state => state.active,
        online: state => state.online,
        status: state => state.status,
        lastConnectionCheckTime: state => state.lastConnectionCheckTime,
    },
    reducers: {
        updateActive: (state, { payload: active }: PayloadAction<boolean>) => {
            state.active = active;
        },
        updateOnline: (state, { payload: online }: PayloadAction<boolean>) => {
            state.online = online;
        },
        updateStatus: (state, action: PayloadAction<ConnectionStatus>) => {
            state.status = action.payload;
        },
        /** Poke the connection state machine.
         *
         * The check against the current status is important to avoid
         * re-triggering the handling of whichever state we *are* in.
         */
        updateLastConnectionCheckTime: (state, { payload: time }: PayloadAction<number>) => {
            if (state.status === ConnectionStatus.AwaitReconnect) {
                state.lastConnectionCheckTime = time;
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
        builder.addCase(updateOidc, (state, { payload: oidc }: PayloadAction<OidcState>) => {
            // `updateOidc` is only used to push a valid (or expired) auth state.
            // Thus, this action only pushes us from `AwaitAuth` to `ShouldConnect`.
            if (state.status === ConnectionStatus.AwaitAuth && oidc && !oidc.expired) {
                state.status = ConnectionStatus.ShouldConnect;
            }
        });
        builder.addCase(
            updateAuthStatus,
            (state, { payload: status }: PayloadAction<AuthStatus>) => {
                const authed = status === AuthStatus.Authenticated;
                if (state.status === ConnectionStatus.AwaitAuth && authed) {
                    state.status = ConnectionStatus.ShouldConnect;
                }
                else if (state.status !== ConnectionStatus.AwaitAuth && !authed) {
                    state.status = ConnectionStatus.ClearAuth;
                }
            },
        );
    },
});

const connSelectors = connSlice.getSelectors((state: RootState) => state.connection);
export const {
    active: selectActiveStatus,
    online: selectOnlineStatus,
    status: selectConnectionStatus,
    lastConnectionCheckTime: selectLastConnectionCheckTime,
} = connSelectors;

const statusIs = (status: ConnectionStatus) => (state: RootState) =>
    connSelectors.status(state) === status;
export const selectConnected = statusIs(ConnectionStatus.Connected);
export const selectNotConnected = (state: RootState) => !selectConnected(state);

export const {
    updateActive: updateActiveStatus,
    updateOnline: updateOnlineStatus,
    updateStatus: updateConnectionStatus,
    updateLastConnectionCheckTime,
} = connSlice.actions;

export const selectConnectedWithUserId = (state: RootState) => {
    return selectConnected(state) && selectCurrentUserId(state) !== undefined;
};
export const selectNotConnectedWithUserId = (state: RootState) => {
    return !selectConnectedWithUserId(state);
};

// For test use only.
export const updateTestConnected = (connected: boolean) =>
    updateConnectionStatus(connected ? ConnectionStatus.Connected : ConnectionStatus.AwaitAuth);

export const reducer = connSlice.reducer;

// Persistence.

export const persistor = {
    stores: {},
};
checkPersistor<ConnectionState>(persistor);

import classNames from "classnames";
import EmbedBlot from "quill/blots/embed";
import { MentionOpValue } from "../../domain/delta";
import { parseSquadUrn, parseUserUrn } from "../../domain/domain";
import { Optional } from "../../misc/types";
import { AudienceMember, isUserId } from "../../domain/audience";

const unknownChar = "\ufffd";

export class MentionBlot extends EmbedBlot {
    static blotName = "mention";
    static tagName = "SPAN";
    static className = "cp-composer-mention";

    private elem: HTMLElement;

    static create(value: MentionOpValue) {
        const node = super.create(value) as HTMLElement;

        node.innerText = value.text;

        node.setAttribute("data-mention-target", value.target);
        node.setAttribute("data-mention-text", value.text);

        // You can't convince me otherwise:
        node.animate([
            {
                opacity: 0,
                transform: "rotateX(90deg)",
                easing: "cubic-bezier(0.7, 0.71, 0.64, 1.95)",
            },
            {
                opacity: 1,
                transform: "unset",
            },
        ], 200);

        return node;
    }

    constructor(...args: ConstructorParameters<typeof EmbedBlot>) {
        super(...args);

        this.elem = this.domNode as HTMLElement;
    }

    static isMentionCase(raw: string): raw is "user" | "squad" {
        return ["user", "squad"].includes(raw);
    }

    static parseTarget<T>(raw: string, parser: (raw: string) => T): Optional<T> {
        try {
            return parser(raw);
        }
        catch {
            return undefined;
        }
    }

    static value(domNode: Node): MentionOpValue | string {
        const elem = domNode as HTMLElement;

        const targetRaw = elem.getAttribute("data-mention-target") ?? "";
        const targetParser = isUserId(targetRaw as AudienceMember) ? parseUserUrn : parseSquadUrn;
        const target = MentionBlot.parseTarget(targetRaw, targetParser);
        if (target === undefined) {
            return unknownChar;
        }

        const text = elem.getAttribute("data-mention-text") ?? "";

        return { target, text };
    }

    static formats(domNode: Node): {
        [index: string]: any;
    } {
        const elem = domNode as HTMLElement;

        const isPrefilled = elem.getAttribute("data-mention-prefilled") !== null;

        return isPrefilled ? { prefilled: true } : {};
    }

    private setClassName(prefilled: boolean) {
        this.elem.className = classNames(MentionBlot.className, {
            "cp-composer-mention--prefilled": prefilled,
        });
    }

    format(format: string, value: any) {
        if (format != "prefilled") return;

        this.setClassName(value);
        if (value) {
            this.elem.setAttribute("data-mention-prefilled", "");
        }
        else {
            this.elem.removeAttribute("data-mention-prefilled");
        }
    }
}

export default MentionBlot;

import { PushNotifications } from "@capacitor/push-notifications";
import { useCallback, useEffect, useState } from "react";

import { reportNotificationTokenThunk } from "../../features/notifications";
import useBackoff from "../../hooks/useBackoff";
import { useConnectedEffect } from "../../hooks/useConnectedEffect";
import { isNativeAndroidPlatform, isNativePlatform } from "../../misc/capacitor";
import log from "../../misc/log";
import { useAppDispatch } from "../../store/redux";

const createDefaultNotificationChannelIfAndroid = async () => {
    if (isNativeAndroidPlatform) {
        await PushNotifications.createChannel({
            id: "beyond_default_notification_channel", // Matches the default channel specified in AndroidManifest.xml.
            name: "Bond",
            description: "The primary notification channel for the Bond app.",
            sound: "notification.mp3", // The file name of a sound file should be specified relative to the android app `res/raw` directory.
            visibility: 0, // Private level: Show this notification on all lockscreens, but conceal sensitive or private information on secure lockscreens.
            lights: true,
            lightColor: "#6A87D0", // Matches the "blue" token in _color.scss.
            vibration: true,
        });
    }
};

const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
        log.warn("User denied notification permissions!");
        return;
    }

    await PushNotifications.register();
};

// TODO: move to a listener-middleware
function NotificationTokenManager(): React.JSX.Element {
    const dispatch = useAppDispatch();

    const [token, setToken] = useState("");
    const [succeeded, setSucceeded] = useState(false);
    const { actionIsPermitted, actionBegin } = useBackoff();

    useConnectedEffect(() => {
        if (!token || !actionIsPermitted || succeeded) return;

        const backoffCallback = actionBegin();

        dispatch(reportNotificationTokenThunk({ token }))
            .unwrap()
            .then(
                () => {
                    backoffCallback(true);
                    setSucceeded(true);
                },
                () => backoffCallback(false),
            );
    }, [actionBegin, actionIsPermitted, dispatch, token, succeeded]);

    const addListeners = useCallback(async () => {
        await PushNotifications.addListener("registration", token => {
            setToken(token.value);
        });

        await PushNotifications.addListener("registrationError", err => {
            log.error("Registration error: ", err.error);
        });

        await PushNotifications.addListener("pushNotificationReceived", () => {
            log.info("Push notification received");
        });

        await PushNotifications.addListener("pushNotificationActionPerformed", () => {
            log.info("Push notification action performed");
        });
    }, []);

    useEffect(() => {
        createDefaultNotificationChannelIfAndroid()
            .then(addListeners)
            .then(registerNotifications)
            .catch(e => {
                log.error("An error occured while initialising native notifications", e);
            });
    }, [addListeners]);

    return <></>;
}

export default function NativeNotificationManager(): React.JSX.Element {
    return (
        <>
            {isNativePlatform && <NotificationTokenManager />}
        </>
    );
}

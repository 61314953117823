import { isMobileBrowser } from "../misc/mobile";
import { MobileTabsView } from "../views/MobileViews";
import { MultitaskingMediaControls } from "./gui/MultitaskingMediaControls";

export function Footer(): React.JSX.Element {
    return (
        <>
            {isMobileBrowser() && <MobileTabsView />}
            <MultitaskingMediaControls />
        </>
    );
}

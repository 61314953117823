import { useCallback, useEffect, useMemo } from "react";

import * as d from "../domain/domain";
import { UserOverview, userSortByActivity } from "../domain/users";
import { selectPrivateBondmates } from "../features/bonds";
import { selectConnected } from "../features/connection";
import { FilterScope } from "../features/filterPanel";
import { selectObservationsForSquadIds } from "../features/squads";
import { selectUsers } from "../features/users";
import { useAppSelector } from "../store/redux";
import { useInterestedSquads, useInterestedUsers } from "./interest/useInterest";
import useSelectorArgs from "./useSelectorArgs";
import { useShallowEqualsMemo } from "./useShallowEquals";
import useTimerTarget from "./useTimerTarget";

const showInactivePeriod = 60 * 60 * 1000; // 60 minutes

export default function useFreshSquadObservers(
    squadsToSubObservers: d.SquadId[],
    scope: FilterScope,
) {
    const privateBondmates = useAppSelector(selectPrivateBondmates);

    useInterestedSquads(squadsToSubObservers);

    // Select these observations from the redux store
    const squadObservations = useSelectorArgs(selectObservationsForSquadIds, squadsToSubObservers);
    const squadObservers = useShallowEqualsMemo(() => {
        const obs = squadObservations.map(uob => uob.userId);
        // If the scope is private, we only show users who share at least one
        // private bond with the current user.
        const filtered = scope === "private" ? obs.filter(id => privateBondmates.has(id)) : obs;
        return Array.from(filtered);
    }, [squadObservations, privateBondmates, scope]);

    useInterestedUsers(squadObservers);

    const isConnected = useAppSelector(selectConnected);

    const { nowMs, setTarget } = useTimerTarget();

    const presenceIsFresh = useCallback((u: UserOverview) => {
        if (!isConnected) return false;
        if (!u.activity || u.activity.active) return true;

        return nowMs < u.activity.inactiveSince + showInactivePeriod;
    }, [nowMs, isConnected]);

    const adjustments = useMemo(() => {
        return {
            filter: presenceIsFresh,
            sort: userSortByActivity, // NB: splits ties on inactive time
        };
    }, [presenceIsFresh]);

    const observerOverviews = useSelectorArgs(selectUsers, squadObservers, adjustments);

    useEffect(() => {
        if (observerOverviews.length === 0) return;

        const eldest = observerOverviews[observerOverviews.length - 1];
        // Not possible, but appease tsc.
        if (!eldest.activity || eldest.activity.active) return;

        setTarget(eldest.activity.inactiveSince + showInactivePeriod);
    }, [observerOverviews, setTarget]);

    return useShallowEqualsMemo(() => observerOverviews.map(uo => uo.id), [observerOverviews]);
}

// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file clients/clients.proto (package clients, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ArchiveBondRequest, ArchiveBondResponse, FollowBondRequest, FollowBondResponse, GetBackendVersionRequest, GetBackendVersionResponse, GetCallAccessTokenRequest, GetCallAccessTokenResponse, GetContactsBookRequest, GetContactsBookResponse, GetShareableBondInviteLinkRequest, GetShareableBondInviteLinkResponse, InviteUserToBondRequest, InviteUserToBondResponse, InviteUserToBondViaEmailRequest, InviteUserToBondViaEmailResponse, ObserveBondRequest, ObserveBondResponse, ObserveBondsPreviewsRequest, ObserveBondsPreviewsResponse, RedeemBondInviteCodeRequest, RedeemBondInviteCodeResponse, ReportApnsTokenRequest, ReportApnsTokenResponse, ReportBrowserFocusRequest, ReportBrowserFocusResponse, ReportFirebaseTokenRequest, ReportFirebaseTokenResponse, ReportTypingActivityRequest, ReportTypingActivityResponse, SubOrgOverviewsRequest, SubOrgOverviewsResponse, WhoAmIRequest, WhoAmIResponse } from "./clients_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service clients.ClientService
 */
export const ClientService = {
  typeName: "clients.ClientService",
  methods: {
    /**
     * GetBackendVersion is called by the frontend to get the version of the backend.
     *
     * @generated from rpc clients.ClientService.GetBackendVersion
     */
    getBackendVersion: {
      name: "GetBackendVersion",
      I: GetBackendVersionRequest,
      O: GetBackendVersionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * FollowBond will be called when a user wants to follow or unfollow a bond.
     *
     * @generated from rpc clients.ClientService.FollowBond
     */
    followBond: {
      name: "FollowBond",
      I: FollowBondRequest,
      O: FollowBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ArchiveBond will be called when a user wants to archive or unarchive a bond.
     *
     * @generated from rpc clients.ClientService.ArchiveBond
     */
    archiveBond: {
      name: "ArchiveBond",
      I: ArchiveBondRequest,
      O: ArchiveBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetContactsBook will be called when the user wants to see all users that they can invite to a bond.
     *
     * @generated from rpc clients.ClientService.GetContactsBook
     */
    getContactsBook: {
      name: "GetContactsBook",
      I: GetContactsBookRequest,
      O: GetContactsBookResponse,
      kind: MethodKind.Unary,
    },
    /**
     * InviteUserToBond will be called when a user wants to invite another user to a bond.
     *
     * @generated from rpc clients.ClientService.InviteUserToBond
     */
    inviteUserToBond: {
      name: "InviteUserToBond",
      I: InviteUserToBondRequest,
      O: InviteUserToBondResponse,
      kind: MethodKind.Unary,
    },
    /**
     * InviteUserToBondViaEmail will be called when a user wants to invite somebody to a bond via email address.
     *
     * @generated from rpc clients.ClientService.InviteUserToBondViaEmail
     */
    inviteUserToBondViaEmail: {
      name: "InviteUserToBondViaEmail",
      I: InviteUserToBondViaEmailRequest,
      O: InviteUserToBondViaEmailResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetShareableBondInviteLink gets or creates a shareable link for inviting users to a bond.
     *
     * @generated from rpc clients.ClientService.GetShareableBondInviteLink
     */
    getShareableBondInviteLink: {
      name: "GetShareableBondInviteLink",
      I: GetShareableBondInviteLinkRequest,
      O: GetShareableBondInviteLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RedeemBondInviteCode will be called when a user wants to join a bond using an opaque invite code.
     *
     * @generated from rpc clients.ClientService.RedeemBondInviteCode
     */
    redeemBondInviteCode: {
      name: "RedeemBondInviteCode",
      I: RedeemBondInviteCodeRequest,
      O: RedeemBondInviteCodeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCallAccessToken gets an access token for the current device when joining a call.
     * TODO: get the Device ID from the frontend.
     *
     * @generated from rpc clients.ClientService.GetCallAccessToken
     */
    getCallAccessToken: {
      name: "GetCallAccessToken",
      I: GetCallAccessTokenRequest,
      O: GetCallAccessTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ReportFirebaseToken will store the specified token in the database.
     *
     * @generated from rpc clients.ClientService.ReportFirebaseToken
     */
    reportFirebaseToken: {
      name: "ReportFirebaseToken",
      I: ReportFirebaseTokenRequest,
      O: ReportFirebaseTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ReportApnsToken will store the specified token in the database.
     *
     * @generated from rpc clients.ClientService.ReportApnsToken
     */
    reportApnsToken: {
      name: "ReportApnsToken",
      I: ReportApnsTokenRequest,
      O: ReportApnsTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ReportBrowserFocus be streamed while the user is focused on the browser.
     *
     * @generated from rpc clients.ClientService.ReportBrowserFocus
     */
    reportBrowserFocus: {
      name: "ReportBrowserFocus",
      I: ReportBrowserFocusRequest,
      O: ReportBrowserFocusResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * ReportTypingActivity will be streamed while the user is actively typing.
     *
     * @generated from rpc clients.ClientService.ReportTypingActivity
     */
    reportTypingActivity: {
      name: "ReportTypingActivity",
      I: ReportTypingActivityRequest,
      O: ReportTypingActivityResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * WhoAmI will be called when the frontend doesn't know its connection's user ID.
     *
     * @generated from rpc clients.ClientService.WhoAmI
     */
    whoAmI: {
      name: "WhoAmI",
      I: WhoAmIRequest,
      O: WhoAmIResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ObserveBond will be called when the frontend is observing one bond.
     * If dont_observe is set, the bond will be subscribed to without registering observation.
     *
     * @generated from rpc clients.ClientService.ObserveBond
     */
    observeBond: {
      name: "ObserveBond",
      I: ObserveBondRequest,
      O: ObserveBondResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * ObserveBondsList will be called when the frontend is observing the list of bonds.
     *
     * @generated from rpc clients.ClientService.ObserveBondsPreviews
     */
    observeBondsPreviews: {
      name: "ObserveBondsPreviews",
      I: ObserveBondsPreviewsRequest,
      O: ObserveBondsPreviewsResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * SubOrgOverviews will be called when the frontend wants to maintain a list of org overviews.
     *
     * @generated from rpc clients.ClientService.SubOrgOverviews
     * @deprecated
     */
    subOrgOverviews: {
      name: "SubOrgOverviews",
      I: SubOrgOverviewsRequest,
      O: SubOrgOverviewsResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
};


import {
    isAsyncThunkAction,
    isFulfilled,
    isRejected,
    isRejectedWithValue,
    nanoid,
    type AsyncThunk,
    type AsyncThunkOptions,
    type AsyncThunkPayloadCreator,
    type PayloadAction,
} from "@reduxjs/toolkit";

import log from "../misc/log";
import { promiseWithResolvers, type PromiseWithResolvers } from "../misc/promises";
import { Optional } from "../misc/types";
import {
    createLocalTag,
    getActionProxyTag,
    tagLocalThunk,
    tagProxyThunk,
} from "../store/locations";
import { getDispatchChannel, ProxiedMessageDirection, type ProxiedMessage } from "../store/proxy";
import { createAppAsyncThunk, selectCurrentUserId, selectStoreConfig } from "../store/redux";
import type { AppAsyncThunkConfig, AppStore } from "../store/types";

/** A registry for thunk constructors, indexed on their stringy `typePrefix`.
 *
 * This is needed to construct the thunk on the overseer, based only on the
 * serialised data passed through the `BroadcastChannel`.
 */
export const proxiedThunkRegistry: Map<string, any> = new Map();
/** A registry for outstanding requests to the overseer. This allows the slave
 * to `await` the result of the proxied thunk in the normal way, despite the
 * work done in the inner thunk having been effected on the overseer.
 *
 * A promise is stored here for every thunk to be proxied to the overseer.
 * It is indexed on the `BOND_PROXIED_ACTION` tag attached to the data passed
 * into the `BroadcastChannel`. When any broadcast is received on the
 * `BroadcastChannel`, its `BOND_PROXIED_ACTION` tag is checked for a matching
 * entry here, and if found it will resolve the promise.
 */
const promiseRegistry: Map<string, PromiseWithResolvers<any>> = new Map();

// Helper provided by redux, but it's a HOF, so store an instance of it here.
const isThunkAction = isAsyncThunkAction();

/** Try to find a promise from the registry for a given action.
 *
 * If found, the promise is removed from the registry.
 */
const getPromise = (action: PayloadAction<unknown>): Optional<PromiseWithResolvers<unknown>> => {
    const tag = getActionProxyTag(action);
    if (!tag) return;

    const promise = promiseRegistry.get(tag);
    if (!promise) return;

    promiseRegistry.delete(tag);

    return promise;
};

/** Resolve an action that has been broadcast by the overseer.
 *
 * That means:
 * - for POJO actions, just dispatch it to the local store
 * - for thunks, check if we have a matching promise in the registry
 * -- if we don't, dispatch it anyway, as it may affect the contents of our
 *    local store
 * -- if we do, resolve the promise, which resumes the local thunk and
 *    will ultimately cause a `fulfilled`/`rejected` dispatch to our local store
 */
export const dispatchThunkResponse = (store: AppStore, action: PayloadAction<unknown>) => {
    if (!isThunkAction(action)) return store.dispatch(action);

    const promise = getPromise(action);
    if (!promise) return store.dispatch(action);

    promise.resolve(action);
};

/** Define a thunk action that will be run on the overseer.
 *
 * This works in both contexts: on a slave, or on the overseer directly.
 *
 * @param typePrefix an identifying string for the thunk
 * @param payloadCreator the function to run on the overseer
 * @param options options for the thunk (see `AsyncThunkOptions`)
 * @return a function to use to dispatch the thunk
 */
export const createProxiedAsyncThunk = <
    Returned,
    ThunkArg extends (object | void) = object,
    ThunkApiConfig extends AsyncThunkOptions<ThunkArg, AppAsyncThunkConfig> = AsyncThunkOptions<
        ThunkArg,
        AppAsyncThunkConfig
    >,
>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<
        Returned,
        ThunkArg,
        AppAsyncThunkConfig
    >,
    options?: ThunkApiConfig,
): AsyncThunk<Returned, ThunkArg, AppAsyncThunkConfig> => {
    const wrappedPayloadCreator: AsyncThunkPayloadCreator<
        Returned,
        ThunkArg,
        AppAsyncThunkConfig
    > = (
        arg: ThunkArg,
        thunkAPI,
    ) => {
        const state = thunkAPI.getState();

        const { broadcast, manageConnection } = selectStoreConfig(state);

        if (manageConnection) {
            // We are allowed to run the payload directly in this context.
            const r = payloadCreator(arg, thunkAPI);
            return (broadcast ? tagProxyThunk : tagLocalThunk)(r);
        }

        // We can't attach a `BOND_PROXIED_ACTION` tag to an object that has
        // been frozen, so take a copy here.
        if (Object.isFrozen(arg)) {
            arg = { ...arg };
        }

        // `userId !== undefined` is checked by thunk predicate
        const userId = selectCurrentUserId(state)!;

        const id = nanoid();
        const msg: ProxiedMessage = {
            direction: ProxiedMessageDirection.Request,
            thunk: { typePrefix, arg: tagProxyThunk(arg, id) },
        };

        const resultPromise = promiseWithResolvers<PayloadAction<Returned>>();
        promiseRegistry.set(id, resultPromise);

        log.info(`Proxying ${typePrefix} (${id})`);

        getDispatchChannel(userId).postMessage(msg);

        // TODO: we should really wait to ensure that the store has updated
        // (i.e. all the listeners have completed) before returning here.
        // It might be that redux guarantees listeners are called in the same
        // order as they subscribe.
        return resultPromise.promise.then(action => {
            if (isFulfilled(action)) return action.payload;
            else if (isRejectedWithValue(action)) return thunkAPI.rejectWithValue(action.payload);
            else if (isRejected(action)) throw action.error;
            else return thunkAPI.rejectWithValue({ error: "unexpected action", action: action });
        });
    };

    const thunk = createAppAsyncThunk<Returned, ThunkArg>(
        typePrefix,
        wrappedPayloadCreator,
        {
            ...options,
            condition(arg, api) {
                const state = api.getState();

                const { proxy } = selectStoreConfig(state);
                const userId = selectCurrentUserId(state);

                if (proxy && !userId) return false;

                return options?.condition?.(arg, api) || true;
            },
            // Pending thunk actions must *never* be proxied or broadcast.
            getPendingMeta: ({ requestId }, _api) => createLocalTag(requestId),
        },
    );

    if (proxiedThunkRegistry.get(typePrefix)) {
        log.error(`Duplicate async thunk prefix: ${typePrefix}`);
    }
    proxiedThunkRegistry.set(typePrefix, thunk);

    return thunk;
};

import { nanoid } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import queue from "@/ds/queue";
import { initSentry } from "@/misc/sentry";
import { isSharedWorker, isWorkerContext, selfIsSharedWorker } from "@/workers/util";
import { logSrcStr } from "./impl";

// These declarations are missing from typescript's type definitions
declare global {
    interface FileSystemDirectoryHandle extends FileSystemHandle {
        [Symbol.asyncIterator](): AsyncIterableIterator<[string, FileSystemHandle]>;
        entries(): AsyncIterableIterator<[string, FileSystemHandle]>;
        keys(): AsyncIterableIterator<string>;
        values(): AsyncIterableIterator<FileSystemHandle>;
    }
}

export const logArchiveWorkerConfig = {
    directoryName: "logs-beyond",
    maxFileSizeBytes: 1 * 1024 * 1024,
    maxNumFiles: 5,
};

async function getLogDirectory(create: boolean) {
    const root = await navigator.storage.getDirectory();
    return await root.getDirectoryHandle(logArchiveWorkerConfig.directoryName, {
        create: create,
    });
}

async function getLogFiles() {
    interface FileModificationInfo {
        name: string;
        lastModified: number;
    }

    try {
        const logDirectory = await getLogDirectory(false);
        const logFiles: FileModificationInfo[] = [];
        const isFileHandle = (handle: FileSystemHandle): handle is FileSystemFileHandle =>
            handle.kind === "file";

        for await (const [name, handle] of logDirectory) {
            if (!isFileHandle(handle)) {
                continue;
            }
            const file = await handle.getFile();
            logFiles.push({ name: name, lastModified: file.lastModified });
        }

        logFiles.sort((a: FileModificationInfo, b: FileModificationInfo) => {
            return a.lastModified - b.lastModified;
        });

        return { files: logFiles.map(logFileInfo => logFileInfo.name), directory: logDirectory };
    }
    catch (e) {
        Sentry.captureException(e, { level: "error" });
        return { files: [], directory: null };
    }
}

const tokenRedacterRe = /"(access|id|refresh)_token": ?"([^"]+)"/gi;
const tokenRedacterFn = (_match: string, tokenType: string, _token: string) => {
    return `"${tokenType}_token": "[redacted]"`;
};

export async function getArchivedLogLines(): Promise<string[]> {
    try {
        const { files, directory } = await getLogFiles();
        if (!files || !directory) {
            return [];
        }
        const getFileContents = async (name: string): Promise<string | undefined> => {
            const logFileHandle = await directory.getFileHandle(name);
            if (!logFileHandle) return;
            const file = await logFileHandle.getFile();
            return await file.text();
        };
        const contentsPromises = files.map(getFileContents);
        const contents = await Promise.all(contentsPromises);
        return contents
            .join("")
            .split("\n")
            .map(l => l.replaceAll(tokenRedacterRe, tokenRedacterFn))
            .filter(l => l.length > 0);
    }
    catch (e) {
        console.error("Getting archived logs failed", e);
        Sentry.captureException(e, { level: "error" });
        return [];
    }
}

const removeOldFiles = async () => {
    const { files, directory } = await getLogFiles();
    const numToDelete = files.length - logArchiveWorkerConfig.maxNumFiles + 1;
    if (numToDelete <= 0) {
        return;
    }
    const logFilesToDelete = files.slice(0, numToDelete);
    await Promise.allSettled(logFilesToDelete.map(name => directory?.removeEntry(name)));
};

export class LogArchiveSharedWorkerContext {
    public fileAccessHandle: FileSystemWritableFileStream | undefined;
    writtenBytes: number = 0;
    sessionId = nanoid(5);

    async createNewLogFile() {
        if (this.fileAccessHandle) {
            await this.fileAccessHandle.close();
            this.fileAccessHandle = undefined;
            this.writtenBytes = 0;
        }

        try {
            await removeOldFiles();
        }
        catch (e) {
            console.error("Removing old log files failed", e);
        }

        const logDirectory = await getLogDirectory(true);
        const filename = `log_${new Date().valueOf()}_${this.sessionId}.txt`;
        const fileHandle = await logDirectory.getFileHandle(filename, { create: true });
        this.fileAccessHandle = await fileHandle.createWritable();
    }

    async write(logLine: any) {
        try {
            if (
                !this.fileAccessHandle ||
                this.writtenBytes >= logArchiveWorkerConfig.maxFileSizeBytes
            ) {
                await this.createNewLogFile();
            }
            const encoder = new TextEncoder();
            const encodedMessage = encoder.encode(logLine + "\n");
            await this.fileAccessHandle?.write(encodedMessage);
            this.writtenBytes += encodedMessage.length;
        }
        catch (e) {
            console.error("Writing log line to file failed", e);
        }
    }
}

export class LogArchiveDedicatedWorkerContext {
    public fileAccessHandle: FileSystemSyncAccessHandle | undefined;
    writtenBytes: number = 0;
    sessionId = nanoid(5);

    async createNewLogFile() {
        if (this.fileAccessHandle) {
            await this.fileAccessHandle.close();
            this.fileAccessHandle = undefined;
            this.writtenBytes = 0;
        }

        try {
            await removeOldFiles();
        }
        catch (e) {
            console.error("Removing old log files failed", e);
            Sentry.captureException(e, { level: "error" });
        }

        const logDirectory = await getLogDirectory(true);
        const filename = `log_${new Date().valueOf()}_${this.sessionId}.txt`;
        const fileHandle = await logDirectory.getFileHandle(filename, { create: true });
        this.fileAccessHandle = await fileHandle.createSyncAccessHandle();
    }

    async write(logLine: any) {
        try {
            if (
                !this.fileAccessHandle ||
                this.writtenBytes >= logArchiveWorkerConfig.maxFileSizeBytes
            ) {
                await this.createNewLogFile();
            }
            const encoder = new TextEncoder();
            const encodedMessage = encoder.encode(logLine + "\n");
            const written = this.fileAccessHandle?.write(encodedMessage, { at: this.writtenBytes });
            this.fileAccessHandle?.flush();
            this.writtenBytes += written ?? 0;
        }
        catch (e) {
            console.error("Writing log line to file failed", e);
            Sentry.captureException(e, { level: "error" });
        }
    }
}

type LogPostMessageType = {
    source?: string;
    logLine?: string;
};

if (isWorkerContext()) {
    const shared = isSharedWorker();

    const context = shared ? new LogArchiveSharedWorkerContext()
        : new LogArchiveDedicatedWorkerContext();

    const logMessageQueue = queue(async logLine => {
        await context.write(logLine);
    });

    const processEvent = ({ data }: MessageEvent<LogPostMessageType>) => {
        const { source, logLine } = data;
        if (source !== logSrcStr || !logLine) return;

        logMessageQueue(logLine);
    };

    if (selfIsSharedWorker(self)) {
        let sentryConfigured = false;

        self.onconnect = (event: MessageEvent<unknown>) => {
            console.log("New connection to log archive shared worker");
            // Ensure we have Sentry for capturing errors, but also make sure
            // it doesn't call back into log.ts!
            // We need check this immediately "onconnect" for the first
            // connection spawned, else we'll access global variables before
            // initialisation.
            if (!sentryConfigured) {
                initSentry({ internalLogsAsBreadcrumb: true });
                sentryConfigured = true;
            }

            const port = event.ports[0];

            port.onmessage = processEvent;
        };
    }
    else {
        self.onload = () => {
            console.log("Log archive web worker loaded");
            // "onload" is the correct place here to avoid accessing global variables too early.
            initSentry({ internalLogsAsBreadcrumb: true });
        };

        self.onmessage = processEvent;
    }
}

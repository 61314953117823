import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { SidebarLinks } from "../components/SidebarLinks";
import { UserInfoAndOrgSelector } from "../components/UserInfoAndOrgSelector";
import { VersionInfo } from "../components/VersionInfo";
import BondPrivacyDomain from "../components/gui/BondPrivacyDomain";
import LinkButton from "../components/gui/LinkButton";
import SensitiveText from "../components/gui/SensitiveText";
import { selectBondTitle } from "../features/bonds";
import { selectShowSidebar, showSidebar } from "../features/meta";
import { selectSquadById } from "../features/squads";
import useAddressParams from "../hooks/useAddressParams";
import useBooleanFeatureFlag from "../hooks/useBooleanFeatureFlag";
import useLocalDispatch from "../hooks/useLocalDispatch";
import { useNavigateBack } from "../hooks/useNavigateBack";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { isMobileBrowser } from "../misc/mobile";
import { mobilePathToViewName, MobileViewName } from "../misc/mobilePathToTab";
import { useAppSelector } from "../store/redux";

function DesktopTopbarSidebarToggle(): React.JSX.Element {
    const localDispatch = useLocalDispatch();
    const sidebarToggleEnabled = useAppSelector(selectShowSidebar);
    const toggleSidebar = useCallback(() => {
        localDispatch(showSidebar(!sidebarToggleEnabled));
    }, [localDispatch, sidebarToggleEnabled]);

    return (
        <button className="c-btn-menu" title="Show menu" onClick={toggleSidebar}>
            Menu
        </button>
    );
}

function DesktopTopbarViewInternal(): React.JSX.Element {
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const { pathname } = useLocation();
    const isDiscover = pathname === "/discover";
    const isNewBond = pathname === "/bond/new";

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleClearClick = () => {
        setInputValue("");
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };
    return (
        <>
            <DesktopTopbarSidebarToggle />

            {!isDiscover && (
                <>
                    <div className="c-bond-search c-bond-search--desktop">
                        <input
                            type="text"
                            placeholder="Search"
                            className="c-bond-search__input"
                            required
                            ref={inputRef}
                            value={inputValue}
                            onChange={handleInputChange}
                            id="search"
                        />
                        <label
                            className="c-bond-search__icon c-bond-search__icon--search"
                            htmlFor="search"
                        >
                            Search
                        </label>
                        <button
                            className="c-bond-search__clear"
                            disabled={inputValue.length === 0}
                            onClick={handleClearClick}
                        >
                            Clear
                        </button>
                    </div>
                    <LinkButton
                        to={"/bond/new"}
                        className="c-btn-add"
                        title="Create bond"
                        // TODO: remove this BODGE once proper in-bond/new-bond topbars are implemented
                        // Use "visibility: hidden" rather than removing the component so the flex layout doesn't change
                        style={{ visibility: isNewBond ? "hidden" : "unset" }}
                    >
                        Add
                    </LinkButton>
                </>
            )}

            {isDiscover && (
                <>
                    <header className="cp-header">
                        <h1 className="cp-header__title">Discover</h1>
                    </header>
                    <div />
                </>
            )}
        </>
    );
}

function DesktopTopbarView(): React.JSX.Element {
    const { bondId } = useAddressParams();
    const isBond = bondId !== undefined;

    return (
        <header className="cp-header cp-header--desktop">
            {!isBond && <DesktopTopbarViewInternal />}
            {isBond && <BondViewTopbar />}
        </header>
    );
}

function MobileTopbarViewInternal({ view }: { view: MobileViewName; }): React.JSX.Element {
    const { navigateBack } = useNavigateBack();
    const { squadId } = useAddressParams();
    const squad = useSelectorArgs(selectSquadById, squadId);

    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const asideRef = useRef<HTMLDivElement>(null);

    const toggleAside = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        setIsOpen(prevState => !prevState);
    }, []);

    const closeAside = useCallback((event: TouchEvent | MouseEvent) => {
        if (
            buttonRef.current &&
            !buttonRef.current.contains(event.target as Node) &&
            asideRef.current &&
            !asideRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("touchstart", closeAside);
            document.addEventListener("mousedown", closeAside);
            return () => {
                document.removeEventListener("touchstart", closeAside);
                document.removeEventListener("mousedown", closeAside);
            };
        }
    }, [isOpen, closeAside]);

    const title = {
        mybonds: "Inbox",
        mysquads: "Groups",
        bond: "",
        squad: (squad?.name || ""),
        discover: "Discover",
    }[view];

    const showMenuButton = view === "mybonds";
    const showBackButton = view === "squad";
    const showPlusButton = showMenuButton || view === "squad";

    return (
        <>
            {showMenuButton &&
                (
                    <div className="cp-header__menu-btn">
                        <button
                            className="c-btn-aside"
                            title={isOpen ? "Hide menu" : "Show menu"}
                            onClick={toggleAside}
                            ref={buttonRef}
                        >
                            {isOpen ? "Hide Menu" : "Menu"}
                        </button>
                        {isOpen && (
                            <div className="c-aside c-aside--visible" ref={asideRef}>
                                <SidebarLinks />
                                <VersionInfo />
                                <UserInfoAndOrgSelector />
                            </div>
                        )}
                    </div>
                )}

            {showBackButton &&
                (
                    <button className="c-btn-return" title="Return" onClick={() => navigateBack()}>
                        Return
                    </button>
                )}

            <h1 className="cp-header__title">{title}</h1>

            {showPlusButton &&
                (
                    <LinkButton to={"/bond/new"} className="c-btn-add" title="Create bond">
                        Add
                    </LinkButton>
                )}
        </>
    );
}

// This might be better rendered from within the BondView component, and have no header rendered
// in this code in this mode.
//
// It's quite a bit more involved: we want bond title, squad title, number of people, and presence
// bar. Indeed the presence bar is rendered by the BondView.
function BondViewTopbar(): React.JSX.Element {
    const { navigateBack } = useNavigateBack();
    const { bondId } = useAddressParams();
    const isMobile = isMobileBrowser();
    const bondTitle = useSelectorArgs(selectBondTitle, bondId);

    const showEmoji = useBooleanFeatureFlag("display-bond-emoji");

    const title = `${showEmoji && bondTitle.emoji ? bondTitle.emoji + " " : ""}${bondTitle.title}`;

    return (
        <>
            {!isMobile && <DesktopTopbarSidebarToggle />}
            {!isMobile ? (
                <div className="cp-header__main cp-header__main--desktop">
                    <button
                        className="c-btn-return c-btn-return--desktop"
                        onClick={navigateBack}
                        title="Return"
                    >
                        Return
                    </button>
                    <div className="c-bond-title c-bond-title--desktop">
                        <div className="c-bond-title__participants c-bond-title__participants--desktop">
                            <BondPrivacyDomain id={bondId} />
                        </div>
                        <div className="c-middot c-bond-title__separator">&#183;</div>
                        <h1 className="c-bond-title__title c-bond-title__title--desktop u-truncate-auto">
                            <SensitiveText>{title}</SensitiveText>
                        </h1>
                    </div>
                </div>
            ) : (
                <>
                    <button
                        className="c-btn-return"
                        onClick={navigateBack}
                        title="Return"
                    >
                        Return
                    </button>
                    <div className="c-bond-title">
                        <h1 className="c-bond-title__title u-truncate-auto">
                            <SensitiveText>{title}</SensitiveText>
                        </h1>
                        <div className="c-bond-title__participants">
                            <BondPrivacyDomain id={bondId} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

function MobileTopbarView(): React.JSX.Element {
    const { pathname } = useLocation();
    const view = mobilePathToViewName(pathname);
    const isBond = view === "bond";
    const className = classNames("cp-header", {
        "cp-header--groups": mobilePathToViewName(pathname) === "mysquads",
    });

    return (
        <header className={className}>
            {(!isBond) && <MobileTopbarViewInternal view={mobilePathToViewName(pathname)} />}
            {isBond && <BondViewTopbar />}
        </header>
    );
}

// Phase 3 top bar
export function TopbarView(): React.JSX.Element {
    return isMobileBrowser() ? <MobileTopbarView /> : <DesktopTopbarView />;
}

import { useNavigate } from "react-router-dom";
import { extractUUID } from "../../domain/domain";
import { useAppSelector } from "../../store/redux";
import { selectCurrentLiveBondId } from "../../features/bonds";
import { useCallback, useMemo } from "react";
import BondChatParticipants, { BondChatParticipantsLocation } from "../BondChatParticipants";
import CallControls, { CallControlsLocation } from "../gui/CallControls";
import Draggable, { StopClicksOnBackground } from "../Draggable";
import useRtcSessionContext from "../../hooks/rtc/useRtcSessionContext";
import { canOpenLiveView, orderParticipants } from "../../domain/rtc";
import { selectCurrentUserId } from "../../features/auth";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals";

interface MultitaskingMediaControlsProps {
}

export function MultitaskingMediaControls(
    _props: MultitaskingMediaControlsProps,
): React.JSX.Element {
    const navigate = useNavigate();

    const { leaveSession, isMultitasking, callParticipants } = useRtcSessionContext();
    const { orderedParticipants } = useShallowEqualsMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );

    const liveBondId = useAppSelector(selectCurrentLiveBondId);

    const returnToLiveBond = useCallback(() => {
        if (!liveBondId) return;
        navigate(`/bond/${extractUUID(liveBondId)}`);
    }, [navigate, liveBondId]);

    const currentUserId = useAppSelector(selectCurrentUserId);
    const canOpenLiveViewMemo = useMemo(
        () => canOpenLiveView(orderedParticipants, currentUserId),
        [orderedParticipants, currentUserId],
    );

    // Memoise components that depend on values pulled out of context above.
    const partipantsComponent = useMemo(() =>
        liveBondId && (
            <BondChatParticipants
                bondId={liveBondId}
                location={BondChatParticipantsLocation.MultitaskingControls}
                canOpenLiveView={canOpenLiveViewMemo}
                hideExpandButton={true}
            />
        ), [liveBondId, canOpenLiveViewMemo]);

    const leaveButton = useMemo(() => (
        <button
            className="c-btn-close-draggable"
            title="Close"
            onClick={leaveSession}
        >
            Close
        </button>
    ), [leaveSession]);

    // StopClicksOnBackground:
    // * Clicking on the participants should navigate back to the bond;
    // * clicking on the call controls should _not_.
    return useMemo(() => (
        <>
            {isMultitasking && liveBondId && (
                <Draggable className="c-call-control-dialog" onClick={returnToLiveBond}>
                    <StopClicksOnBackground>
                        {leaveButton}
                    </StopClicksOnBackground>
                    <div className="c-call-control-dialog__content">
                        {partipantsComponent}
                        <StopClicksOnBackground>
                            <CallControls
                                location={CallControlsLocation.Multitasking}
                            />
                        </StopClicksOnBackground>
                    </div>
                </Draggable>
            )}
        </>
    ), [isMultitasking, liveBondId, leaveButton, partipantsComponent, returnToLiveBond]);
}

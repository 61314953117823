import * as d from "../../domain/domain";
import { retrieveAvatarDownloadBlob, selectAvatarBlob, selectUser } from "../../features/users";
import { useAppDispatch } from "../../store/redux";
import { getGradientFromName } from "./colours";
import {
    getDominantPresenceMode,
    hasLivePresence,
    isLivePresence,
    isVisibleUserPresenceMode,
    PresenceMode,
    presenceModesFromTracks,
} from "../../domain/presence";
import {
    PersonalAvatar,
    getScreenShareText,
    getUserDisplayName,
    getUserNickname,
    getActivityRecencyString,
} from "../../domain/users";
import ParticipantTile, { ParticipantTileProps } from "./ParticipantTile";
import typingBubbleIcon from "../../../assets/icons/typing-bubble-icon.svg";
import speakingBubbleIcon from "../../../assets/icons/speaking-bubble-icon.svg";
import { credentialsValidWithin } from "../../domain/blobs";
import { ReactEventHandler, useCallback, useMemo } from "react";
import { isValidAvatarMimeType } from "../../api/users";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import { useConnectedEffect } from "../../hooks/useConnectedEffect";
import classNames from "classnames";
import { isTrackActive } from "../../domain/rtc";
import { selectOrg } from "../../features/squads";
import { useInterestedOrgs } from "../../hooks/interest/useInterest";

type AvatarSize =
    | "card"
    | "card-desktop"
    | "default"
    | "message"
    | "presence"
    | "suggestion"
    | "upload";

interface AvatarContext {
    isScreenShare?: boolean;
    isMobile?: boolean;
    isBondCard?: boolean;
}

interface AvatarModifiers {
    observingBond?: boolean;
    contributed?: boolean;
    mentionedCurrentUser?: boolean;
    callParticipation?: ParticipantTileProps;
}

export interface IAvatarProps {
    userId: d.UserId;
    testId?: string;
    showPresence: boolean;
    size?: AvatarSize;
    hidden?: boolean;
    context?: AvatarContext;
    modifiers?: AvatarModifiers;
}

const getInitials = (name: string) =>
    name.trim()
        .split(" ")
        .map(word => String.fromCodePoint(word.codePointAt(0) || 0))
        .join("")
        .slice(0, 3);

const avatarBackground = (name: string, userId: d.UserId): string => {
    const unique = d.extractUUID(userId);

    const colorstr = getGradientFromName(name + unique);
    const initials = `${getInitials(name)}`;

    return `<svg xmlns='http://www.w3.org/2000/svg' height="100%" width="100%" class='c-avatar-fallback-name'><rect width='100%' height='100%' fill='${colorstr}'/><text x='50%' y='50%' dominant-baseline='central' text-anchor='middle'>${initials}</text></svg>`;
};

const getPresenceModeIcon = (mode: PresenceMode): string => {
    switch (mode) {
        case PresenceMode.Typing:
            return typingBubbleIcon;
        case PresenceMode.Speaking:
            return speakingBubbleIcon;
        case PresenceMode.Videoing:
            return "";
        case PresenceMode.ScreenSharing:
            return "";
        default:
            return "";
    }
};

export function AvatarImage(
    { avatar: { blobId }, altText, className = "c-human__image" }: {
        avatar: PersonalAvatar;
        altText?: string;
        className?: string;
    },
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const avatarBlob = useSelectorArgs(selectAvatarBlob, blobId);
    const credentials = avatarBlob?.credentials;

    useConnectedEffect(() => {
        if (blobId && !avatarBlob) {
            dispatch(retrieveAvatarDownloadBlob({ blobId }));
        }
    }, [dispatch, blobId, avatarBlob]);

    const errorHandler: ReactEventHandler<HTMLImageElement> = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();

        if (!blobId) {
            return;
        }

        if (credentials && credentialsValidWithin(credentials, 0)) {
            return;
        }

        dispatch(retrieveAvatarDownloadBlob({ blobId }));
    }, [dispatch, blobId, credentials]);

    if (!avatarBlob || !credentials || !isValidAvatarMimeType(avatarBlob.metadata.mimeType)) {
        return <></>;
    }

    return (
        <img
            className={className}
            alt={altText}
            src={credentials.url}
            onError={errorHandler}
        />
    );
}

export function AvatarFallback(
    { name, id, className, testId, empty }: {
        name: string;
        id: d.UserId;
        className?: string;
        testId?: string;
        empty?: boolean;
    },
): React.JSX.Element {
    const avatarHTML = !empty ? avatarBackground(name, id) : "";

    return (
        <figure
            className={className}
            data-testid={testId}
            dangerouslySetInnerHTML={{ __html: avatarHTML }}
        />
    );
}

export default function Avatar(
    props: IAvatarProps,
): React.JSX.Element {
    const user = useSelectorArgs(selectUser, props.userId);
    const org = useSelectorArgs(selectOrg, user?.orgId);
    useInterestedOrgs(user?.orgId);

    const showPresence = props.showPresence;
    const hidden = props.hidden || false;
    const size = props.size || "default";
    const isScreenShare = props.context?.isScreenShare || false;
    const isMobile = props.context?.isMobile || false;
    const inBondCard = props.context?.isBondCard || false;

    const innerTestId = user && (isScreenShare
        ? ("screenshare-" + user.id)
        : ("avatar-" + user.id));
    const testId = props.testId || innerTestId || "";

    const callParticipation = props.modifiers?.callParticipation;
    const isVideoOn = isTrackActive(callParticipation?.videoTrack);

    const presenceModes = useMemo(() => {
        if (!showPresence) {
            return [];
        }

        let modes = user?.activity?.presenceModes ?? [];
        if (callParticipation) {
            // For a user in a call, only use RTC stack as source of live presence info
            modes = modes.filter(mode => !isLivePresence(mode));

            modes.push(...presenceModesFromTracks(
                callParticipation.audioTrack,
                callParticipation.videoTrack,
                isScreenShare,
            ));
        }
        return modes;
    }, [user?.activity?.presenceModes, callParticipation, isScreenShare, showPresence]);

    // Show "sharing" icon iff isScreenShare.
    // Otherwise, show the most dominant visibile presence mode that the user has.
    const presenceForIcon = useMemo(() => (
        isScreenShare
            ? PresenceMode.ScreenSharing
            : getDominantPresenceMode(presenceModes.filter(isVisibleUserPresenceMode))
    ), [isScreenShare, presenceModes]);
    const presenceImageString = presenceForIcon ? getPresenceModeIcon(presenceForIcon) : "";

    if (hidden || !user) {
        // This empty div is used to align message content
        return <div className={`c-human-spacer`}></div>;
    }

    const hasLiveParticipation = !!callParticipation || hasLivePresence(presenceModes);
    const active = !!user.activity?.active || hasLiveParticipation;

    const nickname = getUserNickname(user);
    const displayName = getUserDisplayName(user);
    const orgName = org ? (org.personal ? "" : org.name) : "Unknown org";
    const presenceText = displayName +
        getActivityRecencyString(active ? undefined : user.activity);
    const userHoverText = (showPresence ? presenceText : displayName) +
        (orgName && ` (${orgName})`);
    const hoverText = isScreenShare ? getScreenShareText(nickname) : userHoverText;
    const avatarAltText = `${nickname}'s avatar`;

    const pictureValid = !!user?.picture;
    const userPicture = pictureValid ? user?.picture : undefined;

    // Formatting CSS classes:
    const figureClasses = classNames(`c-human`, `c-human--${size}`, {
        "c-human--offline": showPresence && !active,
        "c-human--card": inBondCard && isMobile,
        "c-human--card-desktop": inBondCard && !isMobile,
    });

    // TODO: this results in a figure inside a figure below. We should put the
    // participant tile alongside the backup avatarHTML once we figure out how
    // to not do this dangerously.
    const fallbackVisual = (
        <AvatarFallback
            className={"c-human__fallback"}
            id={user.id}
            name={user.name}
            testId={"fallback-" + testId}
        />
    );

    return (
        <figure
            className={figureClasses}
            title={hoverText}
            data-testid={testId}
        >
            {!isVideoOn && (
                userPicture ? (
                    <AvatarImage
                        avatar={userPicture}
                        altText={avatarAltText}
                        className="c-human__avatar"
                    />
                ) : fallbackVisual
            )}
            {callParticipation && (
                <ParticipantTile
                    {...callParticipation}
                    videoTitle={false}
                />
            )}
            {presenceImageString && (
                <div
                    className="c-human__activity"
                    style={{
                        backgroundImage: `url("${presenceImageString}")`,
                    }}
                />
            )}
        </figure>
    );
}

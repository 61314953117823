import { useOutletContext } from "react-router-dom";
import { FeatureFlagged } from "../components/FeatureFlags";
import { useNavigateBack } from "../hooks/useNavigateBack";
import { useAppDispatch } from "../store/redux";
import { CloseButton } from "./buttons/Close";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { useCallback, useState } from "react";
import useDialogOpenRef from "../hooks/useDialogOpenRef";
import useDialogOutsideClick from "../hooks/useDialogOutsideClick";
import { BondChildRouteContext } from "../misc/bondChildRouteContext";
import {
    followBond,
    getShareableBondInviteLinkThunk,
    renameBondTitle,
    selectBondIsFollower,
    selectBondTitle,
} from "../features/bonds";
import log from "../misc/log";
import { createToast } from "../features/meta";

export default function ModifyBondModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigate, navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });
    const { bondId } = useOutletContext() as BondChildRouteContext;

    const bondTitle = useSelectorArgs(selectBondTitle, bondId);

    const [bondNameEntry, setBondNameEntry] = useState(bondTitle.title);

    const closeModal = useCallback(() => {
        log.debug("Closing modal");
        navigateBack();
    }, [navigateBack]);

    const showUserInvitationAction = useCallback(() => {
        navigate("../invite", { replace: true });
    }, [navigate]);

    const copyShareableLinkToClipboard = useCallback(async () => {
        if (!bondId) {
            return;
        }
        const link = await dispatch(
            getShareableBondInviteLinkThunk({ bondId }),
        ).unwrap().catch(() => {
            dispatch(createToast({
                message: "Failed to get link.",
                duration: { seconds: 3 },
            }));
            throw new Error("Failed to get link.");
        });
        await navigator.clipboard.writeText(link).catch(() => {
            throw new Error("Failed to copy invite link to clipboard.");
        });

        dispatch(createToast({
            message: "Link copied to clipboard",
            duration: { seconds: 3 },
        }));
    }, [dispatch, bondId]);

    const renameBond = useCallback((title: string) => {
        if (!bondId || !title) {
            return;
        }
        dispatch(
            renameBondTitle({
                bondId: bondId,
                title: title,
            }),
        );
    }, [dispatch, bondId]);

    const isFollowing = useSelectorArgs(selectBondIsFollower, bondId);

    const unfollowBond = useCallback(() => {
        if (!bondId || !isFollowing) {
            return;
        }
        dispatch(followBond({ bondId: bondId, follow: false }));
    }, [dispatch, bondId, isFollowing]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    return (
        <dialog
            className="c-dialog c-dialog--add-people"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="c-dialog__header c-dialog__header--centered">
                <CloseButton side="right" onClick={closeModal} />
            </header>

            <article className="c-dialog__content-wrapper">
                <div className="c-dialog__content c-dialog__content--has-scroll">
                    <div className="c-form-element-new">
                        <input
                            type="text"
                            value={bondNameEntry}
                            onChange={e => setBondNameEntry(e.target.value)}
                            onKeyDown={e => {
                                if (e.key === "Enter") {
                                    renameBond(bondNameEntry);
                                    closeModal();
                                }
                            }}
                            className="c-input-new c-input-new--solid c-input-new--full"
                        />
                    </div>

                    <div className="c-form-element-new u-m-tb-24">
                        <button
                            className="c-btn-solid c-btn-solid--copy-link"
                            onClick={copyShareableLinkToClipboard}
                        >
                            <div className="c-btn-solid__icon c-btn-solid__icon--link"></div>
                            Copy link
                        </button>
                    </div>

                    <div className="c-dialog-add-people">
                        <button
                            className="c-btn-add-people"
                            onClick={showUserInvitationAction}
                        >
                            <div className="c-btn-add-people__icon" />
                            Add people
                        </button>
                        {/* <div className="c-dialog-add-people__divider" /> */}
                        {/* TODO: Suggested users here? */}
                    </div>
                </div>
            </article>

            <div className="c-dialog__footer c-dialog__footer--new">
                <button
                    className="c-btn-solid c-btn-solid--leave"
                    onClick={unfollowBond}
                    disabled={!isFollowing}
                >
                    Leave bond
                </button>
            </div>
        </dialog>
    );
}

export function FlaggedModifyBondModal(): React.JSX.Element {
    return (
        <FeatureFlagged flag={"early-bond-invite-buttons"} match={true}>
            <ModifyBondModal />
        </FeatureFlagged>
    );
}

import { FC, useCallback, useMemo } from "react";

import {
    SuggestionContentProps,
    SuggestionDomain,
    SuggestionEntityAdapter,
} from "../components/SuggestionsList";
import { SquadId, UserId } from "../domain/domain";
import { OrgOverview, SquadOverview } from "../domain/squads";
import { UserOverview } from "../domain/users";
import { selectOrgs, selectSquads } from "../features/squads";
import { selectUsers } from "../features/users";
import { Optional } from "../misc/types";
import { isEmailAddress } from "../misc/utils";
import { useInterestedOrgs, useInterestedSquads, useInterestedUsers } from "./interest/useInterest";
import useSelectorArgs from "./useSelectorArgs";
import * as d from "../domain/domain";

type EmailOverview = string;

export type EmailSuggestionContent = FC<SuggestionContentProps<EmailOverview>>;
export type SquadSuggestionContent = FC<SuggestionContentProps<SquadOverview>>;
export type UserSuggestionContent = FC<SuggestionContentProps<UserOverview>>;

/**
 * General options for populating suggestion subdomains
 */
interface SubdomainOptions<E> {
    /**
     * Content component which will be used to render each suggestion in the subdomain
     */
    Content: FC<SuggestionContentProps<E>>;
    /**
     * Action callback which will be called for each suggestion when it is selected
     */
    action: (e: E) => void;
    /**
     * Optional predicate which can be used to highlight a suggestion row, overriding
     * the default behaviour (arrow key selection)
     */
    overrideHighlight?: (s: E) => boolean;
}

/**
 * Options for populating the email subdomain. If the text query matches an email address
 * format, a single email suggestion will be produced, otherwise the subdomain will be empty
 */
export interface EmailSubdomainOptions extends SubdomainOptions<EmailOverview> {
    query: string;
}

/**
 * Options for populating the squad subdomain. Interest will be registered in the given ids,
 * and the subdomain will be populated with the relevant squad overviews
 */
export interface SquadSubdomainOptions extends SubdomainOptions<SquadOverview> {
    ids: SquadId[];
}

/**
 * Options for populating the user subdomains. Interest will be registered in the given ids,
 * and two subdomains will be populated with the relevant user overviews: one which is
 * filterable, and one non-filterable
 */
export interface UserSubdomainOptions extends SubdomainOptions<UserOverview> {
    idsToFilter?: UserId[];
    idsNotToFilter?: UserId[];
}

/**
 * Create a new suggestion entity adapter for email suggestions
 */
const newEmailAdapter = (
    action: Optional<(e: EmailOverview) => void>,
    overrideHighlight: Optional<(s: EmailOverview) => boolean>,
): SuggestionEntityAdapter<EmailOverview> => ({
    id: e => e,
    sortKey: e => e,
    searchKey: e => e,
    action: e => () => action?.(e),
    overrideHighlight: e => overrideHighlight?.(e) ?? false,
});

/**
 * Create a new suggestion entity adapter for squad suggestions
 */
const newSquadAdapter = (
    action: Optional<(s: SquadOverview) => void>,
    overrideHighlight: Optional<(s: SquadOverview) => boolean>,
): SuggestionEntityAdapter<SquadOverview> => ({
    id: s => s.id,
    sortKey: s => s.name,
    searchKey: s => s.name,
    title: s => s.name,
    action: s => () => action?.(s),
    overrideHighlight: s => overrideHighlight?.(s) ?? false,
});

/**
 * Create a new suggestion entity adapter for user suggestions. The search key combines
 * both the nickname and name of the user so both are searchable
 */
const newUserAdapter = (
    action: Optional<(u: UserOverview) => void>,
    overrideHighlight: Optional<(u: UserOverview) => boolean>,
    getOrgName: (o: d.OrgId) => string,
): SuggestionEntityAdapter<UserOverview> => ({
    id: u => u.id,
    sortKey: u => u.name,
    searchKey: u => `${u.nickname} ${u.name}`.trim(),
    title: u => u.name + ` (${getOrgName(u.orgId)})`,
    action: u => () => action?.(u),
    overrideHighlight: u => overrideHighlight?.(u) ?? false,
});

function orgName(org: Optional<OrgOverview>): string {
    return org?.personal ? "Personal Account" : org?.name || "Unknown Org";
}

/**
 * Build a suggestion domain object for suggesting audience members based on the given options.
 * The hook is also responsible for registering interest in the audience members
 */
export const useAudienceSuggestionDomain = (
    options: {
        email?: EmailSubdomainOptions;
        squad?: SquadSubdomainOptions;
        user?: UserSubdomainOptions;
    },
): SuggestionDomain<[EmailOverview, SquadOverview, UserOverview, UserOverview]> => {
    useInterestedSquads(options.squad?.ids);
    useInterestedUsers(options.user?.idsToFilter);
    useInterestedUsers(options.user?.idsNotToFilter);

    const validEmails = useMemo(
        () => isEmailAddress(options.email?.query ?? "") ? [options.email!.query] : [],
        [options.email],
    );
    const squads = useSelectorArgs(selectSquads, options.squad?.ids ?? []);
    const usersToFilter = useSelectorArgs(selectUsers, options.user?.idsToFilter ?? []);
    const usersNotToFilter = useSelectorArgs(selectUsers, options.user?.idsNotToFilter ?? []);

    const orgIds = useMemo(() => {
        return [...usersNotToFilter, ...usersToFilter].map(u => u.orgId);
    }, [usersNotToFilter, usersToFilter]);
    const orgs = useSelectorArgs(selectOrgs, orgIds);
    useInterestedOrgs(orgIds);

    const getOrgName = useCallback(
        (orgId: d.OrgId) => orgName(orgs.find(o => o.id === orgId)),
        [orgs],
    );

    const emailAdapter = useMemo(
        () => newEmailAdapter(options.email?.action, options.email?.overrideHighlight),
        [options.email],
    );
    const squadAdapter = useMemo(
        () => newSquadAdapter(options.squad?.action, options.squad?.overrideHighlight),
        [options.squad],
    );
    const userAdapter = useMemo(
        () => newUserAdapter(options.user?.action, options.user?.overrideHighlight, getOrgName),
        [options.user, getOrgName],
    );

    return useMemo(() => [{
        entities: validEmails,
        key: "emails",
        adapter: emailAdapter,
        Content: options.email?.Content,
        classNameModifier: "email",
        filter: false,
    }, {
        entities: squads,
        key: "squads",
        adapter: squadAdapter,
        Content: options.squad?.Content,
        classNameModifier: "squad",
    }, {
        entities: usersToFilter,
        key: "usersToFilter",
        adapter: userAdapter,
        Content: options.user?.Content,
        classNameModifier: "human",
    }, {
        entities: usersNotToFilter,
        key: "usersNotToFilter",
        adapter: userAdapter,
        Content: options.user?.Content,
        classNameModifier: "human",
        filter: false,
    }], [
        options.email,
        options.squad,
        options.user,
        emailAdapter,
        squadAdapter,
        userAdapter,
        validEmails,
        squads,
        usersToFilter,
        usersNotToFilter,
    ]);
};

export default useAudienceSuggestionDomain;

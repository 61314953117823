import {
    type AsyncThunk,
    type AsyncThunkOptions,
    type AsyncThunkPayloadCreator,
} from "@reduxjs/toolkit";

import { createLocalTag, tagLocalThunk } from "../store/locations";
import { createAppAsyncThunk } from "../store/redux";
import type { AppAsyncThunkConfig } from "../store/types";

/** Define a thunk for local dispatch *only*.
 *
 * This works in both contexts: on a slave, or on the overseer directly.
 *
 * @param typePrefix an identifying string for the thunk
 * @param payloadCreator the function to dispatch against the local store
 * @param options options for the thunk (see `AsyncThunkOptions`)
 * @return a function to use to dispatch the thunk
 */
export const createLocalAsyncThunk = <
    Returned,
    ThunkArg = void,
    ThunkApiConfig extends AsyncThunkOptions<ThunkArg, AppAsyncThunkConfig> = AsyncThunkOptions<
        ThunkArg,
        AppAsyncThunkConfig
    >,
>(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<
        Returned,
        ThunkArg,
        AppAsyncThunkConfig
    >,
    options?: ThunkApiConfig,
): AsyncThunk<Returned, ThunkArg, AppAsyncThunkConfig> => {
    const wrappedPayloadCreator: AsyncThunkPayloadCreator<
        Returned,
        ThunkArg,
        AppAsyncThunkConfig
    > = (
        arg: ThunkArg,
        thunkAPI,
    ) => {
        const pc = payloadCreator(arg, thunkAPI);
        return tagLocalThunk(pc);
    };

    return createAppAsyncThunk<Returned, ThunkArg>(
        typePrefix,
        wrappedPayloadCreator,
        {
            ...options,
            // Pending thunk actions must *never* be proxied or broadcast.
            getPendingMeta: (base, api) => {
                const tag = createLocalTag(base.requestId);
                if (!options?.getPendingMeta) return tag;

                return {
                    ...options.getPendingMeta(base, api) ?? {},
                    ...tag,
                };
            },
        },
    );
};

// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=js+ts"
// @generated from file intel/intel.proto (package intel, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { SuggestBondsRequest, SuggestBondsResponse, SuggestBondTitleRequest, SuggestBondTitleResponse } from "./intel_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service intel.IntelService
 */
export const IntelService = {
  typeName: "intel.IntelService",
  methods: {
    /**
     * SuggestBonds returns a list of bonds that are related to the query text.
     * The size of the list is limited by the limit parameter.
     *
     * @generated from rpc intel.IntelService.SuggestBonds
     * @deprecated
     */
    suggestBonds: {
      name: "SuggestBonds",
      I: SuggestBondsRequest,
      O: SuggestBondsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc intel.IntelService.SuggestBondTitle
     */
    suggestBondTitle: {
      name: "SuggestBondTitle",
      I: SuggestBondTitleRequest,
      O: SuggestBondTitleResponse,
      kind: MethodKind.Unary,
    },
  }
};


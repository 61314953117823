import {
    DetailedHTMLProps,
    HTMLAttributes,
    MouseEventHandler,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Optional } from "../misc/types";
import classNames from "classnames";

export interface DraggableProps
    extends PropsWithChildren, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
{
    allowClicksOnDrag?: boolean;
}

function Draggable(props: DraggableProps): React.JSX.Element {
    const { allowClicksOnDrag, children, ...divProps } = props;

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const startPositionRef = useRef({ x: 0, y: 0 });

    const [isDragging, setIsDragging] = useState(false);
    const currentClickIsDrag = useRef<Optional<boolean>>();
    const dragRef = useRef<HTMLDivElement>(null);

    const className = useMemo(
        () => classNames(props.className, { "is-grabbed": isDragging }),
        [props.className, isDragging],
    );

    const onMouseDownInComponent: MouseEventHandler = useCallback(e => {
        currentClickIsDrag.current = false;
        startPositionRef.current = { x: e.pageX - position.x, y: e.pageY - position.y };
    }, [position]);

    const onMouseMoveInDocument = useCallback((e: MouseEvent) => {
        if (currentClickIsDrag.current !== undefined) {
            setIsDragging(true);
            currentClickIsDrag.current = true;
            setPosition({
                x: e.pageX - startPositionRef.current.x,
                y: e.pageY - startPositionRef.current.y,
            });
        }
    }, []);

    const endClick: MouseEventHandler = useCallback(e => {
        if (!allowClicksOnDrag && currentClickIsDrag.current) e.stopPropagation();

        currentClickIsDrag.current = undefined;
        startPositionRef.current = { x: 0, y: 0 };
        setIsDragging(false);
    }, [allowClicksOnDrag]);

    useEffect(() => {
        if (dragRef.current) {
            dragRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
        }
    }, [position]);

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMoveInDocument);
        return () => document.removeEventListener("mousemove", onMouseMoveInDocument);
    }, [onMouseMoveInDocument]);

    return (
        <div
            {...divProps}
            className={className}
            ref={dragRef}
            onMouseDown={onMouseDownInComponent}
            onClickCapture={endClick}
        >
            {children}
        </div>
    );
}

/** @function
 * Helper component to wrap around children of the Draggable component.
 *
 * If the parent (i.e Draggable) has an onClick, then wrap this around children
 * which have their own onClick if you don't want to compose the two onClicks.
 */
export function StopClicksOnBackground(props: PropsWithChildren): React.JSX.Element {
    const stopPropagation: React.MouseEventHandler = useCallback(e => {
        e.stopPropagation();
    }, []);
    return <div onClick={stopPropagation}>{props.children}</div>;
}

export default Draggable;

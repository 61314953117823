import { memo, useCallback } from "react";
import { MediaToggleControl, toMediaGroup } from "../../domain/mediaDevices";
import MediaDeviceControl, { MediaDeviceControlCompact } from "./MediaDeviceControl";
import { TypedEntries } from "../../misc/types";
import useRtcSessionContext from "../../hooks/rtc/useRtcSessionContext";
import classNames from "classnames";

export enum CallControlsLocation {
    Default,
    LiveBond,
    Multitasking,
}

export interface CallControlsProps {
    onLeave?: React.MouseEventHandler<HTMLButtonElement>;
    location: CallControlsLocation;
}

function CallControls(
    props: CallControlsProps,
): React.JSX.Element {
    const { location, onLeave } = props;

    const { mediaControls, isMultitasking } = useRtcSessionContext();

    // logical XOR:
    const livenessAppliesHere = isMultitasking === (location === CallControlsLocation.Multitasking);

    const className = classNames(`c-call-controls`, {
        "c-call__controls--fixed": location === CallControlsLocation.Default,
        "c-call-controls--no-options": location === CallControlsLocation.Multitasking,
    });

    const getMediaControlComponent = useCallback(
        ([kind, toggle]: MediaToggleControl) => {
            const key = kind;
            const props = {
                control: toggle,
                mediaGroup: toMediaGroup(kind),
            };
            return (
                (location === CallControlsLocation.Multitasking)
                    ? <MediaDeviceControlCompact key={key} {...props} />
                    : <MediaDeviceControl key={key} {...props} />
            );
        },
        [location],
    );

    return (
        <div className={className}>
            {livenessAppliesHere && mediaControls &&
                TypedEntries(mediaControls).map(getMediaControlComponent)}
            {onLeave && (
                <button
                    className="c-call-btn c-call-btn--leave"
                    onClick={onLeave}
                    title="Leave call"
                >
                    Leave
                </button>
            )}
        </div>
    );
}

// Explicitly memoise this component to stop unnecessarily re-rendering
const CallControlsMemoed = memo(CallControls);
export default CallControlsMemoed;

import { AsyncThunkOptions, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import * as d from "../domain/domain";
import type { AppAsyncThunkConfig, AppDispatch, RootState, StoreConfig } from "./types";
import { Optional } from "../misc/types";

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const createAppSelector = createSelector.withTypes<RootState>();

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

// Avoid import loops by defining these here.
export const selectCurrentUserId = (state: RootState): Optional<d.UserId> => state.auth.userId;
export const selectStoreConfig = (state: RootState): StoreConfig => state.meta.config;

export const thunkRequiresUserId: AsyncThunkOptions<any, AppAsyncThunkConfig> = Object.freeze({
    condition: (_: any, { getState }: { getState: () => RootState; }) => {
        const state = getState();
        return !!selectCurrentUserId(state);
    },
});

import { MouseEventHandler, useEffect, useRef, useState } from "react";
import PresenceHeader from "./PresenceHeader";
import { useAppSelector } from "../store/redux";
import { Phase3FilterOptions, selectPhase3Filter, setPhase3Filter } from "../features/filterPanel";
import useOutsideClick from "../hooks/useOutsideClick";
import useAddressParams from "../hooks/useAddressParams";
import useLocalDispatch from "../hooks/useLocalDispatch";
import classNames from "classnames";

const FilterButton = (
    { name, current, onClick }: {
        name: Phase3FilterOptions;
        current: Phase3FilterOptions;
        onClick: (nf: Phase3FilterOptions) => void;
    },
) => {
    const classes = classNames("c-filter-dropdown__option", {
        "is-selected": current === name,
    });
    return (
        <button
            className={classes}
            onClick={() => onClick(name)}
        >
            {name}
        </button>
    );
};

export function FilterBondsAndPresenceHeader(): React.JSX.Element {
    const localDispatch = useLocalDispatch();
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const toggleFilter: MouseEventHandler = () => setIsFilterVisible(z => !z);
    const handleFilterChange = (newFilter: Phase3FilterOptions) => {
        localDispatch(setPhase3Filter(newFilter));
        setIsFilterVisible(false);
    };
    const filter = useAppSelector(selectPhase3Filter);
    const isFilterEnabled = filter !== "all";
    const { squadId } = useAddressParams();
    const isSquadView = squadId !== undefined;

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [popoverRef] = useOutsideClick<HTMLDivElement>((target: EventTarget | null) => {
        if (buttonRef.current?.contains(target as Node)) return;
        setIsFilterVisible(false);
    });
    const options: Phase3FilterOptions[] = ["all", "unread"];
    if (!isSquadView) {
        options.push("dismissed");
    }

    useEffect(() => {
        if (isSquadView && filter === "dismissed") {
            // Dismissed is not a valid filter for squad view. So reset it.
            localDispatch(setPhase3Filter("all"));
        }
    }, [isSquadView, filter, localDispatch]);

    const buttonClasses = classNames("c-btn-filter", {
        "is-selected": isFilterEnabled,
    });
    const divClasses = classNames(
        "c-filter-dropdown__options",
        {
            "is-visible": isFilterVisible,
        },
    );

    return (
        <div className="c-bonds-presence-wrapper">
            <div className="c-filter-dropdown">
                <button
                    onClick={toggleFilter}
                    className={buttonClasses}
                    ref={buttonRef}
                    title={`Showing ${filter} bonds`}
                >
                    {filter}
                </button>

                <div
                    className={divClasses}
                    ref={popoverRef}
                >
                    {options.map(name => (
                        <FilterButton
                            name={name}
                            current={filter}
                            onClick={handleFilterChange}
                            key={name}
                        />
                    ))}
                </div>
            </div>
            <PresenceHeader />
        </div>
    );
}

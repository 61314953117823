import { ForwardedRef, forwardRef, useMemo } from "react";
import BeyondTheme from "../quill/themes/BeyondTheme";
import { RichTextEditorOps, RichTextEditorProps } from "./RichTextEditor";
import QuillWrapper, { QuillBinding } from "./gui/QuillWrapper";
import { isMobileBrowser } from "../misc/mobile";

export const QuillRichTextEditor = forwardRef((
    props: RichTextEditorProps,
    ref: ForwardedRef<RichTextEditorOps>,
): React.JSX.Element => {
    const {
        markupForInit,
        placeholder,
        tabIndex,
        onChange,
        onSelect,
        onSubmit,
        onEscape,
        onShiftEscape,
        onFocus,
        onBlur,
    } = props;

    const bindings = useMemo(() => {
        const b: Record<string, QuillBinding> = {
            shiftEscape: {
                key: "Escape",
                shiftKey: true,
                handler: onShiftEscape,
            },
            escape: {
                key: "Escape",
                handler: onEscape,
            },
        };

        // On mobile, we don't want "enter" to submit the message. The user should click on the
        // send icon instead.
        if (!isMobileBrowser()) {
            b["submit"] = {
                key: "Enter",
                handler: onSubmit,
            };
        }

        return b;
    }, [onSubmit, onShiftEscape, onEscape]);

    return (
        <QuillWrapper
            ref={ref}
            bindings={bindings}
            placeholder={placeholder}
            theme={BeyondTheme.NAME}
            maxLength={8000}
            markupForInit={markupForInit}
            tabIndex={tabIndex}
            onChange={onChange}
            onSelect={onSelect}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    );
});

export default QuillRichTextEditor;

import { createPromiseClient } from "@connectrpc/connect";
import { BondService } from "../../gen/proto/bonds/bonds_connect";
import {
    CreateBondFromChatMessageRequest,
    CreateBondFromChatMessageResponse,
} from "../../gen/proto/bonds/bonds_pb";
import { PrivacyLevel } from "../../gen/proto/domain/domain_pb";
import { AudienceMember, isSquadId } from "../domain/audience";
import { BondOverview } from "../domain/bonds";
import { getContent_Mentions } from "../domain/chatContent";
import * as d from "../domain/domain";
import { audienceOpForAdd, audienceOpForMention, filterMentions } from "../domain/mentions";
import { UnsentChatMessage } from "../domain/messages";
import { BondTitles } from "../domain/bonds";
import log from "../misc/log";
import { transport } from "./transport";
import {
    fromProtoBondId,
    fromProtoChannelId,
    pbAudienceOpSet,
    pbBlobId,
    pbOrgId,
    pbTimestamp,
    pbUserId,
} from "./util";

export const service = createPromiseClient(BondService, transport);
export default service;

export interface CreateBondFromMsgParams {
    senderId: d.UserId;
    orgId: d.OrgId;
    privacy?: PrivacyLevel;
    bondTitles?: BondTitles;
    audience: AudienceMember[];
    msg: UnsentChatMessage;
    officialAttachmentIds?: d.BlobId[];
}

// Returns a BondOverview that is vaguely the same as what we expect to be returned by the server.
// TODO: put this into the redux store while we wait for the server to respond and then update it
// when the server responds. This will allow the user to create the bond and then immediately see it in the UI.
export async function createBondFromMsg(params: CreateBondFromMsgParams): Promise<BondOverview> {
    const {
        senderId,
        orgId,
        privacy,
        bondTitles,
        audience,
        msg,
        officialAttachmentIds,
    } = params;

    const mentions = getContent_Mentions(msg.content) ?? [];
    const audienceOps = [
        ...audience.map(audienceOpForAdd),
        ...mentions.map(audienceOpForMention),
    ];

    // Eagerly extract squads from mentions for responsiveness in the UI
    const { squadIds: mentionedSquadIds } = filterMentions(mentions);
    const squadIds = [...mentionedSquadIds, ...audience.filter(isSquadId)];

    const calcPrivacy = privacy ??
        ((squadIds.length == 0) ? PrivacyLevel.PRIVATE : PrivacyLevel.OPEN);

    const req = new CreateBondFromChatMessageRequest({
        bond: {
            userId: pbUserId(senderId),
            orgId: pbOrgId(orgId),
            privacy: calcPrivacy,
            bondTitles: {
                aiGeneratedTitle: bondTitles?.aiGeneratedTitle ?? "",
                userSpecifiedTitle: bondTitles?.userSpecifiedTitle ?? "",
            },
        },
        message: {
            clientTxAt: pbTimestamp(msg.clientTxTs),
            content: JSON.stringify(msg.content),
            attachmentIds: officialAttachmentIds?.map(pbBlobId),
        },
        audienceOps: pbAudienceOpSet(audienceOps),
    });

    let resp: CreateBondFromChatMessageResponse;
    try {
        resp = await service.createBondFromChatMessage(req);
    }
    catch (e) {
        log.error(e);
        return Promise.reject(e);
    }

    return {
        id: fromProtoBondId(resp.response?.newBondId),
        channelId: fromProtoChannelId(resp.response?.newChannelId),
        orgId,
        squadIds,
        privacy: calcPrivacy,
        knowledge: {
            userSpecifiedTitle: bondTitles?.userSpecifiedTitle ?? "",
            aiGeneratedTitle: bondTitles?.aiGeneratedTitle ?? "",
            summary: "",
            detailedSummary: "",
            imageUrl: "",
        },
        contributors: [senderId],
        followers: [senderId], // Could add mentioned users here, except we need to know if they're external or not...
        externalUsers: [],

        lastActivityAt: msg.clientTxTs,
        maxSequenceNumber: 1,
        liveCallIds: [],
    };
}

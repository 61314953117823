import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { FilterScope } from "../features/filterPanel";
import { selectCurrentSquadIds } from "../features/squads";
import { useInterestedSquads } from "../hooks/interest/useInterest";
import useAddressParams from "../hooks/useAddressParams";
import useFreshSquadObservers from "../hooks/useFreshSquadObservers";
import { useAppSelector } from "../store/redux";
import Avatar from "./gui/Avatar";

function PresenceHeader(): React.JSX.Element {
    const pathSegments = useLocation().pathname.split("/");
    const viewLocation = pathSegments.length > 1 ? pathSegments[1] : "";
    const { squadId } = useAddressParams();

    const currentUserSquads = useAppSelector(selectCurrentSquadIds);
    const scope: FilterScope = useMemo(() => {
        if (squadId !== undefined) {
            return { squadId };
        }
        return "unknown";
    }, [squadId]);

    const squadsToSubObservers = useMemo(() => {
        if (scope == "unknown") {
            return currentUserSquads;
        }
        return [scope.squadId];
    }, [scope, currentUserSquads]);

    useInterestedSquads(squadsToSubObservers);
    const sortedSquadObservers = useFreshSquadObservers(
        squadsToSubObservers,
        scope,
    );

    // This logic is getting really nasty. Does it ever evaluate to false?
    const showPresenceHeader = viewLocation == "bond" ||
        viewLocation == "squad" ||
        (pathSegments[1] == "mobile" && pathSegments[2] == "tab" && pathSegments[3] == "mybonds");

    if (!showPresenceHeader) {
        return <></>;
    }

    return (
        <div className="c-bonds-presence">
            <div className="c-bonds-presence__humans">
                {sortedSquadObservers.map(userId => (
                    <Avatar
                        userId={userId}
                        key={userId}
                        showPresence={true}
                        size="presence"
                    />
                ))}
            </div>
        </div>
    );
}

export default PresenceHeader;

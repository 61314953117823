import { Action, createListenerMiddleware, TaskAbortError } from "@reduxjs/toolkit";

import type { AnySelector } from "../features/selectors";
import log from "../misc/log";
import type { AppDispatch, RootState } from "./types";

/** Create a single instance of the middleware.
 * Listeners add themselves by calling `startAppListening()` with their callback.
 */
export const appListenerMiddleware = createListenerMiddleware();
export type AppListenerMiddleware = typeof appListenerMiddleware;

export const startAppListening = (
    middleware: typeof appListenerMiddleware = appListenerMiddleware,
) => middleware.startListening.withTypes<RootState, AppDispatch>();

export const appAppendListener = startAppListening();

export type AppAppendListener = ReturnType<typeof startAppListening>;

type AppListenerArg = Parameters<AppAppendListener>[0];
type ListenerPredicate = AppListenerArg["predicate"];
export type ListenerAPI = Parameters<AppListenerArg["effect"]>[1];

export const statesDifferBySelectors =
    (...selectors: AnySelector<[]>[]): ListenerPredicate => (_, a, b) => {
        for (const s of selectors) {
            if (s(a) != s(b)) return true;
        }
        return false;
    };

export const startLongLivedListener = (
    predicate: ListenerPredicate,
    f: (api: ListenerAPI, state: RootState) => void,
    name: string,
    appendListener: AppAppendListener = appAppendListener,
) => {
    return appendListener(
        {
            predicate: () => true,
            effect: async (_action, api) => {
                // Don't spawn any further instances of this listener.
                // This one will keep running.
                api.unsubscribe();

                let previousState: RootState | undefined;

                try {
                    while (!api.signal.aborted) {
                        // If `f` calls any dispatches, we don't want to miss them.
                        // Hence we have to *synchronously* call `getState` and
                        // compare projected values here.
                        const currentState = api.getState();

                        if (
                            !previousState || predicate({} as Action, previousState, currentState)
                        ) {
                            f(api, currentState);
                            previousState = currentState;
                            continue;
                        }

                        // We could destructure the return value here but, the
                        // code is simpler if we don't...
                        await api.take(predicate);
                    }
                }
                catch (e) {
                    if (!(e instanceof TaskAbortError)) {
                        log.warn(`Unexpected middleware exception (${name})`, e);
                    }
                }

                // If we are aborted for some reason, start up again.
                log.info(`Listener ${name} restarting`);
                api.subscribe();
            },
        },
    );
};

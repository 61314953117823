import { ReactEventHandler, useCallback, useContext, useMemo } from "react";
import { useAppDispatch } from "../../store/redux";

import { OfficialAttachment, isOfficialAttachment } from "../../domain/attachments";
import { credentialsValidWithin } from "../../domain/blobs";
import * as d from "../../domain/domain";
import { getAttachmentBlobUrlThunk, selectAttachment } from "../../features/chats";
import useSelectorArgs from "../../hooks/useSelectorArgs";
import { Optional } from "../../misc/types";
import { useConnectedEffect } from "../../hooks/useConnectedEffect";
import { isInlinableImage } from "../../misc/attachments";
import useDownloadAttachment from "../../hooks/useDownloadAttachment";
import { useNavigate } from "react-router-dom";
import useAddressParams from "../../hooks/useAddressParams";
import log from "../../misc/log";
import { messageAttachmentLightboxRoute } from "./AttachmentLightbox";
import { MessagesViewContext } from "../../views/ChatView";

interface CommonAttachmentViewProps {
    attachment: OfficialAttachment;
    backendMsgId: Optional<d.MessageId>;
    anchorElementCreator?: () => HTMLAnchorElement;
    index: number;
}

type GenericAttachmentViewProps = CommonAttachmentViewProps;

function GenericAttachmentView(
    props: GenericAttachmentViewProps,
): React.JSX.Element {
    const downloadLinkContents = useDownloadAttachment(props);

    const { attachment: { metadata: { fileName } } } = props;

    return (
        <div className="c-message__attachment">
            <button
                className="c-btn-download-attachment"
                title={`Download '${fileName}'`}
                onClick={downloadLinkContents}
            >
                Download file
            </button>
        </div>
    );
}

type ImageAttachmentViewProps = Omit<CommonAttachmentViewProps, "anchorElementCreator">;

function ImageOption(props: ImageAttachmentViewProps): React.JSX.Element {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { bondId } = useAddressParams();

    const {
        attachment: { credentials, id: attachmentId, metadata: { fileName, dimensions } },
        backendMsgId,
    } = props;

    const clientDims = useContext(MessagesViewContext);
    const { clientWidth, clientHeight } = clientDims ?? document.documentElement;

    const { url } = credentials;

    const errorHandler: ReactEventHandler<HTMLImageElement> = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();

        if (!backendMsgId) return;

        if (credentialsValidWithin(credentials, 0)) return;

        dispatch(getAttachmentBlobUrlThunk({
            blobId: attachmentId,
            messageId: backendMsgId,
        }));
    }, [backendMsgId, credentials, dispatch, attachmentId]);

    const navigateToLightbox = useCallback(() => {
        if (!bondId) {
            log.error("BondId missing when attempting lightbox open navigation.");
            return;
        }

        if (!backendMsgId) {
            log.error("MessageId missing when attempting lightbox open navigation.");
            return;
        }

        navigate(messageAttachmentLightboxRoute(bondId, backendMsgId, props.index));
    }, [bondId, backendMsgId, navigate, props.index]);

    const [imgWidth, imgHeight] = useMemo(() => {
        // Viewport size is passed down in a context; but if that fails fall back to the
        // document size (possibly needed on first render)
        const vw = clientWidth, vh = clientHeight;

        // Try and come up with some sane maximum dimensions. This is a pretty horrible
        // heuristic where we're trying to juggle sensible fixed sizing that won't break
        // the CSS layout. In an ideal world, we'd do all this sizing in CSS, but we
        // need to fix the sizing for layout purposes before images load.
        const maxHeight = Math.min(vh / 2, 480);
        const maxWidth = Math.min(vw * 4 / 5, 480 * 16 / 9, vw - 200);

        const origHeight = dimensions?.height || 0;
        const origWidth = dimensions?.width || 0;

        // If we fit in the bounding box, no need to scale
        if (origHeight <= maxHeight && origWidth <= maxWidth) {
            return [origWidth, origHeight];
        }

        let height = origHeight;
        let width = origWidth;

        if (height > maxHeight) {
            const scale = maxHeight / height;
            height = maxHeight;
            width = width * scale;
        }
        if (width > maxWidth) {
            const scale = maxWidth / width;
            width = maxWidth;
            height = height * scale;
        }

        // Turn floating point to integer
        width = width >> 0;
        height = height >> 0;

        return [width, height];
    }, [dimensions?.height, dimensions?.width, clientWidth, clientHeight]);

    return (
        <figure className="c-message__attachment">
            <div
                className="c-attachment"
                onClick={navigateToLightbox}
                style={{ height: imgHeight, width: imgWidth }}
            >
                <div className="c-attachment-embiggen-icon" title="View large version" />
                <img
                    className="c-attachment__image"
                    src={url}
                    title={fileName}
                    onError={errorHandler}
                    width={dimensions?.width}
                    height={dimensions?.height}
                    loading="lazy"
                />
            </div>
        </figure>
    );
}

type OfficialAttachmentViewProps = CommonAttachmentViewProps;

function OfficialAttachmentView(
    props: OfficialAttachmentViewProps,
): React.JSX.Element {
    const dispatch = useAppDispatch();

    const {
        attachment: { id, credentials: { url }, metadata: { mimeType, dimensions } },
        backendMsgId,
    } = props;

    // If there is no SAS URL, ask the backend for one.
    useConnectedEffect(() => {
        if (!url && backendMsgId && id) {
            dispatch(getAttachmentBlobUrlThunk({
                blobId: id,
                messageId: backendMsgId,
            }));
        }
    }, [id, dispatch, backendMsgId, url]);

    if (isInlinableImage(mimeType) && dimensions) {
        return <ImageOption {...props} />;
    }

    return <GenericAttachmentView {...props} />;
}

interface AttachmentViewProps {
    attachmentId: d.BlobId | d.LocalAttachmentId;
    anchorElementCreator?: () => HTMLAnchorElement;
    msgId: d.AttachmentMessageId;
    index: number;
}

export default function AttachmentView(
    { attachmentId, msgId, ...props }: AttachmentViewProps,
): React.JSX.Element {
    const attachment = useSelectorArgs(selectAttachment, attachmentId);
    const backendMsgId = d.backendMessageId(msgId);

    if (!isOfficialAttachment(attachment)) {
        // TODO: fix this up once(/if) we allow async upload
        return <></>;
    }

    return (
        <OfficialAttachmentView
            attachment={attachment}
            backendMsgId={backendMsgId}
            {...props}
        />
    );
}

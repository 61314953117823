import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { generatePasscode, getOrCreateUser } from "../api/auth";
import log from "../misc/log";
import { isDevEnv } from "../misc/environment";

export default function EmailView(): React.JSX.Element {
    const navigate = useNavigate();
    const [params, _] = useSearchParams();
    const callbackUrl = params.get("callback_url");
    const authReqId = params.get("auth_request_id");
    const passcodeUrl = `/login/passcode?callback_url=${callbackUrl}&auth_request_id=${authReqId}`;

    // TODO: email validation
    const [isFailed, setIsFailed] = useState(false);
    const [userEmail, setUserEmail] = useState("");

    // Autofill previous user ID
    useEffect(() => {
        const previousUser = localStorage.getItem("x-beyond-temp-userid");
        if (previousUser) {
            setUserEmail(previousUser);
        }
    }, []);
    const saveDevUser = (newUserId: string) => {
        if (isDevEnv && newUserId) {
            localStorage.setItem("x-beyond-temp-userid", newUserId);
        }
    };

    const signIn = useCallback(async () => {
        try {
            if (!userEmail) {
                setIsFailed(true);
                return;
            }

            // Append '@example.com' to email if none provided (dev only)
            let email = userEmail;
            if (isDevEnv && !userEmail.includes("@")) {
                email = `${userEmail}@example.com`;
            }

            // Create user if not exists
            const userResp = await getOrCreateUser(email);
            if (!userResp.isSuccess) {
                log.error("Failed to maybe create user:", userResp.reason);
                setIsFailed(true);
                return;
            }
            const { userId, isNewUser } = userResp.response;

            saveDevUser(email);

            // Generate passcode for user
            const passcodeResp = await generatePasscode(userId);
            if (!passcodeResp.isSuccess) {
                log.error("Failed to generate passcode:", passcodeResp.reason);
                setIsFailed(true);
                return;
            }

            navigate(passcodeUrl, { replace: true, state: { isNewUser, userId, userEmail } });
        }
        catch (e) {
            log.error("Failed to sign in with email", e);
            setIsFailed(true);
        }
    }, [navigate, passcodeUrl, userEmail]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                signIn();
            }
        };
        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        };
    }, [signIn]);

    if (!callbackUrl || !authReqId) {
        log.error("No callback URL or auth request ID provided");
        return <Navigate to="/login" />;
    }

    return (
        <>
            <p>
                NB: you can enter e.g. 'aiden' or an 'aiden@example.com' email address (or sign up
                as a new user)
            </p>
            <div className="c-signin-wrapper">
                <fieldset className="c-signin">
                    {
                        <div className="c-signin__element c-signin__element--active">
                            <label htmlFor="userEmail" className="c-signin__label">Email</label>
                            <div className="c-signin__value">
                                <input
                                    type="text"
                                    id="userEmail"
                                    name="signin-input"
                                    placeholder="Enter your email"
                                    value={userEmail}
                                    autoComplete="off"
                                    maxLength={100}
                                    className="c-signin__input"
                                    onChange={e => setUserEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    }
                    <button
                        className="cp-btn cp-btn-signin"
                        title="Continue"
                        onClick={signIn}
                    >
                        Continue
                    </button>
                    {isFailed && (
                        <div className="c-signin__element c-signin__element--error">
                            <label className="c-signin__error">Failed to sign in</label>
                        </div>
                    )}
                </fieldset>
            </div>
        </>
    );
}

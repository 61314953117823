import { useCallback, useEffect } from "react";
import log from "../misc/log";
import { App } from "@capacitor/app";
import NativeNotificationManager from "./managers/NativeNotificationManager";

export default function Capacitor(): React.JSX.Element {
    const addListeners = useCallback(async () => {
        await App.addListener("resume", () => {
            log.info("Native app resumed");
        });

        await App.addListener("pause", () => {
            log.info("Native app suspended");
        });

        await App.addListener("appStateChange", state => {
            log.info("Native app state changed. Currently active: ", state.isActive);
        });
    }, []);

    useEffect(() => {
        addListeners()
            .catch(e => {
                log.error("An error occured while adding capacitor listeners", e);
            });
    }, [addListeners]);

    return <NativeNotificationManager />;
}

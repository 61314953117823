import { getEnvironmentConfig } from "../misc/environment";

const apiBaseUrl = `${getEnvironmentConfig().oidcAuthority}/api`;
const authApiBaseUrl = `${apiBaseUrl}/auth`;
const userApiBaseUrl = `${apiBaseUrl}/users`;

const defaultHeaders = {
    "Content-Type": "application/json",
};

type SuccessResponse<T> = undefined extends T ? { isSuccess: true; } : {
    isSuccess: true;
    response: T;
};
type BaseApiResponse<T> = SuccessResponse<T> | {
    isSuccess: false;
    reason: string;
};

type ApiResponse = BaseApiResponse<undefined>;

type GetUserByEmailResponse = BaseApiResponse<{
    userId: string;
}>;

export async function getUserByEmail(
    userEmail: string,
): Promise<GetUserByEmailResponse> {
    try {
        const response = await fetch(encodeURI(`${userApiBaseUrl}/email/${userEmail}`), {
            method: "GET",
            headers: defaultHeaders,
        });

        if (!response.ok) {
            return { isSuccess: false, reason: response.statusText };
        }

        const { id: userId } = await response.json();

        return { isSuccess: true, response: { userId } };
    }
    catch (err: any) {
        return { isSuccess: false, reason: err.message };
    }
}

type CreateUserResponse = BaseApiResponse<{
    userId: string;
}>;

export async function createUser(
    userEmail: string,
): Promise<CreateUserResponse> {
    try {
        const response = await fetch(userApiBaseUrl, {
            method: "POST",
            headers: defaultHeaders,
            body: JSON.stringify({ email: userEmail }),
        });

        if (!response.ok) {
            return { isSuccess: false, reason: response.statusText };
        }

        const { id: userId } = await response.json();

        return { isSuccess: true, response: { userId } };
    }
    catch (err: any) {
        return { isSuccess: false, reason: err.message };
    }
}

type GetOrCreateUserResponse = BaseApiResponse<{
    userId: string;
    isNewUser: boolean;
}>;

export async function getOrCreateUser(
    userEmail: string,
): Promise<GetOrCreateUserResponse> {
    const getUserResp = await getUserByEmail(userEmail);

    if (getUserResp.isSuccess) {
        return {
            isSuccess: true,
            response: { userId: getUserResp.response.userId, isNewUser: false },
        };
    }

    if (getUserResp.reason !== "Not Found") {
        return { isSuccess: false, reason: getUserResp.reason };
    }

    const createUserResp = await createUser(userEmail);

    if (!createUserResp.isSuccess) {
        return { isSuccess: false, reason: createUserResp.reason };
    }

    return {
        isSuccess: true,
        response: { userId: createUserResp.response.userId, isNewUser: true },
    };
}

export async function generatePasscode(
    userId: string,
): Promise<ApiResponse> {
    try {
        const response = await fetch(`${authApiBaseUrl}/passcode/generate`, {
            method: "POST",
            headers: defaultHeaders,
            body: JSON.stringify({ user_id: userId }),
        });

        if (!response.ok) {
            return { isSuccess: false, reason: response.statusText };
        }

        return { isSuccess: true };
    }
    catch (err: any) {
        return { isSuccess: false, reason: err.message };
    }
}

export async function verifyPasscode(
    authReqId: string,
    userId: string,
    passcode: string,
): Promise<ApiResponse> {
    try {
        const response = await fetch(`${authApiBaseUrl}/passcode/verify`, {
            method: "POST",
            headers: defaultHeaders,
            body: JSON.stringify({ auth_request_id: authReqId, passcode, user_id: userId }),
        });

        if (!response.ok) {
            return { isSuccess: false, reason: response.statusText };
        }

        return { isSuccess: true };
    }
    catch (err: any) {
        return { isSuccess: false, reason: err.message };
    }
}

export async function updateUserDetails(
    userId: string,
    username: string,
    displayName: string,
    nickname: string,
): Promise<ApiResponse> {
    try {
        const response = await fetch(`${userApiBaseUrl}/${userId}`, {
            method: "POST",
            headers: defaultHeaders,
            body: JSON.stringify({ username, nickname, display_name: displayName }),
        });

        if (!response.ok) {
            return { isSuccess: false, reason: response.statusText };
        }

        return { isSuccess: true };
    }
    catch (err: any) {
        return { isSuccess: false, reason: err.message };
    }
}

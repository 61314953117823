import * as squads_pb from "../../gen/proto/squads/squads_pb";
import * as devices_pb from "../../gen/proto/devices/devices_pb";
import { SquadService } from "../../gen/proto/squads/squads_connect";
import deviceService, { observersParser } from "./devices";
import { OrgOverview, SquadOverview } from "../domain/squads";
import { transport } from "./transport";
import { createPromiseClient } from "@connectrpc/connect";
import {
    fromProtoOrgId,
    fromProtoSquadId,
    fromProtoUserId,
    pbOrgId,
    pbPersonId,
    pbSquadId,
    pbUserId,
    toProtoSquadSet,
    toProtoOrgSet,
} from "./util";
import * as d from "../domain/domain";
import { streamHandler } from "./stream";
import { UserObservation } from "../domain/presence";
import { translateUserDefinition } from "./users";
import { UserDefinition } from "../domain/users";
import { Diff } from "../misc/types";
import { translateAsyncIterable } from "../misc/iterable";

export const squadsService = createPromiseClient(SquadService, transport);
export default squadsService;

function translateOrgOverview(org: squads_pb.OrgOverview): OrgOverview {
    return {
        id: fromProtoOrgId(org.id),
        name: org.name,
        personal: org.isPersonal,
    };
}

function translateSquadOverview(squad: squads_pb.SquadOverview): SquadOverview {
    return {
        id: fromProtoSquadId(squad.id),
        name: squad.name,
        userIds: squad.userIds?.ids?.map(fromProtoUserId) || [],
    };
}

function orgOverviewParser(res: squads_pb.SubOrgOverviewsResponse) {
    switch (res.orgOverviewOrDeleted?.case) {
        case "orgOverview":
            return translateOrgOverview(res.orgOverviewOrDeleted.value);
        case "deletedId":
            // TODO
            break;
        default:
            throw new Error(`unexpected orgOrDeleted case: ${res.orgOverviewOrDeleted?.case}`);
    }
}

function squadOverviewParser(res: squads_pb.SubSquadOverviewsResponse) {
    switch (res.squadOverviewOrDeleted?.case) {
        case "squadOverview":
            return translateSquadOverview(res.squadOverviewOrDeleted.value);
        case "deletedId":
            // TODO
            break;
        default:
            throw new Error(`unexpected squadOrDeleted case: ${res.squadOverviewOrDeleted?.case}`);
    }
}

interface CreateOrgArgs {
    name: string;
    initalPerson: d.PersonId;
}
export async function createOrg(args: CreateOrgArgs): Promise<d.OrgId> {
    const resp = await squadsService.createOrg(
        new squads_pb.CreateOrgRequest({
            name: args.name,
            initialPersonId: pbPersonId(args.initalPerson),
        }),
    );
    return fromProtoOrgId(resp.newOrgId);
}

export interface CreateUserArgs {
    orgId: d.OrgId;
    personId: d.PersonId;
}
export async function createUser(args: CreateUserArgs): Promise<void> {
    await squadsService.createUser(
        new squads_pb.CreateUserRequest({
            orgId: pbOrgId(args.orgId),
            personId: pbPersonId(args.personId),
        }),
    );
}

interface CreateSquadArgs {
    orgId: d.OrgId;
    name: string;
    initalUser: d.UserId;
}
export async function createSquad(args: CreateSquadArgs): Promise<d.SquadId> {
    const resp = await squadsService.createSquad(
        new squads_pb.CreateSquadRequest({
            orgId: pbOrgId(args.orgId),
            name: args.name,
            initialUserId: pbUserId(args.initalUser),
        }),
    );
    return fromProtoSquadId(resp.newSquadId);
}

export interface AddSquadMemberArgs {
    squadId: d.SquadId;
    userId: d.UserId;
}
export async function addSquadMember(args: AddSquadMemberArgs): Promise<void> {
    await squadsService.addSquadMember(
        new squads_pb.AddSquadMemberRequest({
            squadId: pbSquadId(args.squadId),
            userId: pbUserId(args.userId),
        }),
    );
}

async function* translateOrgOverviewDiff(v: Diff<d.OrgId>) {
    if (v.added?.length) {
        yield new squads_pb.SubOrgOverviewsRequest({
            orgIds: toProtoOrgSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubOrgOverviewsRequest({
            orgIds: toProtoOrgSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subOrgOverviews(
    reqStream: AsyncIterableIterator<Diff<d.OrgId>>,
    signal: AbortSignal,
) {
    const logPrefix = `subOrgOverviews`;

    const translation = translateAsyncIterable(reqStream, translateOrgOverviewDiff);

    const stream = squadsService.subOrgOverviews(translation, { signal });

    yield* streamHandler(stream, orgOverviewParser, logPrefix);
}

async function* translateSquadOverviewsDiff(v: Diff<d.SquadId>) {
    if (v.added?.length) {
        yield new squads_pb.SubSquadOverviewsRequest({
            squadIds: toProtoSquadSet(v.added),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubSquadOverviewsRequest({
            squadIds: toProtoSquadSet(v.removed),
            addToSub: false,
        });
    }
}

export async function* subSquadOverviews(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    signal: AbortSignal,
) {
    const logPrefix = "subSquadOverviews";

    const translation = translateAsyncIterable(reqStream, translateSquadOverviewsDiff);

    const stream = squadsService.subSquadOverviews(translation, { signal });

    yield* streamHandler(stream, squadOverviewParser, logPrefix);
}

export async function listUsersByPerson(
    personId: d.PersonId,
): Promise<Array<UserDefinition>> {
    const resp = await squadsService.listUsersByPerson(
        new squads_pb.ListUsersByPersonRequest({
            personId: pbPersonId(personId),
        }),
    );

    return resp.userDefinitions.map(translateUserDefinition) || [];
}

export interface ListPersonOrgsResponse {
    orgIds: Array<d.OrgId>;
}

export async function listPersonOrgs(
    personId: d.PersonId,
): Promise<ListPersonOrgsResponse> {
    const resp = await squadsService.listPersonOrgs(
        new squads_pb.ListPersonOrgsRequest({
            personId: pbPersonId(personId),
        }),
    );

    return {
        orgIds: resp.orgIds?.map(fromProtoOrgId) || [],
    };
}

export interface ListUserSquadsResponse {
    squadIDs: Array<d.SquadId>;
}

async function* translateSubUserSquadListsDiff(v: Diff<d.UserId>) {
    if (v.added?.length) {
        yield new squads_pb.SubUserSquadListsRequest({
            userIds: {
                ids: v.added.map(pbUserId),
            },
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new squads_pb.SubUserSquadListsRequest({
            userIds: { ids: v.removed.map(pbUserId) },
            addToSub: false,
        });
    }
}

export async function* subUserSquadLists(
    reqStream: AsyncIterableIterator<Diff<d.UserId>>,
    signal: AbortSignal,
): AsyncGenerator<d.SquadId[], void, unknown> {
    const logPrefix = `subUserSquadLists`;

    const translation = translateAsyncIterable(reqStream, translateSubUserSquadListsDiff);

    const resp = squadsService.subUserSquadLists(translation, { signal });

    const parse = (res: squads_pb.SubUserSquadListsResponse) =>
        res.squadIds?.map(fromProtoSquadId) || [];

    yield* streamHandler(resp, parse, logPrefix);
}

export type SquadObservations = {
    viewId: d.SquadId;
    observations: Array<UserObservation>;
};

function rmPath(path: string): string {
    const parts = path.split("/");
    return parts[0];
}

async function* translateSquadObserversDiff(v: Diff<d.SquadId>) {
    if (v.added?.length) {
        yield new devices_pb.SubObserversV2Request({
            viewUrns: v.added.map(sqid => `${sqid as string}/bondPreviews`),
            addToSub: true,
        });
    }
    if (v.removed?.length) {
        yield new devices_pb.SubObserversV2Request({
            viewUrns: v.removed.map(sqid => `${sqid as string}/bondPreviews`),
            addToSub: false,
        });
    }
}

export async function* subSquadObservers(
    reqStream: AsyncIterableIterator<Diff<d.SquadId>>,
    signal: AbortSignal,
): AsyncGenerator<SquadObservations, void, unknown> {
    const logPrefix = `subSquadObservers`;

    const translation = translateAsyncIterable(reqStream, translateSquadObserversDiff);

    const resp = deviceService.subObserversV2(translation, { signal });

    const urnParser = (urn: string) => d.parseSquadUrn(rmPath(urn));

    yield* streamHandler(resp, observersParser(urnParser), logPrefix);
}

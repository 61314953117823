import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Outlet } from "react-router-dom";

import BondChatParticipants from "../components/BondChatParticipants";
import { FeatureFlagged } from "../components/FeatureFlags";
import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { orderParticipants } from "../domain/rtc";
import { transferDraftThunk } from "../features/bondCreation";
import {
    clearSelectedBond,
    getShareableBondInviteLinkThunk,
    selectBondById,
    selectChannelIdByBondId,
    updateSelectedBond,
} from "../features/bonds";
import { closeLiveView, JoinedCallView, selectJoinedCallView } from "../features/calls";
import {
    PrivateOrSquadFilterOption,
    setPrivateOrSquadFilterThunk,
    setRelevanceLevelFilter,
} from "../features/filterPanel";
import { MetaInterestCounterKey, selectInterestInKey } from "../features/interest";
import { createToast } from "../features/meta";
import {
    useInterestedCall,
    useInterestedSquads,
    useInterestedVisibleBond,
} from "../hooks/interest/useInterest";
import useLocalDispatch from "../hooks/useLocalDispatch";
import usePrevious from "../hooks/usePrevious";
import useSelectorArgs from "../hooks/useSelectorArgs";
import { BondChildRouteContext } from "../misc/bondChildRouteContext";
import { isMobileBrowser } from "../misc/mobile";
import type { Focusable, Optional } from "../misc/types";
import { canOpenLiveView } from "../domain/rtc.ts";
import { selectCurrentUserId } from "../features/auth.ts";
import useRtcSessionContext from "../hooks/rtc/useRtcSessionContext.ts";
import { useAppDispatch, useAppSelector } from "../store/redux";
import ChatView from "./ChatView";
import { useNavigateBack } from "../hooks/useNavigateBack.ts";
import useAddressParams from "../hooks/useAddressParams.ts";
import { useShallowEqualsMemo } from "@/hooks/useShallowEquals.ts";

export default BondView;

function EarlyBondButtons({ bondId }: { bondId: d.BondId; }): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigate } = useNavigateBack();

    const showRenameBondModalAction = useCallback(() => {
        navigate("modify");
    }, [navigate]);

    const showUserInvitationAction = useCallback(() => {
        navigate("invite");
    }, [navigate]);

    const copyShareableLinkToClipboard = useCallback(async () => {
        if (!bondId) {
            return;
        }

        // Consider sensible offline behaviour for this button
        const link = await dispatch(
            getShareableBondInviteLinkThunk({ bondId }),
        ).unwrap().catch(
            () => {
                dispatch(createToast({
                    message: "Failed to get link.",
                    duration: { seconds: 3 },
                }));
                throw new Error("Failed to get link.");
            },
        );

        navigator.clipboard.writeText(link).catch(() => {
            throw new Error("Failed to copy invite link to clipboard.");
        });

        dispatch(createToast({
            message: "Link copied to clipboard",
            duration: { seconds: 3 },
        }));
    }, [dispatch, bondId]);

    const controlClasses = classNames("c-bond-controls", {
        "c-bond-controls--desktop": !isMobileBrowser(),
    });

    const btnClasses = classNames("c-btn-lineal", {
        "c-btn-lineal--desktop": !isMobileBrowser(),
    });

    return (
        <FeatureFlagged flag={"early-bond-invite-buttons"} match={true}>
            <div className={controlClasses}>
                <button
                    className={btnClasses}
                    title="Change name"
                    onClick={showRenameBondModalAction}
                >
                    <div className="c-btn-lineal__icon c-btn-lineal__icon--name"></div>
                    Change name
                </button>
                <button
                    className={btnClasses}
                    title="Add people"
                    onClick={showUserInvitationAction}
                >
                    <div className="c-btn-lineal__icon c-btn-lineal__icon--people"></div>
                    Add people
                </button>
                <button
                    className={btnClasses}
                    title="Add link"
                    onClick={copyShareableLinkToClipboard}
                >
                    <div className="c-btn-lineal__icon c-btn-lineal__icon--link"></div>
                    Copy link
                </button>
            </div>
        </FeatureFlagged>
    );
}

export function BondView(): React.JSX.Element {
    const { bondId, messageId } = useAddressParams();

    const dispatch = useAppDispatch();
    const localDispatch = useLocalDispatch();
    const { navigate, navigateBack } = useNavigateBack();

    const currentUserId = useAppSelector(selectCurrentUserId);

    const prevBondId = usePrevious(bondId);
    const bond = useSelectorArgs(selectBondById, bondId);

    const joinedCallView = useAppSelector(selectJoinedCallView);
    const isLiveView = joinedCallView === JoinedCallView.Live;

    const { callParticipants } = useRtcSessionContext();
    const { orderedParticipants } = useShallowEqualsMemo(
        () => orderParticipants(callParticipants),
        [callParticipants],
    );
    const canOpenLiveViewMemo = useMemo(
        () => canOpenLiveView(orderedParticipants, currentUserId),
        [orderedParticipants, currentUserId],
    );

    const chatRef = useRef<Focusable>(null);
    useEffect(() => {
        if (isMobileBrowser()) {
            return;
        }

        if (bondId != prevBondId) {
            chatRef.current?.focus({ goToEnd: true });
        }
    }, [bondId, prevBondId]);

    useEffect(() => {
        localDispatch(updateSelectedBond(bondId));
        return () => {
            localDispatch(clearSelectedBond());
        };
    }, [localDispatch, bondId]);

    useInterestedVisibleBond(bondId);
    useInterestedCall(bond?.liveCallIds[0]);
    useInterestedSquads(bond?.squadIds);

    const showBondInterestedAction = useCallback(() => {
        navigate("interested");
    }, [navigate]);

    const channelId = useAppSelector(selectChannelIdByBondId(bondId));

    // Escape key handler
    const navigateUp = useCallback(async () => {
        if (channelId) {
            await dispatch(transferDraftThunk({
                from: newChannelDraftTarget(channelId),
                to: bondCreationDraftTarget,
            }));
            navigateBack();
        }
        else {
            await dispatch(
                setPrivateOrSquadFilterThunk({
                    by: "option",
                    option: PrivateOrSquadFilterOption.ALL,
                }),
            );
            localDispatch(setRelevanceLevelFilter({ by: "all" }));
        }
    }, [navigateBack, dispatch, localDispatch, channelId]);

    const minimiseGrid = useCallback(() => localDispatch(closeLiveView()), [localDispatch]);

    // NB: both callbacks are memoised above, so goBack is stable when passed to the hook.
    const goBack = isLiveView ? minimiseGrid : navigateUp;

    const hotkeyBlockingInterest = useSelectorArgs(
        selectInterestInKey,
        MetaInterestCounterKey.BlockHotkey,
    );
    const isEscHotkeyBlocked = useCallback(() => hotkeyBlockingInterest, [hotkeyBlockingInterest]);
    useHotkeys("esc", goBack, {
        ignoreEventWhen: isEscHotkeyBlocked,
        enableOnFormTags: ["textarea"],
    });

    const shouldRedirect = !bondId || !bond;

    useEffect(() => {
        if (shouldRedirect) {
            const t = setTimeout(() => {
                navigate("/bond");
            }, 1 * 1000);

            return () => {
                clearTimeout(t);
            };
        }
    }, [navigate, shouldRedirect]);

    // Memoise the outlet context to avoid unnecessary rerenders
    const outletCtx: Optional<BondChildRouteContext> = useMemo(
        () => bondId ? { bondId } : undefined,
        [bondId],
    );

    // Memoised for (indirect) context dependency
    const participantsComponent = useMemo(() =>
        bondId && (
            <BondChatParticipants
                bondId={bondId}
                canOpenLiveView={canOpenLiveViewMemo}
            />
        ), [bondId, canOpenLiveViewMemo]);

    if (shouldRedirect) {
        return <h1>No bond found. Redirecting...</h1>;
    }

    return (
        <>
            <div className="c-section c-bond">
                {participantsComponent}
                <ChatView
                    ref={chatRef}
                    bondId={bondId}
                    scrollToMessageId={messageId}
                    showBondInterestedAction={showBondInterestedAction}
                    preChatContent={<EarlyBondButtons bondId={bondId} />}
                />
                <Outlet context={outletCtx} />
            </div>
        </>
    );
}

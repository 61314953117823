import { Breadcrumb, addBreadcrumb, captureException } from "@sentry/react";
import { DateTime } from "luxon";

import { prettyNanoid } from "@/misc/nanoid";

import { stringifyToUnparsableJSON } from "./stringify";
import { LogLevel, logLevelProps, LogLine, logLineProps, logProps } from "./types";
import { isSharedWorker, sharedWorkerAvailable } from "@/workers/util";

import SharedLogWorker from "./archiveWorker?sharedworker";
import DedicatedLogWorker from "./archiveWorker?worker";
import { getArchivedLogLines } from "./archiveWorker";

export const workerOptions = { name: "avos-logger" };
export const logSrcStr = "avos-beyond";

export type LogFunc = (message?: any, ...optionalParams: any[]) => void;

type LogObject = {
    log: LogFunc;
    debug: LogFunc;
    info: LogFunc;
    warn: LogFunc;
    error: LogFunc;
    with: (props?: logProps) => WithObject;
};

interface LogOptions {
    forwardToConsole?: boolean;
    forwardToSentry?: boolean;
    directConsoleLoggingOnly?: boolean;
    worker?: {
        enabled?: boolean; // default true if `worker` present
        shared?: boolean;
        port?: MessagePort;
    };
}

function argsForConsoleLog(
    line: { msg: string; args?: any[]; props?: logProps; },
    stringify?: boolean,
): any[] {
    let args: any[] = [line.msg];
    const concatOptionalStringify = (newArgs: any[]) =>
        args.concat(stringify ? newArgs.map(stringifyToUnparsableJSON) : newArgs);

    if (line.args) {
        args = concatOptionalStringify(line.args);
    }
    if (line.props && Object.entries(line.props).length > 0) {
        args = concatOptionalStringify([line.props]);
    }
    return args;
}

function tsFormat(ts: number): string {
    return DateTime.fromMillis(ts).toFormat("yyMMdd-HH:mm:ss.SSS");
}

function formatLogLines(logLines: LogLine[]) {
    return logLines.map(line =>
        `${tsFormat(line.tsUtc)} ${logLevelProps[line.level].name}${
            line.tabId ? " " + line.tabId : ""
        } ` +
        argsForConsoleLog(line, true)
    );
}

class BufferedLogger {
    buffer: LogLine[] = [];
    forwardToConsole: boolean = true;
    forwardToSentry: boolean = true;
    tabId: string = prettyNanoid(6);
    shared: boolean = false;
    port: MessagePort | Worker | undefined;
    // Currently unused.
    worker: SharedWorker | Worker | undefined;

    log(level: LogLevel, msg: string, args?: any[], props?: logLineProps) {
        if (!props) {
            props = {};
        }
        if (level == LogLevel.Error) {
            props["stack"] = new Error().stack?.toString() || "";
        }

        const line: LogLine = {
            msg,
            level,
            tsUtc: DateTime.utc().valueOf(),
            args,
            props,
            tabId: this.tabId,
        };

        this.buffer.push(line);

        if (this.port) {
            this.port?.postMessage({
                source: logSrcStr,
                logLine: stringifyToUnparsableJSON(line),
            });
        }

        if (this.forwardToConsole) {
            logLevelProps[level].console()(
                DateTime.fromMillis(line.tsUtc).toFormat("yyyy-MM-dd HH:mm:ss.SSS"),
                ...argsForConsoleLog(line),
            );
        }
        else if (this.forwardToSentry && level >= LogLevel.Info) {
            // If we forward to console, Sentry will already pick it up. If we don't,
            // pass logs over to Sentry -- this will be a no-op if Sentry is not
            // configured.
            const breadcrumb: Breadcrumb = {
                category: "console",
                data: {
                    arguments: args?.map(stringifyToUnparsableJSON),
                    logger: "console",
                },
                level: logLevelProps[level].sentry,
                message: msg,
            };
            addBreadcrumb(breadcrumb);
        }
    }

    dump() {
        console.group("log dump @ " + DateTime.now().toISO());
        for (const line of this.buffer) {
            console.info(
                "%s %c%s%c %s",
                tsFormat(line.tsUtc),
                logLevelProps[line.level].fmt[0],
                logLevelProps[line.level].name,
                logLevelProps[line.level].fmt[1],
                ...argsForConsoleLog(line),
            );
        }
        console.groupEnd();
    }

    configure(opts: LogOptions) {
        if (opts.forwardToConsole !== undefined) this.forwardToConsole = opts.forwardToConsole;
        if (opts.forwardToSentry !== undefined) this.forwardToSentry = opts.forwardToSentry;

        if (opts.worker && (opts.worker.enabled ?? true)) {
            this.shared = (opts.worker.shared ?? false) &&
                (sharedWorkerAvailable() || isSharedWorker());

            if (this.shared) {
                if (sharedWorkerAvailable()) {
                    this.worker = new SharedLogWorker(workerOptions);
                    this.port = this.worker.port;
                }
                else {
                    this.port = opts.worker.port;
                }
            }
            else {
                this.worker = new DedicatedLogWorker(workerOptions);
                this.port = this.worker;
            }
        }
    }

    clear() {
        this.buffer = [];
    }

    getLogBufferFormatted(): string[] {
        return formatLogLines(this.buffer);
    }

    async getArchivedLogLines(): Promise<string[]> {
        return getArchivedLogLines();
    }
}

export type logFilter = (m?: any, ...p: any[]) => boolean;

class WithObject {
    private props: logProps = {};
    private filters: logFilter[] = [];

    constructor(props?: logProps) {
        this.props = props ?? {};
    }

    prop(key: string, value: any): WithObject {
        this.props[key] = value;
        return this;
    }

    filter(pred: logFilter): WithObject {
        this.filters.push(pred);
        return this;
    }

    checkFilters(m?: any, ...p: any[]): boolean {
        return this.filters.every(f => f(m, ...p));
    }

    logger(): LogObject {
        return {
            debug: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Debug, m, p, this.props),
            info: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Info, m, p, this.props),
            log: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Info, m, p, this.props),
            warn: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Warn, m, p, this.props),
            error: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && logger.log(LogLevel.Error, m, p, this.props),
            with: (props?: logProps, filters?: logFilter[]) => {
                this.merge(props, filters);
                return this;
            },
        };
    }

    externalLogger(): LogObject {
        return {
            debug: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && tryLog(LogLevel.Debug, m, p, this.props),
            info: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && tryLog(LogLevel.Info, m, p, this.props),
            log: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && tryLog(LogLevel.Info, m, p, this.props),
            warn: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && tryLog(LogLevel.Warn, m, p, this.props),
            error: (m?: any, ...p: any[]) =>
                this.checkFilters(m, ...p) && tryLog(LogLevel.Error, m, p, this.props),
            with: (props?: logProps, filters?: logFilter[]) => {
                this.merge(props, filters);
                return this;
            },
        };
    }

    private merge(props?: logProps, filters?: logFilter[]) {
        if (props) {
            Object.entries(props).forEach(([k, v]) => this.prop(k, v));
        }
        if (filters) {
            filters.forEach(f => this.filter(f));
        }
    }
}

const logger = new BufferedLogger();

const Log: LogObject = {
    debug: (m?: any, ...p: any[]) => logger.log(LogLevel.Debug, m, p),
    info: (m?: any, ...p: any[]) => logger.log(LogLevel.Info, m, p),
    log: (m?: any, ...p: any[]) => logger.log(LogLevel.Info, m, p),
    warn: (m?: any, ...p: any[]) => logger.log(LogLevel.Warn, m, p),
    error: (m?: any, ...p: any[]) => logger.log(LogLevel.Error, m, p),
    with: (props?: logProps) => {
        return new WithObject(props);
    },
};

export function dumpLogs(_opts?: Record<string, never>) {
    logger.dump();
}

async function dumpArchivedLogs(_opts?: Record<string, never>) {
    console.group("archived logs dump @ " + DateTime.now().toISO());
    const archivedLogsFormatted = await getArchivedLogsFormatted();
    archivedLogsFormatted.forEach(line => console.info(line));
    console.groupEnd();
}

export function getLogBufferFormatted(): string[] {
    return logger.getLogBufferFormatted();
}

function parseLogLine(line: string): LogLine {
    const parsed = JSON.parse(line);

    // Convert the timestamp to a number if it's a string
    // See https://github.com/avos-io/beyond/issues/3019
    if (typeof parsed.tsUtc === "string") {
        parsed.tsUtc = +parsed.tsUtc;
    }

    return parsed;
}

export async function getArchivedLogsFormatted() {
    const archivedLogLines: string[] = await logger.getArchivedLogLines();
    return formatLogLines(archivedLogLines.map(parseLogLine));
}

export function configureLogs(opts: LogOptions) {
    if (opts.directConsoleLoggingOnly === true) {
        Log.debug = console.debug;
        Log.info = console.info;
        Log.log = console.info;
        Log.warn = console.warn;
        Log.error = console.error;
    }
    else if (opts.directConsoleLoggingOnly === false) {
        Log.debug = (m?: any, ...p: any[]) => logger.log(LogLevel.Debug, m, p);
        Log.info = (m?: any, ...p: any[]) => logger.log(LogLevel.Info, m, p);
        Log.log = (m?: any, ...p: any[]) => logger.log(LogLevel.Info, m, p);
        Log.warn = (m?: any, ...p: any[]) => logger.log(LogLevel.Warn, m, p);
        Log.error = (m?: any, ...p: any[]) => logger.log(LogLevel.Error, m, p);
    }

    logger.configure(opts);
}

function tryLog(level: LogLevel, msg: string, args?: any[], props?: logLineProps) {
    try {
        return logger.log(level, msg, args, props);
    }
    catch (e) {
        captureException(e, {
            tags: { ["logLine"]: `"${msg}" at ${level} level` },
        });
    }
}

export function clearLogBuffer() {
    logger.clear();
}

// Export these to the global namespace on the browser. We want it to be possible
// to open up the developer tools and change logging settings or dump logs.
if (typeof window !== "undefined") {
    (window as any).dumpLogs = dumpLogs;
    (window as any).dumpArchivedLogs = dumpArchivedLogs;
    (window as any).configureLogs = configureLogs;
    (window as any).logAPI = Log;
}

export default Log;

import * as d from "./domain";

type UserMaxReadSeqNum = {
    channelId: d.ChannelId;
    sequenceNumber: number;
};

export type SequenceNumberWrapper = {
    case: "sequenceNumber";
    value: UserMaxReadSeqNum;
};

export type DeletedSequenceNumberWrapper = {
    case: "deletedId";
    value: d.ChannelId;
};

export type SequenceNumberOrDeleted = SequenceNumberWrapper | DeletedSequenceNumberWrapper;

export enum DraftType {
    Channel,
    BondCreation,
}

export type DraftTarget = { type: DraftType.BondCreation; } | {
    type: DraftType.Channel;
    channelId: d.ChannelId;
};

export const bondCreationDraftTarget: DraftTarget = Object.freeze({ type: DraftType.BondCreation });

export const newChannelDraftTarget = (channelId: d.ChannelId): DraftTarget =>
    Object.freeze({
        type: DraftType.Channel,
        channelId,
    });

export function isChannelDraftTarget(
    dt: DraftTarget,
): dt is DraftTarget & { type: DraftType.Channel; } {
    return dt.type === DraftType.Channel;
}

export function isBondCreationDraftTarget(
    dt: DraftTarget,
): dt is DraftTarget & { type: DraftType.BondCreation; } {
    return dt.type === DraftType.BondCreation;
}

export function draftTargetsAreEqual(a: DraftTarget, b: DraftTarget): boolean {
    if (a.type !== b.type) return false;
    // Appease tsc's poor inference for the check on the next line.
    if (isBondCreationDraftTarget(a) || isBondCreationDraftTarget(b)) return true;
    return a.channelId === b.channelId;
}

export function getChannelOfDraftTarget(dt: DraftTarget): d.ChannelId {
    if (!isChannelDraftTarget(dt)) {
        throw new Error(`No channelId on draft target with type ${dt.type}`);
    }
    return dt.channelId;
}

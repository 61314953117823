import { PostHogProvider } from "posthog-js/react";
import * as ReactDOM from "react-dom/client";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { Provider } from "react-redux";

import { oidcConfig } from "./components/AuthController";
import PostHogMeta from "./components/PostHogMeta";
import { setupRoutes } from "./components/Routes";
import Session from "./components/Session";
import { capacitorSetup, isNativePlatform } from "./misc/capacitor";
import { isDevEnv } from "./misc/environment";
import log, { configureLogs } from "./misc/log";
import { getPosthogClient } from "./misc/posthog";
import { setupTypeExtensions } from "./misc/primatives";
import { getRouter } from "./misc/router";
import { initSentry } from "./misc/sentry";
import StrictModeWrapper from "./misc/strictModeWrapper";
import "./sass/main.scss";
import { startStore } from "./workers/setup";
import { sharedWorkerAvailable } from "./workers/util";

/**
 * Attempt to find the URL that we should be navigating to, as put into the
 * `url_state` field on `SigninRedirectArgs`.
 */
const parseUrlState = (urlState: string | undefined): string | undefined => {
    if (!urlState) return;

    try {
        const { returnUrl } = JSON.parse(urlState);
        if (returnUrl) return returnUrl;
    }
    catch (err) {
        log.error("Error parsing auth-flow state", err);
        return;
    }
};

// oidc-client-ts writes to local storage by itself (as configured in the
// `oidcConfig()`)
const authProviderProps: AuthProviderProps = {
    ...oidcConfig(),
    onSigninCallback: user => {
        const url = parseUrlState(user?.url_state);
        const currentUrl = getRouter().state.location.pathname;
        getRouter().navigate(url || currentUrl, { replace: true, state: {} });

        // Handle the actual authentication via an event on the
        // `UserManager` object.
    },
};

async function main() {
    setupTypeExtensions();

    configureLogs({
        forwardToConsole: isDevEnv,
        forwardToSentry: true,
        worker: { shared: sharedWorkerAvailable() && !isNativePlatform },
    });

    if (isNativePlatform) {
        capacitorSetup();
    }

    log.info("Frontend startup", __BEYOND_FRONTEND_VERSION__);
    log.info(`Location changed: ${window.location.href} (initial load)`);

    initSentry();

    const posthogClient = getPosthogClient();

    setupRoutes();

    const store = await startStore();

    const rootElem = document.getElementById("root");
    if (!rootElem) {
        return;
    }

    const root = ReactDOM.createRoot(rootElem);

    root.render(
        <StrictModeWrapper>
            <AuthProvider {...authProviderProps}>
                <Provider store={store}>
                    <PostHogProvider client={posthogClient}>
                        <PostHogMeta />
                        <Session />
                    </PostHogProvider>
                </Provider>
            </AuthProvider>
        </StrictModeWrapper>,
    );
}

main();

import { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { bondCreationDraftTarget, newChannelDraftTarget } from "../domain/channels";
import * as d from "../domain/domain";
import { userNameForMention } from "../domain/mentions";
import { selectValidUserIdsForBondCreationAudience } from "../features/bondCreation";
import {
    inviteUserToBondThunk,
    inviteUserToBondViaEmailThunk,
    selectBondById,
} from "../features/bonds";
import { selectUsers } from "../features/users";
import { useInterestedContacts } from "../hooks/interest/useInterestedContacts";
import useAudienceSuggestionDomain, {
    EmailSubdomainOptions,
    EmailSuggestionContent,
    UserSubdomainOptions,
    UserSuggestionContent,
} from "../hooks/useAudienceSuggestionDomain";
import { AutoCompleteQuery } from "../hooks/useAutoCompleteQuery";
import useDialogOpenRef from "../hooks/useDialogOpenRef";
import useDialogOutsideClick from "../hooks/useDialogOutsideClick";
import { useNavigateBack } from "../hooks/useNavigateBack";
import useSelectorArgs from "../hooks/useSelectorArgs";
import useSet from "../hooks/useSet";
import { BondChildRouteContext } from "../misc/bondChildRouteContext";
import log from "../misc/log";
import { useAppDispatch } from "../store/redux";
import { CloseButton } from "./buttons/Close";
import { FeatureFlagged } from "./FeatureFlags";
import Avatar from "./gui/Avatar";
import SensitiveText from "./gui/SensitiveText";
import { SuggestionsList } from "./SuggestionsList";
import { createToast } from "../features/meta";

const EmailInviteSuggestionContent: EmailSuggestionContent = ({ entity }) => (
    <>
        <div className="u-truncate-auto">
            Invite&nbsp;
            <em>
                <SensitiveText>{entity}</SensitiveText>
            </em>
            &nbsp;by email.
        </div>
    </>
);

const UserInviteSuggestionContent: UserSuggestionContent = ({ entity }) => (
    <>
        <Avatar userId={entity.id} showPresence={false} size="suggestion" />
        <div className="u-truncate-auto">
            <em>
                <SensitiveText>{userNameForMention(entity)}</SensitiveText>
            </em>
            &nbsp;&bull;&nbsp;
            <SensitiveText>{entity.name}</SensitiveText>
        </div>
    </>
);

export default function InviteUsersModal(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const { navigateBack } = useNavigateBack({ defaultPath: "..", replaceDefault: true });
    const { bondId } = useOutletContext() as BondChildRouteContext;

    const bondOverview = useSelectorArgs(selectBondById, bondId);

    // The sketches have squads appear in the 'Add people' modal, but setting a
    // channel draft target hides squads from the suggestions at the moment.
    const draftTarget = useMemo(
        () => bondOverview && newChannelDraftTarget(bondOverview.channelId),
        [bondOverview],
    );

    const closeModal = useCallback(() => {
        log.debug("Closing modal");
        navigateBack();
    }, [navigateBack]);

    const dialogRef = useDialogOpenRef();
    const handleBackdropClick = useDialogOutsideClick(dialogRef, closeModal);

    const inviteUserToBondCb = useCallback((userId: d.UserId) => {
        dispatch(
            inviteUserToBondThunk({
                bondId,
                invitedUserId: userId,
            }),
        );
    }, [dispatch, bondId]);

    const addUserToBondViaEmailCb = useCallback(async (emailAddress: string) => {
        const confirm = window.confirm(`Invite ${emailAddress} to this bond?`);
        if (!confirm) return;

        const resp = dispatch(
            inviteUserToBondViaEmailThunk({
                bondId,
                invitedEmailAddress: emailAddress,
            }),
        ).unwrap().catch(() => {
            dispatch(createToast({
                message: "Failed to create invite.",
                duration: { seconds: 3 },
            }));
            throw new Error("Failed to create invite.");
        });

        const link = await resp;
        await navigator.clipboard.writeText(link).catch(() => {
            throw new Error("Failed to copy invite link to clipboard.");
        });

        dispatch(createToast({
            message: `Invite link for ${emailAddress} copied to clipboard.`,
            duration: { seconds: 4 },
        }));
        closeModal();
    }, [dispatch, closeModal, bondId]);

    const [userLookupText, setUserLookupText] = useState("");

    const userLookupQuery: AutoCompleteQuery = useMemo(() => ({
        trigger: "",
        text: userLookupText,
        range: { start: 0, end: userLookupText.length },
    }), [userLookupText]);

    const {
        add: addChosenUserId,
        remove: removeChosenUserId,
        has: userIdIsChosen,
        sorted: sortedChosenUserIds,
    } = useSet<d.UserId>();

    const chosenUsers = useSelectorArgs(selectUsers, sortedChosenUserIds);

    const selectUser = useCallback((userId: d.UserId) => {
        if (!userId) return;
        if (userIdIsChosen(userId)) {
            removeChosenUserId(userId);
        }
        else {
            addChosenUserId(userId);
        }
    }, [addChosenUserId, removeChosenUserId, userIdIsChosen]);

    // Maintain interest in all squads and users that the backend can make known to
    // the user while this modal is open
    useInterestedContacts();

    // Build the audience suggestion domain
    const validUserIds = useSelectorArgs(
        selectValidUserIdsForBondCreationAudience,
        draftTarget ?? bondCreationDraftTarget,
    );
    const emailSubdomainOptions = useMemo<EmailSubdomainOptions>(
        () => ({
            query: userLookupQuery.text,
            Content: EmailInviteSuggestionContent,
            action: addUserToBondViaEmailCb,
        }),
        [userLookupQuery.text, addUserToBondViaEmailCb],
    );
    const userSubdomainOptions = useMemo<UserSubdomainOptions>(
        () => ({
            idsToFilter: validUserIds,
            Content: UserInviteSuggestionContent,
            action: u => selectUser(u.id),
            overrideHighlight: u => userIdIsChosen(u.id),
        }),
        [validUserIds, selectUser, userIdIsChosen],
    );
    const suggestionDomain = useAudienceSuggestionDomain({
        email: emailSubdomainOptions,
        user: userSubdomainOptions,
    });

    const inviteAllChosen = useCallback(() => {
        chosenUsers.forEach(u => inviteUserToBondCb(u.id));
        closeModal();
    }, [chosenUsers, inviteUserToBondCb, closeModal]);

    if (!bondOverview) return <></>;

    return (
        <dialog
            className="c-dialog c-dialog--add-people"
            onClose={closeModal}
            onMouseDown={handleBackdropClick}
            ref={dialogRef}
            role="dialog"
        >
            <header className="c-dialog__header c-dialog__header--centered">
                <h1 className="c-dialog__title">Add people</h1>
                <CloseButton side="right" onClick={closeModal} />
            </header>

            <article className="c-dialog__content-wrapper">
                <div className="c-dialog__content c-dialog__content--has-scroll">
                    <div className="c-form-element-new">
                        <input
                            className="c-input-new c-input-new--full"
                            type="text"
                            value={userLookupText}
                            onChange={e => setUserLookupText(e.target.value)}
                            placeholder="Name or name@example.com"
                        />
                    </div>

                    <div className="c-autocomplete-list">
                        <SuggestionsList
                            domain={suggestionDomain}
                            query={userLookupQuery.text}
                            arrowKeysActive={false}
                            allowTab={true}
                            useUnselectedState={true}
                            groupClassName=""
                            suggestionClassName="c-suggestion"
                        />
                    </div>
                </div>
            </article>

            <div className="c-dialog__footer c-dialog__footer--new">
                <button
                    className="c-btn-solid c-btn-solid--add"
                    onClick={inviteAllChosen}
                    disabled={chosenUsers.length == 0}
                >
                    {`Add ${chosenUsers.length > 0 ? chosenUsers.length : ""} users to bond`}
                </button>
            </div>
        </dialog>
    );
}

export function FlaggedInviteUsersModal(): React.JSX.Element {
    return (
        <FeatureFlagged flag={"early-bond-invite-buttons"} match={true}>
            <InviteUsersModal />
        </FeatureFlagged>
    );
}

import {
    Log as OidcLog,
    User,
    UserManagerSettings,
    WebStorageStateStore,
    ErrorResponse,
} from "oidc-client-ts";
import { useCallback, useEffect } from "react";
import { useAuth } from "react-oidc-context";

import {
    AuthStatus,
    deriveOidcState,
    selectAuthStatus,
    selectTokenExpired,
    selectTokenExpiresAt,
    updateAuthStatus,
    updateOidc,
} from "../features/auth";
import { useAppDispatch, useAppSelector } from "../store/redux";
import log from "../misc/log";
import { useNavigate } from "react-router-dom";
import useTimerTarget from "../hooks/useTimerTarget";
import { captureException } from "@sentry/react";
import { updateConnectionStatus, ConnectionStatus } from "../features/connection";
import { getEnvironmentConfig } from "../misc/environment";
import { isNativePlatform } from "../misc/capacitor";

const isOidcTimerLogLine = (exclude: RegExp[], limit: number) => (m?: any, ...p: any[]) => {
    const match = exclude.filter(re => re.exec(m)).length > 0;
    return !(match && p[0] == "timer completes in" && p[1] > limit);
};

const omitted = [
    /Timer\('Access token expiring'\)/,
    /Timer\('Access token expired'\)/,
];
const OidcLogger = log.with()
    .prop("from", "oidc")
    .filter(isOidcTimerLogLine(omitted, 300))
    .externalLogger();

OidcLog.setLogger(OidcLogger);
OidcLog.setLevel(OidcLog.DEBUG);

// TODO: expose an endpoint on the backend to serve the oidc client cofiguration.
export const oidcConfig = (): UserManagerSettings => {
    const targetConfig = getEnvironmentConfig();

    // We set the current URL as the URL to redirect to after login
    // Bodge: This won't work for native platforms, they need their congigured redirect URI
    const currentUrl = window.location.href;
    const targetRedirectUri = isNativePlatform ? targetConfig.redirectUri : currentUrl;

    log.info(`Providing OIDC with redirect_uri: '${targetRedirectUri}'`);

    const config: UserManagerSettings = {
        authority: targetConfig.oidcAuthority,
        // Uncomment to refresh your token every 5 minutes:
        // accessTokenExpiringNotificationTimeInSeconds: 12 * 60 * 60 - 5 * 60,
        client_id: targetConfig.clientId,
        scope: "openid profile email offline_access",
        includeIdTokenInSilentRenew: true,
        redirect_uri: targetRedirectUri,
        post_logout_redirect_uri: new URL("/login", window.location.origin).toString(),
        monitorSession: true,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    return config;
};

export function AuthController() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const auth = useAuth();
    const user = auth.user ?? null; // Don't allow `undefined`
    const refreshToken = user?.refresh_token;

    const { nowMs, setTarget } = useTimerTarget();

    const tokenExpired = useAppSelector(selectTokenExpired);
    const tokenExpiresAt = useAppSelector(selectTokenExpiresAt);

    const authStatus = useAppSelector(selectAuthStatus);

    const transition = useCallback((status: AuthStatus) => dispatch(updateAuthStatus(status)), [
        dispatch,
    ]);

    useEffect(() => {
        if (tokenExpiresAt === undefined) return;

        log.info(`Setting timer for token expiry`, tokenExpiresAt);
        setTarget(tokenExpiresAt);
    }, [tokenExpiresAt, setTarget]);

    useEffect(() => {
        if (tokenExpiresAt === undefined || tokenExpiresAt > nowMs) return;

        log.info(`Token has expired, disabling connection attempts`);
        dispatch(updateConnectionStatus(ConnectionStatus.ClearAuth));
    }, [nowMs, tokenExpiresAt, dispatch]);

    useEffect(() => {
        if (!auth.error) return;

        log.info(`Auth error`, auth.error);

        captureException(auth.error);
    }, [auth.error]);

    // Determine if we are authenticated.
    useEffect(() => {
        if (authStatus === AuthStatus.Unknown) {
            if (!auth.isLoading) {
                if (user) {
                    const update = deriveOidcState(user);
                    log.info(`User authenticated during load`, {
                        personId: update.personId,
                    });
                    // Implicitly moves to `AuthStatus.Authenticated`
                    // or `AuthStatus.CheckingForRefresh`
                    dispatch(updateOidc(update));
                }
                else {
                    log.info(`No user auth found during load`);
                    transition(AuthStatus.ClearingAuthentication);
                }
            }
        }
        else if (authStatus === AuthStatus.Unauthenticated) {
            if (!auth.isLoading && user) {
                const update = deriveOidcState(user);
                log.info(`User authenticated successfully`, { personId: update.personId });
                // Implicitly moves to `AuthStatus.Authenticated`
                // or `AuthStatus.CheckingForRefresh`
                dispatch(updateOidc(update));
            }
        }
        else if (authStatus === AuthStatus.Authenticated) {
            // TODO: token revoked?
            if (
                tokenExpired ||
                (tokenExpiresAt !== undefined && tokenExpiresAt <= nowMs)
            ) {
                log.info(`Noticed that token has expired`);
                transition(AuthStatus.CheckingForRefresh);
            }
        }
        else if (authStatus === AuthStatus.ClearingAuthentication) {
            // Something (e.g. an error from the websocket) has provided a signal that
            // we need to start the auth process anew.
            // Let the LogoutView sort it out.
            log.info(`Clearing authentication`);
            navigate("/logout");
        }
        else if (authStatus === AuthStatus.CheckingForRefresh) {
            if (refreshToken) {
                log.info(`Have refresh token`);
                transition(AuthStatus.Refreshing);
            }
            else {
                log.info(`No refresh token found`);
                transition(AuthStatus.ClearingAuthentication);
            }
        }
        else if (authStatus === AuthStatus.Refreshing) {
            // Do nothing. Wait for the `silentRenewService` to deal with this.
        }
    }, [
        auth,
        transition,
        dispatch,
        authStatus,
        auth.isLoading,
        refreshToken,
        user,
        navigate,
        nowMs,
        tokenExpired,
        tokenExpiresAt,
    ]);

    useEffect(() => {
        return auth.events.addAccessTokenExpiring(() => {
            log.debug(`Access token expiring`);
        });
    }, [auth]);

    useEffect(() => {
        return auth.events.addAccessTokenExpired((...ev: unknown[]) => {
            log.info(`Access token expired`, ev);
        });
    }, [auth]);

    useEffect(() => {
        return auth.events.addUserSessionChanged(() => {
            log.warn(`User session changed`);
        });
    }, [auth]);

    // You'd imagine this would fire on sign-in, but it doesn't appear to.
    // Token refresh does not fire the event.
    // Loading data from a cached location does not fire the event.
    useEffect(() => {
        return auth.events.addUserSignedIn(() => {
            log.info(`UserSignedIn`);
        });
    }, [auth]);

    useEffect(() => {
        return auth.events.addUserSignedOut(() => {
            log.info(`UserSignedOut`);
        });
    }, [auth]);

    // "loading" here means gaining a new access token.
    useEffect(() => {
        return auth.events.addUserLoaded((user: User) => {
            log.info(`User loaded`, { person: user.profile.sub });
            dispatch(updateOidc(deriveOidcState(user)));
        });
    }, [auth, dispatch]);

    useEffect(() => {
        return auth.events.addUserUnloaded(() => {
            log.info(`User unloaded`);
        });
    }, [auth]);

    useEffect(() => {
        return auth.events.addSilentRenewError((error: Error) => {
            log.error(`Silent renew error`, error);

            if (error instanceof ErrorResponse) {
                // In this case we might have hit a fatal error, so it's safer to give up
                log.warn("ErrorResponse details", {
                    form: error.form,
                    name: error.name,
                    error: error.error,
                    error_description: error.error_description,
                    error_uri: error.error_uri,
                    state: error.state,
                    session_state: error.session_state,
                    url_state: error.url_state,
                });
                return;
            }

            // If we get here, it's most likely a network error. The full set of exceptions
            // that could be thrown are not documented, and there are several complex code-paths,
            // but on balance it probably makes sense to retry after a short delay.
            setTimeout(auth.startSilentRenew, 2000);
        });
    }, [auth]);

    useEffect(() => {
        const ia = auth.isAuthenticated;
        log.info(`Auth isAuthenticated`, ia);
    }, [auth.isAuthenticated]);

    return <></>;
}

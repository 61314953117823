import {
    Action,
    isAction,
    isAsyncThunkAction,
    isPending,
    Middleware,
    PayloadAction,
} from "@reduxjs/toolkit";

import * as d from "../domain/domain";
import log from "../misc/log";
import { modulo } from "../misc/utils";
import { actionHasLocalTag, actionHasProxyTag, tagProxyAction } from "./locations";
import { appAppendListener, type AppAppendListener } from "./middleware";
import type { RootState } from "./types";

const isThunkAction = isAsyncThunkAction();
const isPayloadAction = (a: Action): a is PayloadAction<unknown> => "payload" in a;

/** Which "direction" is a message on a `BroadcastChannel` intended for?
 *
 * Request => only the overseer should dispatch
 * Broadcast => only slaves should dispatch
 */
export enum ProxiedMessageDirection {
    Request,
    Broadcast,
}

/** The shape for the data needed for a proxied thunk to be put
 * into a `BroadcastChannel`.
 */
type ProxiedThunkRequest<ThunkArg = unknown> = { thunk: { typePrefix: string; arg: ThunkArg; }; };
/** The shape for a POJO action to be put into a `BroadcastChannel`.
 */
type ProxiedPayloadRequest<
    Returned = unknown,
    A extends PayloadAction<unknown> = PayloadAction<Returned>,
> = {
    action: A;
};

/** A message on a `BroadcastChannel` intended to be dispatched by the overseer
 */
export type ProxiedActionRequest<
    Returned = unknown,
    ThunkArg = unknown,
    A extends PayloadAction<unknown> = PayloadAction<Returned>,
> = {
    direction: ProxiedMessageDirection.Request;
} & (ProxiedPayloadRequest<Returned, A> | ProxiedThunkRequest<ThunkArg>);

/** A message on a `BroadcastChannel` intended to be dispatched by slaves.
 */
export type ProxiedActionBroadcast<
    Returned = unknown,
    A extends PayloadAction<unknown> = PayloadAction<Returned>,
> = {
    direction: ProxiedMessageDirection.Broadcast;
    action: A;
    seqNo: number;
};

/** The shape of a message on the `BroadcastChannel` for proxying dispatches.
 */
export type ProxiedMessage<
    Returned = unknown,
    ThunkArg = unknown,
    A extends PayloadAction<unknown> = PayloadAction<Returned>,
> = ProxiedActionRequest<Returned, ThunkArg, A> | ProxiedActionBroadcast<Returned, A>;

/** Typeguard for proxied mesages, direction === Request
 */
export const isProxiedRequest = (msg: ProxiedMessage): msg is ProxiedActionRequest =>
    msg.direction === ProxiedMessageDirection.Request;
/** Typeguard for proxied mesages, direction === Broadcast
 */
export const isProxiedResponse = (msg: ProxiedMessage): msg is ProxiedActionBroadcast =>
    msg.direction === ProxiedMessageDirection.Broadcast;

/** Typeguard for proxied thunk requests.
 */
export const isThunkRequest = <T extends ProxiedActionRequest>(
    msg: T,
): msg is T & ProxiedThunkRequest => "thunk" in msg;

/** Get a handle to a `BroadcastChannel` on which to send and receive
 * proxied requests and broadcasts.
 *
 * @param userId the userId for which the current store is configured
 * @return a `BroadcastChannel` for the sending and receiving of messages
 */
export const getDispatchChannel = (userId: d.UserId) => {
    if (!userId) throw new Error(`Action proxying requires a userId`);
    return new BroadcastChannel(`dispatches-${userId}`);
};

/** Middleware to proxy all messages not tagged as local or proxied.
 *
 * Implicitly, this must only be added to stores that are proxying to an
 * overseer.
 *
 * @param userId the userId for which the store has been configured
 */
export const proxyMiddleware = (userId: d.UserId) => {
    const proxyChannel = getDispatchChannel(userId);

    const middleware: Middleware<RootState> = _store => next => action => {
        if (!isAction(action) || !isPayloadAction(action)) {
            log.warn(`Ignoring non-payload-action dispatch`, action);
            return;
        }

        // Thunk actions reaching here can't be proxied, since they are
        // functions in disguise. So there's not much to do but continue
        // dispatching them. This may or may not be what you want!
        // n.b. we should only have added this middleware if we are proxying.
        if (isThunkAction(action)) {
            return next(action);
        }

        // Local-only action.
        if (actionHasLocalTag(action)) {
            return next(action);
        }

        // Action retreived out of the broadcast channel.
        // n.b. we should only have added this middleware if we are proxying.
        if (actionHasProxyTag(action)) {
            return next(action);
        }

        try {
            const msg: ProxiedMessage = {
                direction: ProxiedMessageDirection.Request,
                action: tagProxyAction({ ...action }),
            };

            proxyChannel.postMessage(msg);
        }
        catch (e) {
            log.error(`Error proxying action ${action.type}`, action, e);
        }
    };

    return middleware;
};

/** Add a listener on the dispatch channel
 *
 * @param userId the userId for which the store has been configured
 * @param direction the direction of messages on which to apply the callback
 * @param onMessage a function to apply to all messages received on the
 * dispatch channel, for the given direction
 * @return a cleanup function to remove the listener
 */
export const listenForProxiedDispatches = (
    userId: d.UserId,
    direction: ProxiedMessageDirection,
    onMessage: (action: ProxiedMessage) => void,
) => {
    if (!userId) {
        log.warn(`Cannot listen for proxied actions with no userId`);
        return () => {};
    }

    const proxiedMessageChannel = getDispatchChannel(userId);

    const handleProxiedMessage = (
        { data }: MessageEvent<ProxiedMessage>,
    ) => {
        if (direction !== data.direction) return;

        onMessage(data);
    };

    proxiedMessageChannel.addEventListener("message", handleProxiedMessage);

    return () => {
        proxiedMessageChannel.removeEventListener("message", handleProxiedMessage);
    };
};

/** The current sequence number of the broadcasts into the dispatch channel.
 *
 * This is used to ensure that new clients know from which sequence number to
 * begin applying broadcast messages, so that all stores remain identical.
 *
 * It loops for sanity's sake. But it should only loop after a large enough
 * number of messages that there is no risk of an individual client receiving
 * two messages with the same sequence number whilst resolving the initial
 * state from the overseer into its local store.
 *
 * 1 << 10 is wildly too large for this to be a problem.
 */
export let currentBroadcastSeqNo = 0;
const broadcastSeqNoLoopsAt = 1 << 10;
const updateSeqNo = () => {
    const r = currentBroadcastSeqNo;

    if (++currentBroadcastSeqNo >= broadcastSeqNoLoopsAt) {
        currentBroadcastSeqNo = modulo(currentBroadcastSeqNo, broadcastSeqNoLoopsAt);
    }

    return r;
};

/** A unique and unusual prefix for a thunk action.
 *
 * We need some way to filter out the `fulfilled`/`rejected` actions that
 * are dispatched whenever and however the stream closes.
 * The solution we have here uses a prefix on the provided `typePrefix`,
 * which goes against the normal string form these prefixes take and uses a
 * colon, which should be Good Enough to filter them out.
 */
export const streamTypePrefix = "stream:";

/** Add a listener to broadcast all actions that the overseer has dispatched.
 *
 * Implicitly, this must only be added to the store of an overseer.
 *
 * @param userId the userId for which the store has been configured
 * @param appendListener a function to add the listener
 * @return a cleanup function to remove the listener
 */
export const startBroadcastingDispatches = (
    userId: d.UserId,
    appendListener: AppAppendListener = appAppendListener,
) => {
    const broadcastChannel = getDispatchChannel(userId);

    return appendListener({
        predicate: (action, _, __) => {
            // Don't broadcast pending thunk actions.
            if (isPending(action)) return false;
            // Don't broadcast stream-ended actions.
            if (action.type.startsWith(streamTypePrefix)) return false;

            return true;
        },
        effect: (action, _api) => {
            if (!isPayloadAction(action)) {
                log.error(`Unexpected non-payload action ${action.type}`, action);
                return;
            }

            const seqNo = updateSeqNo();

            const msg: ProxiedMessage = {
                direction: ProxiedMessageDirection.Broadcast,
                action: tagProxyAction(action),
                seqNo,
            };

            try {
                broadcastChannel.postMessage(msg);
            }
            catch (e) {
                log.error(`Dispatch broadcast error`, action, e);
            }
        },
    });
};

import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";

import * as capi from "../api/calls";
import * as clapi from "../api/client";
import { CallLanguageCode, CallOverview, isNullRole } from "../domain/calls";
import * as d from "../domain/domain";
import log from "../misc/log";
import { Diff, Optional, optionalFriendly, SerializableSet, TypedKeys } from "../misc/types";
import { separateDiscriminatedUnion, toSerializableSet } from "../misc/utils";
import { checkPersistor, fetchIdbStore, idbStore } from "../persist/shared";
import type { Connection, IdbStoreUpdate, PersistenceUpdate } from "../persist/types";
import { type AppAppendListener, appAppendListener } from "../store/middleware";
import { createAppSelector } from "../store/redux";
import type { RootState } from "../store/types";
import { resetStore, selectCurrentUserId } from "./auth";
import { selectCallsInterest, startManagingInterest } from "./interest";
import { createProxiedAsyncThunk } from "./proxiedThunk";
import { createSelectorPair, memoizeOptions } from "./selectors";
import { createStreamingAsyncThunk } from "./streamingThunk";
import { unaryThunkHandler } from "./thunk";
import { selectUsers } from "./users";

const callsAdapter = createEntityAdapter({
    selectId: (call: CallOverview) => call.id,
    sortComparer: (a: CallOverview, b: CallOverview) => {
        const end = b.endedAt - a.endedAt;
        if (end > 0) {
            return end;
        }
        return 0;
    },
});

interface StartCallArgs {
    name: string;
    bondId: d.BondId;
    language?: string;
}
export const startCall = createProxiedAsyncThunk(
    "calls/startInBond",
    async (args: StartCallArgs, thunkAPI) => {
        const state = thunkAPI.getState();
        const userId = selectCurrentUserId(state)!;

        log.info("Starting call in bond", args.bondId);
        return await unaryThunkHandler(
            thunkAPI,
            capi.startCallInBond({
                ...args,
                currentUserId: userId,
            }),
            "startCallInBond",
        );
    },
);

interface GetAccessTokenArgs {
    sessionId: d.RtcSessionId;
    userId: d.UserId;
}
export const getAccessToken = createProxiedAsyncThunk(
    "calls/getAccessToken",
    async (args: GetAccessTokenArgs, thunkAPI) => {
        try {
            return await clapi.getAccessToken(args.sessionId, args.userId);
        }
        catch (err) {
            log.error(`Exception when getting access token:`, err);
            return thunkAPI.rejectWithValue({ error: "Failed to get call access token" });
        }
    },
);

export const subCallsThunk = createStreamingAsyncThunk<
    AsyncIterableIterator<Diff<d.CallId>>,
    capi.CallOrDeleted
>(
    "calls/subCalls",
    {
        rpc: ({ arg, signal }) => capi.subCalls(arg, signal),
        parser: ({ thunkAPI, content }) => {
            const [calls, deleted] = separateDiscriminatedUnion<
                capi.CallWrapper,
                capi.CallDeletedWrapper
            >(
                capi.callOrDeletedIsCall,
                content,
            );

            if (calls.length > 0) {
                const latest = calls.map(wr => wr.call).filterLatest(call => call.id);
                thunkAPI.dispatch(callsSlice.actions.streamedMany(latest));
            }

            if (deleted.length > 0) {
                const unique = deleted.map(wr => wr.deletedId).removeDuplicates();
                thunkAPI.dispatch(callsSlice.actions.removeCalls(unique));
            }
        },
    },
);
export const startCallInterestStream = (
    appendListener: AppAppendListener = appAppendListener,
) => startManagingInterest(selectCallsInterest, subCallsThunk, "subCalls", appendListener);

export enum CallActionStatus {
    None,
    Ready,
    Pending,
    Completed,
    Backoff,
    Error,
}

// JoinedCallView describes the user's desired view of a call once a bond goes live and they have Joined call status.
// Either BondView (default) or LiveView, defined in:
// https://slab.avos.info/posts/phase-2-crunch-time-priorities-2iqd4i4i#hjm2n-the-two-views-for-a-live-bond-%F0%9F%97%92%EF%B8%8F
export enum JoinedCallView {
    Bond,
    Live,
}

export interface CallsState {
    calls: EntityState<CallOverview, d.CallId>;
    currentCallId?: d.CallId;
    currentParticipantId?: d.RtcParticipantId;

    callStartStatus: CallActionStatus;
    callJoinStatus: CallActionStatus;

    joinedCallView: JoinedCallView;
    focusedTileId: Optional<string>;
    gridTiles: SerializableSet<string>;

    defaultTranscriptionLanguage?: CallLanguageCode;
    wakeLockInterest: number;

    updates: CallUpdate[];
}

const getInitialState = (props?: {
    calls?: Record<d.CallId, CallOverview>;
}): CallsState => ({
    callStartStatus: CallActionStatus.None,
    callJoinStatus: CallActionStatus.None,

    joinedCallView: JoinedCallView.Bond,
    focusedTileId: undefined,
    gridTiles: {},

    calls: callsAdapter.getInitialState({}, props?.calls ?? {}),
    wakeLockInterest: 0,
    updates: [],
});

export const callsSlice = createSlice({
    name: "calls",
    initialState: getInitialState(),
    reducers: {
        removeCall: (state, { payload: callId }: PayloadAction<d.CallId>) => {
            state.calls = callsAdapter.removeOne(state.calls, callId);

            state.updates = [[stores.calls, callId]];
        },
        removeCalls: (state, { payload: callIds }: PayloadAction<d.CallId[]>) => {
            state.calls = callsAdapter.removeMany(state.calls, callIds);

            state.updates = callIds.map(id => [stores.calls, id]);
        },

        streamed: (state, { payload: co }: PayloadAction<CallOverview>) => {
            state.calls = callsAdapter.upsertOne(state.calls, co);

            state.updates = [[stores.calls, co.id, co]];
        },
        streamedMany: (state, { payload: cos }: PayloadAction<CallOverview[]>) => {
            state.calls = callsAdapter.upsertMany(state.calls, cos);

            state.updates = cos.map(co => [stores.calls, co.id, co]);
        },

        setCurrentParticipantId: (state, action: PayloadAction<Optional<d.RtcParticipantId>>) => {
            state.currentParticipantId = action.payload;
        },

        setCallStartStatus: (state, { payload: status }: PayloadAction<CallActionStatus>) => {
            state.callStartStatus = status;
        },
        setCallJoinStatus: (state, { payload: status }: PayloadAction<CallActionStatus>) => {
            state.callJoinStatus = status;
        },
        setCallJoinStatusFromCurrentCallId: (
            state,
            { payload: callJoinStatus }: PayloadAction<CallActionStatus>,
        ) => {
            // Set the in call status if we have a current call ID, else set to None.
            if (state.currentCallId) {
                state.callJoinStatus = callJoinStatus;
            }
            else {
                state.callStartStatus = CallActionStatus.None;
                state.callJoinStatus = CallActionStatus.None;
            }
        },
        setCallStartStatusFromCurrentCallId: (
            state,
            { payload: callStartStatus }: PayloadAction<CallActionStatus>,
        ) => {
            // Set the in call status if we do not have a current call ID, else set to Completed.
            if (!state.currentCallId) {
                state.callStartStatus = callStartStatus;
            }
            else {
                state.callStartStatus = CallActionStatus.Completed;
            }
        },
        setJoinedCall: (state, { payload: callId }: PayloadAction<d.CallId>) => {
            state.currentCallId = callId;
            state.callJoinStatus = CallActionStatus.Completed;
        },
        setLeftCall: state => {
            state.currentCallId = undefined;
            state.callStartStatus = CallActionStatus.None;
            state.callJoinStatus = CallActionStatus.None;
        },
        setCurrentCallId: (state, { payload: callId }: PayloadAction<Optional<d.CallId>>) => {
            state.currentCallId = callId;
            if (callId) {
                state.callStartStatus = CallActionStatus.Completed;
            }
            else {
                state.callStartStatus = CallActionStatus.None;
                state.callJoinStatus = CallActionStatus.None;
            }
        },

        closeLiveView: state => {
            state.joinedCallView = JoinedCallView.Bond;
            // Also reset focus
            state.focusedTileId = undefined;
            state.gridTiles = toSerializableSet([]);
        },
        openLiveView: (state, { payload: focusedTileId }: PayloadAction<Optional<string>>) => {
            state.joinedCallView = JoinedCallView.Live;
            state.focusedTileId = focusedTileId;
        },

        setFocusedTileId: (state, { payload: focusedTileId }: PayloadAction<Optional<string>>) => {
            state.focusedTileId = focusedTileId;
        },
        setGridTiles: (state, { payload: gridTiles }: PayloadAction<string[]>) => {
            state.gridTiles = toSerializableSet(gridTiles);
        },

        // This is temporary! We're avoiding thinking about language more generally.
        // For now, this means users pick a language when they start a call for the first time.
        setDefaultTranscriptionLanguage: (state, { payload: language }) => {
            state.defaultTranscriptionLanguage = language;
        },
        registerWakeLockInterest: (state, _action: PayloadAction<void>) => {
            state.wakeLockInterest++;
        },
        unregisterWakeLockInterest: (state, _action: PayloadAction<void>) => {
            if (state.wakeLockInterest < 1) {
                // If this happens, this is indicative of a bad problem.
                // Somehow, Redux's knowledge of this is out of sync with reality.
                state.wakeLockInterest = 0;
            }
            else {
                state.wakeLockInterest--;
            }
        },
    },
    selectors: {
        currentCallId: state => state.currentCallId,
        currentParticipantId: state => state.currentParticipantId,
        startStatus: state => state.callStartStatus,
        joinStatus: state => state.callJoinStatus,
        joinedCallView: state => state.joinedCallView,
        focusedTileId: state => state.focusedTileId,
        gridTiles: state => state.gridTiles,
        defaultTranscriptionLanguage: state => state.defaultTranscriptionLanguage,
        wakeLockInterest: state => state.wakeLockInterest,
    },
    extraReducers: builder => {
        builder.addCase(startCall.pending, (state, _) => {
            state.callStartStatus = CallActionStatus.Pending;
        });
        builder.addCase(startCall.fulfilled, (state, _) => {
            state.callStartStatus = CallActionStatus.Completed;
        });
        builder.addCase(startCall.rejected, (state, _) => {
            if (!state.currentCallId) {
                state.callStartStatus = CallActionStatus.Error;
            }
        });

        builder.addCase(getAccessToken.fulfilled, (state, action) => {
            state.currentParticipantId = action.payload.participantId;
        });

        builder.addCase(resetStore, _state => {
            return getInitialState();
        });
    },
});

export const {
    setCallJoinStatus,
    setCallStartStatus,
    setCallJoinStatusFromCurrentCallId,
    setCallStartStatusFromCurrentCallId,
    setJoinedCall,
    setLeftCall,
    setCurrentCallId,
    setCurrentParticipantId,
    openLiveView,
    closeLiveView,
    setFocusedTileId,
    setGridTiles,
    setDefaultTranscriptionLanguage,
    registerWakeLockInterest,
    unregisterWakeLockInterest,
} = callsSlice.actions;

const selectors = callsSlice.getSelectors((state: RootState) => state.calls);

export const selectCallStartStatus = selectors.startStatus;
export const selectCallJoinStatus = selectors.joinStatus;
export const selectJoinedCallView = selectors.joinedCallView;
export const selectFocusedTileId = selectors.focusedTileId;
export const selectCurrentCallId = selectors.currentCallId;
export const selectCurrentParticipantId = selectors.currentParticipantId;
export const selectDefaultTranscriptionLanguage = selectors.defaultTranscriptionLanguage;
export const selectWakeLockInterest = selectors.wakeLockInterest;

const callsSelectors = callsAdapter.getSelectors<RootState>(
    state => state.calls.calls,
);
export const selectCallById = optionalFriendly(callsSelectors.selectById);

const selectCurrentParticipantIds = createAppSelector(
    [selectCallById],
    co => (co?.participants.filter(p => !isNullRole(p.role)) || []).map(p => p.userId),
    memoizeOptions.weakMapShallow,
);

const selectCurrentParticipants = (state: RootState, id: Optional<d.CallId>) => {
    const participants = selectCurrentParticipantIds(state, id);
    return selectUsers(state, participants);
};

export const selectCurrentParticipantIdsPair = createSelectorPair(
    selectCurrentParticipantIds,
    createAppSelector(
        [selectCurrentParticipants],
        uos => uos.map(uo => uo.id),
        memoizeOptions.weakMapShallow,
    ),
);

export const selectGridTilesSet = createAppSelector(
    [selectors.gridTiles],
    ids => TypedKeys(ids).toSet(),
    memoizeOptions.lruShallow,
);

export const { streamed: streamedCall } = callsSlice.actions;

export const reducer = callsSlice.reducer;

// Persistence.

const stores = {
    calls: idbStore<d.CallId, CallOverview>("calls", 1),
};

type CallUpdate = IdbStoreUpdate<typeof stores.calls>;

const persist = (
    previousState: CallsState,
    currentState: CallsState,
): PersistenceUpdate[] => {
    if (previousState.updates == currentState.updates) {
        return [];
    }

    return currentState.updates;
};

const hydrate = async (conn: Connection) => {
    const calls = await fetchIdbStore(conn, stores.calls);

    return getInitialState({ calls });
};

export const persistor = {
    stores,
    hydrate,
    persist,
};
checkPersistor<CallsState>(persistor);

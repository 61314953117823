import * as clients_pb from "../../gen/proto/clients/clients_pb";
import { ClientService } from "../../gen/proto/clients/clients_connect";
import { transport } from "./transport";
import { createPromiseClient } from "@connectrpc/connect";
import * as d from "../domain/domain";
import * as util from "./util";
import { streamHandler } from "./stream";
import { Optional } from "../misc/types";

// Note: Calls to the client service are defined in the api file of the service
// which is relevant to the specific call. We export the PromiseClient here to
// act as the single source for the client service.

const service = createPromiseClient(ClientService, transport);
export default service;

// pbRtcParticipantId is not serialisable, so unwrap response in the API layer
// to prevent redux complaining about unserialisable payload actions.
export interface GetCallAccessTokenResponse {
    accessToken: string;
    participantId: d.RtcParticipantId;
}

export async function getAccessToken(
    sessionId: d.RtcSessionId,
    userId: d.UserId,
): Promise<GetCallAccessTokenResponse> {
    const req = new clients_pb.GetCallAccessTokenRequest({
        sessionId: util.pbRtcSessionId(sessionId),
        userId: util.pbUserId(userId),
    });
    const resp = await service.getCallAccessToken(req);
    return {
        accessToken: resp.accessToken,
        participantId: util.fromProtoRtcParticipantId(resp.participantId),
    };
}

export async function reportFirebaseToken(token: string): Promise<void> {
    const req = new clients_pb.ReportFirebaseTokenRequest({ token });

    await service.reportFirebaseToken(req);
}

export async function reportApnsToken(token: string): Promise<void> {
    const req = new clients_pb.ReportApnsTokenRequest({ token });

    await service.reportApnsToken(req);
}

export async function* sustainBrowserFocusReport(
    signal: AbortSignal,
): AsyncGenerator<void, void, unknown> {
    const req = new clients_pb.ReportBrowserFocusRequest({
        recordedAt: util.pbTimestamp(Date.now()),
    });

    const logPrefix = `sustainBrowserFocusReport`;

    const resp = service.reportBrowserFocus(req, { signal });

    yield* streamHandler(resp, () => null, logPrefix);
}

export async function* sustainTypingActivityReport(
    signal: AbortSignal,
): AsyncGenerator<void, void, unknown> {
    const req = new clients_pb.ReportTypingActivityRequest({
        recordedAt: util.pbTimestamp(Date.now()),
    });

    const logPrefix = `sustainTypingActivityReport`;

    const resp = service.reportTypingActivity(req, { signal });

    yield* streamHandler(resp, () => null, logPrefix);
}

export interface WhoAmIResponse {
    userId: d.UserId;
    orgId: d.OrgId;
}

export async function whoAmI(): Promise<Optional<WhoAmIResponse>> {
    const resp = await service.whoAmI(
        new clients_pb.WhoAmIRequest(),
    );

    const userId = resp.userId ? util.fromProtoUserId(resp.userId) : undefined;
    const orgId = resp.orgId ? util.fromProtoOrgId(resp.orgId) : undefined;

    if (!userId || !orgId) {
        return undefined;
    }

    return { userId, orgId };
}

import { useAppDispatch, useAppSelector } from "../store/redux";
import { selectIdleSubscribers, setIdleSince, setNotIdle } from "../features/meta";
import { useCallback, useEffect, useState } from "react";
import { DateTime, Duration } from "luxon";
import log from "../misc/log";
import useTimerTarget from "../hooks/useTimerTarget";

export type PresenceType = {
    type: "idle" | "active";
};

const defaultElement: Document | HTMLElement = document;
const defaultEvents = [
    "mousemove",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
    "focus",
];

// Reimplementation of react-idle-timer
export function useIdleTimer(
    { timeout, onPresenceChange }: {
        timeout: number;
        onPresenceChange: (p: PresenceType) => void;
    },
) {
    const { now, setTarget } = useTimerTarget();
    const [goesIdle, setGoesIdle] = useState<DateTime | undefined>(undefined);
    const [isIdle, setIsIdle] = useState<boolean | undefined>(undefined);

    // Whenever we're mounted with a different timeout, recalculate when we will
    // go idle.
    useEffect(() => {
        const next = DateTime.now().plus(timeout);
        setGoesIdle(next);
        setTarget(next);
    }, [timeout, setTarget]);

    // This is potentially called back a lot due to a lot of DOM events firing. So we
    // want to be careful to do very little work. Setting react state is considered to
    // be in that category.
    const onActivity = useCallback(() => {
        setIsIdle(false);
        setGoesIdle(DateTime.now().plus(timeout));
    }, [timeout]);

    // Tell the invoker about presence changes; this effect will fire on isIdle changes
    useEffect(() => {
        if (isIdle === undefined) return;
        onPresenceChange({ type: isIdle ? "idle" : "active" });
    }, [isIdle, onPresenceChange]);

    // Subscribe to any events that will mark as as active on mount
    useEffect(() => {
        defaultEvents.forEach(event => {
            defaultElement.addEventListener(event, onActivity, {
                passive: true,
                capture: true,
            });
        });

        return () => {
            defaultEvents.forEach(event => {
                // removeEventListener API is a bit odd; it's not symmetrical with addEventListener
                // as the "passive" flag does not exist.
                defaultElement.removeEventListener(event, onActivity, { capture: true });
            });
        };
    }, [onActivity]);

    // On timeout, or the idle target changing
    useEffect(() => {
        if (!goesIdle) return;

        if (now >= goesIdle) {
            setIsIdle(true);
        }
        else {
            setTarget(goesIdle);
        }
    }, [now, goesIdle, isIdle, setTarget, onPresenceChange]);

    return isIdle;
}

export default function IdleTracker(): React.JSX.Element {
    const dispatch = useAppDispatch();
    const idleTimeouts = useAppSelector(selectIdleSubscribers);
    const timeout = Math.min(
        ...Object.values(idleTimeouts).map(x => Duration.fromDurationLike(x!).valueOf()),
        15 * 60 * 1000,
    );

    const callback = useCallback((p: PresenceType) => {
        if (p.type === "idle") {
            const idleSince = DateTime.now().minus(timeout);
            log.info("User idle since", idleSince.toISO());
            dispatch(setIdleSince(idleSince.toMillis()));
        }
        else {
            log.info("User no longer idle");
            dispatch(setNotIdle());
        }
    }, [dispatch, timeout]);

    useIdleTimer({
        timeout: timeout,
        onPresenceChange: callback,
    });

    return <></>;
}
